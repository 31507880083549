/*
 * InterPayments Inc. ("COMPANY") CONFIDENTIAL
 * Unpublished Copyright © 2023 InterPayments Inc., All Rights Reserved.
 *
 * https://interpayments.com/copyright-policy/
 *
 * NOTICE: All information contained herein is, and remains the property of
 * COMPANY. The intellectual and technical concepts contained herein are
 * proprietary to COMPANY and may be covered by U.S. and Foreign Patents, patents
 * in process, and are protected by trade secret or copyright law. Dissemination
 * of this information or reproduction of this material is strictly forbidden
 * unless prior written permission is obtained from COMPANY. Access to the source
 * code contained herein is hereby forbidden to anyone except current COMPANY
 * employees, managers or contractors who have executed Confidentiality and
 * Non-disclosure agreements explicitly covering such access.
 *
 * The copyright notice above does not evidence any actual or intended publication
 * or disclosure of this source code, which includes information that is
 * confidential and/or proprietary, and is a trade secret, of COMPANY. ANY
 * REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY
 * OF OR THROUGH USE OF THIS SOURCE CODE WITHOUT THE EXPRESS WRITTEN CONSENT
 * OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 * INTERNATIONAL TREATIES. THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR
 * RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE
 * OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 * MAY DESCRIBE, IN WHOLE OR IN PART.
 *
 */

type StatusChecker = (s: number) => boolean
const ok: StatusChecker = status => status >= 200 && status < 300

export type TokenGetter = () => Promise<string>

export type BaseServiceParams = {
  baseUrl: string
}

export type AuthenticatedBaseServiceParams = {
  getIdToken: TokenGetter,
  idToken: string | undefined
} & BaseServiceParams

export class BaseService {

  private readonly baseUrl: string

  constructor({baseUrl}: BaseServiceParams) {
    this.baseUrl = baseUrl
  }

  protected objectToQueryString(input: {[k: string]: any}): string {
    return Object.entries(input)
      .filter(([_, v]) => !!v)
      .map(([k, v]) => [encodeURIComponent(k), encodeURIComponent(v)])
      .map(([k, v]) => `${k}=${v}`)
      .join("&")
  }

  protected async checkJsonStatus<T>(rsp: Response, chk: number | StatusChecker = ok): Promise<T> {
   await this.checkStatus(rsp, chk)
   try {
      const contentType = rsp.headers.get("content-type")
      const text = await rsp.text()

      if (!(contentType && contentType.startsWith("application/json"))) {
        return Promise.reject(`bad content-type ${contentType}: ${text.slice(0, 50)}`)
      }

      // console.log("parsed json response to ", JSON.parse(text))
      return JSON.parse(text)
    } catch (e) {
     console.log("EXCEPTION!!!!", e)
      return Promise.reject(e)
    }
  }

  protected async checkStatus<T>(rsp: Response, chk: number | StatusChecker = ok): Promise<void> {
    let checker: StatusChecker
    if (typeof chk === "number") {
      checker = (status: number) => status === chk
    } else {
      checker = chk
    }

    if (!checker(rsp.status)) {
      return Promise.reject({
        error: {
          status: rsp.status,
          statusText: rsp.statusText,
          body: (rsp.body || "").toString()
        }})
    }
  }

  protected apiUrl(path: string): string {
    return `${this.baseUrl}${path}`
  }
}

export class AuthenticatedBaseService extends BaseService {
  private readonly getIdToken: TokenGetter

  private readonly idToken: string | undefined

  constructor({baseUrl, getIdToken, idToken}: AuthenticatedBaseServiceParams) {
    super({baseUrl})
    // console.log("setting getIdToken", idToken)
    this.getIdToken = getIdToken
    this.idToken = idToken
  }

  protected async getAuthHeaders(headers: { [key: string]: string } = {}) {
    const token = this.idToken ? this.idToken : this.getIdToken && await this.getIdToken()
    if (!!token) {
      // console.log("getAuthHeaders with token", token)
      return ({headers: {authorization: `Bearer ${token}`, ...headers}})
    } else {
      console.log("getAuthHeaders with NO token")
      return {headers}
    }
  }
}

/*
 * InterPayments Inc. ("COMPANY") CONFIDENTIAL
 * Unpublished Copyright © 2023 InterPayments Inc., All Rights Reserved.
 *
 * https://interpayments.com/copyright-policy/
 *
 * NOTICE: All information contained herein is, and remains the property of
 * COMPANY. The intellectual and technical concepts contained herein are
 * proprietary to COMPANY and may be covered by U.S. and Foreign Patents, patents
 * in process, and are protected by trade secret or copyright law. Dissemination
 * of this information or reproduction of this material is strictly forbidden
 * unless prior written permission is obtained from COMPANY. Access to the source
 * code contained herein is hereby forbidden to anyone except current COMPANY
 * employees, managers or contractors who have executed Confidentiality and
 * Non-disclosure agreements explicitly covering such access.
 *
 * The copyright notice above does not evidence any actual or intended publication
 * or disclosure of this source code, which includes information that is
 * confidential and/or proprietary, and is a trade secret, of COMPANY. ANY
 * REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY
 * OF OR THROUGH USE OF THIS SOURCE CODE WITHOUT THE EXPRESS WRITTEN CONSENT
 * OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 * INTERNATIONAL TREATIES. THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR
 * RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE
 * OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 * MAY DESCRIBE, IN WHOLE OR IN PART.
 *
 */

import React from 'react'
import {Col, Layout, Result, Row, Typography} from 'antd'
import { AppHeader, IsProduction } from '../../components/Layout/AppHeader'
import { BankOutlined, BookOutlined, BulbOutlined, ContainerOutlined, CreditCardOutlined, TeamOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { AppFooter } from '../../components/Layout/AppFooter'
import { BulletedLinkCard } from '../../components/Card'
import {env2} from "../../utils/env2";
import {useAuthorization} from "../../hooks/useAuthorization";
import { useProducts } from 'hooks/useProducts'

const { Content } = Layout

const DocsLandingPageFC: React.FC<React.PropsWithChildren<unknown>> = () => {

    const BaseUrl = env2("REACT_APP_BASE_URL", "http://localhost:9000")

    const binServiceAuth = useAuthorization('feature:bin-service', ['viewer'])
    const extendedFeesAuth = useAuthorization('feature:extended-fees', ['viewer'])

    const canBinServ = binServiceAuth.isAuthorized('viewer')
    const canExtendedFees = extendedFeesAuth.isAuthorized('viewer')

    // turn this off for now... it should be enforced on server-side
    // support saying that everyone is getting a blank page.
    // const { noProducts } = useProducts()
    // if (noProducts) return <></>

    return <>
    <Layout
      style={{minHeight:"100vh"}}
    >
      <AppHeader />
      <Content style={{minHeight: 'unset'}}>
        <Content
          className='main-content'
        >
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Result
                icon={<BookOutlined />}
                extra={<>
                  <Typography.Title level={3}>Documentation</Typography.Title>
                </>}
                style={{paddingBottom: '16px'}}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            {/*
            <BulletedLinkCard
              isWide
              isAnchor
              title='Technical Sales Deck'
              href='/assets/docs/IP_SurchargingTechnicalSalesDoc.pdf'
              bullets={[
                'High level overview of how to integrate InterPayments into your ecosystem.',
                'Payment flow diagrams demonstrate product journey for common situations.',
                'Swimlane diagrams provide outline for common implementation paths.',
              ]}
              icon={<BulbOutlined />}
            />
            */}
            <BulletedLinkCard
              isAnchor
              title='Transaction Fee API'
              href={`${BaseUrl}/v1/ch/transactionFeeApi.html`}
              bullets={[
                'Calculate transaction fees for surcharging.',
                'Get details for existing transactions.',
                'Calculate and process partial or full refunds.',
                'Create recurring transaction records based on existing transaction metadata.'
              ]}
              icon={<BankOutlined />}
            />
            <BulletedLinkCard
              isAnchor
              title='Management API'
              href={`${BaseUrl}/v1/merchant/merchantApi.html`}
              bullets={[
                'Manage and configure your merchants.',
                'Generate new tokens for use with the Transaction Fee API.',
                'Query all transactions over a period of time.',
                'Modify business rules to optimize your surcharging workflow.'
              ]}
              icon={<TeamOutlined />}
            />
            {canBinServ ? <BulletedLinkCard
              isAnchor
              title='BinServ API'
              href={`${BaseUrl}/v1/nicn/binServApi.html`}
              bullets={[
                'Get card metadata based on bank identification number (BIN).',
                'Look up one or multiple BINs at a time.',
                'Filter through a list of BINs based on card features.',
              ]}
              icon={<CreditCardOutlined />}
            /> : <></>}
            {canExtendedFees ? <BulletedLinkCard
              isAnchor
              title='Service Fee API'
              href={`${BaseUrl}/v1/sf/serviceFeeApi.html`}
              bullets={[
                'Calculate service fees',
                'Get details for existing transactions.',
              ]}
              icon={<CreditCardOutlined />}
              style={{height: '100%'}}
            /> : <></>}
            {canExtendedFees ? <BulletedLinkCard
              isAnchor
              title='Convenience Fee API'
              href={`${BaseUrl}/v1/cf/convenienceFeeApi.html`}
              bullets={[
                'Calculate convenience fees',
                'Get details for existing transactions.',
              ]}
              icon={<CreditCardOutlined />}
              style={{height: '100%'}}
            /> : <></>}
            {canExtendedFees ? <BulletedLinkCard
              isAnchor
              title='FeeServ API'
              href={`${BaseUrl}/v1/feeserv/feeServApi.html`}
              bullets={[
                  'Processing Fees and Analytics',
                  'Least Cost Routing',
              ]}
              icon={<CreditCardOutlined />}
              style={{height: '100%'}}
            /> : <></>}
            {IsProduction ? <BulletedLinkCard
              isAnchor
              title='Compliance Recommendations'
              href={'/assets/docs/InterPayments_MerchantSurchargeDisclosureRequirements_January2025.pdf'}
              bullets={[
                'Learn about disclosure requirements that affect compliance',
              ]}
              style={{height: '100%'}}
              icon={<ContainerOutlined />}
            /> : <></>}
            <BulletedLinkCard
              isAnchor
              title='Portal Help'
              href={`./docs/help`}
              bullets={[
                'Help related to the InterPayment\'s Reseller Portal.',
              ]}
              icon={<QuestionCircleOutlined />}
              style={{height: '100%', paddingBottom: '16px'}}
            />  
          </Row>
        </Content>
      </Content>
      <AppFooter />
    </Layout>
  </>
}

export const DocsLandingPage = DocsLandingPageFC

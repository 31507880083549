import { useCallback } from "react"
import { useRequest } from "./useRequest"

// Casbin API

export const useCasbinAPI = () => {
  const { makeRequest } = useRequest()

  const getAllowedActions = useCallback(
    (object: string) => {
      return makeRequest([`/v1/e/users/resource/${object}/permission`, {}])
    }, [])

  const hasOwnPermissionSet = useCallback(
    (object: string, actions: string[]) => {
      return makeRequest([`/v1/e/users/resource/${object}/permission?${actions.length > 0 ? 'actions=' : ''}${actions.join(',')}`, {}])
    }, [])

  const hasPermission = useCallback(
    (subject: string, object: string, action: string) => {
      return makeRequest([`/v1/e/users/${subject}/resource/${object}/permission/${action}`, {}])
    }, [])

  return { getAllowedActions, hasOwnPermissionSet, hasPermission }
}

import { blue, red } from "@ant-design/colors"
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, FieldTimeOutlined, HomeOutlined, MailOutlined, PhoneOutlined, PlusOutlined, StarOutlined, UserOutlined } from "@ant-design/icons"
import { Button, Card, Col, Divider, message, Popconfirm, Result, Row, Space, Spin, Table, Tag, Tooltip, Typography } from "antd"
import { FancyCard } from "components/Card"
import { CustomerForm } from "components/paylink/CustomerForm"
import { usePaylinkAPI } from "hooks/paylink/usePaylinkAPI"
import { Merchant } from "models/models"
import { Address, AddressDetails, CardMethod, Customer, CustomerRequest, getCustomerName } from "models/paylink"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { AddressForm, AddressRequest } from "components/paylink/AddressForm"
import { usePaylinkMerchantContext } from "../PaylinkMerchantContext"

const { Text, Title } = Typography

const CustomerTitle = ({ customer }: {customer: Customer}) => {
  const hasName = !!customer.firstName || !!customer.lastName
  const hasCompany = !!customer.companyName
  if (hasName) return <>
    <Title level={2} style={{marginBlockEnd: 0}}>{`${customer.firstName} ${customer.lastName}`}</Title>
    {hasCompany ? <Title level={4} style={{marginBlockStart: 0, fontWeight: 'normal'}} type='secondary'>{customer.companyName}</Title> : <></>}
  </>
  return <Title level={2} style={{marginBlockEnd: 0}}>{customer.companyName}</Title>
}

const NewAddressCard = ({mid, cid, onRefresh}: {mid: string, cid: string, onRefresh: () => void}) => {
  const [ loading, setLoading ] = useState<boolean>(false)
  const [ showForm, setShowForm ] = useState<boolean>(false)

  const { createAddress } = usePaylinkAPI()

  const handleSubmit = useCallback((request: AddressRequest) => {
    setLoading(true)
    return createAddress(mid, cid, {address: request})
      .then(onRefresh)
      .then(_ => {
        setLoading(false)
        setShowForm(false)
      })
      .catch(e => {
        setLoading(false)
      })
  }, [])

  if (showForm) return <Spin spinning={loading}>
    <Card title='New Address'>
      <AddressForm onSubmit={handleSubmit} onCancel={() => setShowForm(false)} />
    </Card>
  </Spin>

  return <FancyCard style={{height: '100%', position: 'relative', overflow: 'hidden'}} icon={<PlusOutlined style={{color: blue[0]}} />} hoverable onClick={_ => setShowForm(true)}>
    <Title level={4} style={{color: blue.primary, position: 'absolute', top: '50%', transform: 'translateY(-50%)'}}>New Address</Title>
  </FancyCard>
}

const AddressCard = ({merchant, customer, address, onRefresh}: {merchant: Merchant, customer: Customer, address: Address, onRefresh: () => Promise<void>}) => {
  const [ loading, setLoading ] = useState<boolean>(false)
  const [ showForm, setShowForm ] = useState<boolean>(false)

  const { updateAddress } = usePaylinkAPI()

  const handleSetDefault = useCallback(() => {
    if (!merchant || !address) return
    setLoading(true)
    updateAddress(merchant.id!, customer.id!, address.id!, {address: {...address, default: true}})
      .then(onRefresh)
      .then(_ => {
        setLoading(false)
      })
  }, [merchant, address])

  const handleUpdate = useCallback((values) => {
    if (!merchant || !address) return
    setLoading(true)
    updateAddress(merchant.id!, customer.id!, address.id!, {address: {...values, default: address.default || false, disabled: address.disabled || false}})
      .then(onRefresh)
      .then(_ => {
        setLoading(false)
        setShowForm(false)
      })
  }, [merchant, address])

  const handleDelete = useCallback(() => {
    if (!merchant || !address) return
    setLoading(true)
    updateAddress(merchant.id!, customer.id!, address.id!, {address: {...address, disabled: true}} as any)
      .then(onRefresh)
      .then(_ => {
        setLoading(false)
      })
  }, [merchant, address])

  if (showForm) return <Card>
    <AddressForm onSubmit={values => handleUpdate(values)} onCancel={() => setShowForm(false)} initialValues={address} />
  </Card>

  return <Card
    title={<div style={{marginBlock: '1em'}}>
      <div style={{marginBlockEnd: '0.5em'}}>
        <Title level={4} style={{marginBlockEnd: '-0.25em'}}>
          <span>{address.firstName} {address.lastName}</span>
        </Title>
        <Text type='secondary' style={{fontSize: '0.75em'}}>{address.id}</Text>
      </div>
      <div>
        {!!address.default ? <Space size='small'><Tag color={blue.primary} icon={<StarOutlined />}>Default</Tag></Space> : <></>}
      </div>
    </div>}
    extra={<Space size='small'>
      <Tooltip title='Set Default'><Button icon={<StarOutlined />} onClick={() => handleSetDefault()}></Button></Tooltip>
      <Tooltip title='Edit'><Button icon={<EditOutlined />} type='primary' ghost onClick={() => setShowForm(true)}></Button></Tooltip>
      <Tooltip title='Delete'><Button danger icon={<DeleteOutlined />} onClick={() => handleDelete()}></Button></Tooltip>
    </Space>}
  >
    <div style={{display: 'grid', gridTemplateColumns: 'auto 1fr', gap: '0.5rem'}}>
      <Text type='secondary'><HomeOutlined /></Text>
      <div>
        <Text>{address.line1}</Text>
        <br />
        <Text>{address.locality}, {address.region}, {address.country} {address.postalCode}</Text>
      </div>
      
      <Text type='secondary'><MailOutlined /></Text>
      <Text>{address.email}</Text>

      <Text type='secondary'><PhoneOutlined /></Text>
      <Text>{address.phoneNumber}</Text>
    </div>
  </Card>
}

const CardMethodCard = ({card}: {card: CardMethod}) => {
  return <Card>
    <div>
      <div style={{marginBlockEnd: '0.5em'}}>
        <Title level={4} style={{marginBlockEnd: '-0.25em'}}>{card.cardNumber}</Title>
        <Text type='secondary' style={{fontSize: '0.75em'}}>{card.id}</Text>
      </div>
      {!!card.default || !!card.disabled ? <Space size='small'>
        {!!card.default ? <Tag color={blue.primary} icon={<StarOutlined />}>Default</Tag> : <></>}
        {!!card.disabled ? <Tag color={red.primary} icon={<ExclamationCircleOutlined />}>Disabled</Tag> : <></>}
      </Space> : <></>}
    </div>
    <Divider />
    <div style={{display: 'grid', gridTemplateColumns: 'auto 1fr', gap: '0.5rem'}}>
      <Text type='secondary'><FieldTimeOutlined /></Text>
      <Text>{card.expirationMonth}/{card.expirationYear}</Text>

      <Text type='secondary'><UserOutlined /></Text>
      <Text>{card.nameOnCard}</Text>
    </div>
  </Card>
}

const AddressesInner = ({ customer }: {customer: Customer}) => {
  const { merchant } = usePaylinkMerchantContext()
  const mid = merchant!.id!
  const cid = customer.id

  const { getAddresses } = usePaylinkAPI()

  const [ addresses, setAddresses ] = useState<Address[] | undefined>(undefined)
  const [ loading, setLoading ] = useState<boolean>(false)

  const refresh = useCallback(() => {
    setLoading(true)
    return getAddresses(mid, cid)
      .then(a => setAddresses(a.addresses))
      .then(_ => setLoading(false))
  }, [customer])

  useEffect(() => {
    refresh()
  }, [customer])

  return <Spin spinning={loading}>
    <Divider>Addresses</Divider>
    <Row gutter={[32, 32]}>
      <Col span={24}>
        <NewAddressCard mid={mid} cid={cid} onRefresh={refresh} />
      </Col>
      {!!addresses
        ? addresses.map(a => <Col xs={24} xl={12}>
            <AddressCard merchant={merchant!} customer={customer} address={a} key={a.id} onRefresh={refresh} />
          </Col>)
        : <></>}
    </Row>
  </Spin>
}

const PaymentMethodsInner = ({ customer }: {customer: Customer}) => {
  const { merchant } = usePaylinkMerchantContext()
  const mid = merchant!.id!
  const cid = customer.id

  const { getCards, getBankAccounts } = usePaylinkAPI()

  const [ cards, setCards ] = useState<CardMethod[] | undefined>(undefined)
  const [ loading, setLoading ] = useState<boolean>(false)

  const refresh = useCallback(() => {
    setLoading(true)
    return getCards(mid, cid)
      .then(c => setCards(c.cards))
      .then(_ => setLoading(false))
  }, [customer])

  useEffect(() => {
    refresh()
  }, [customer])

  return <Spin spinning={loading}>
    <Divider>Payment Methods</Divider>
    <Row gutter={[32, 32]}>
      {!!cards
        ? cards.map(c => <Col xs={24} xl={12}>
          <CardMethodCard card={c} key={c.id} />
        </Col>)
        : <></>}
      {/* {!!bankAccounts
        ? bankAccounts.map(b => <Card key={b.id}>{JSON.stringify(b)}</Card>)
        : <></>
      } */}
    </Row>
  </Spin>
}

export const CustomerView = () => {
  const { updateCustomer } = usePaylinkAPI()

  const history = useHistory()
  const { merchant, customers, reloadCustomers } = usePaylinkMerchantContext()

  const mid = merchant!.id!
  const { cid } = useParams<{cid: string}>()
  const customer = (customers || []).find(c => c.id === cid)

  const loading = useMemo(() => customer === undefined, [customer])
  const [ showForm, setShowForm ] = useState<boolean>(false)

  const refresh = useCallback(() => {
    reloadCustomers(mid)
  }, [mid, reloadCustomers])

  const handleUpdate = useCallback((values: CustomerRequest) => {
    if (!mid || !cid || !customer) return
    message.info({content: <Text>Updating customer <Text strong>{getCustomerName(values as Customer)}</Text></Text>, key: 'updateCustomer'})
    updateCustomer(mid, cid, {customer: {...values, disabled: false}})
      .then(refresh)
      .then(_ => {
        setShowForm(false)
        message.success({content: <Text>Customer <Text strong>{getCustomerName(values as Customer)}</Text> updated successfully!</Text>, key: 'updateCustomer'})
      })
      .catch(e => {
        message.error({content: <Text>Customer <Text strong>{getCustomerName(customer)}</Text> could not be updated</Text>, key: 'updateCustomer'})
      })
  }, [mid, cid, customer])

  const handleDelete = useCallback(() => {
    if (!mid || !cid || !customer) return
    message.info({content: <Text>Deleting customer <Text strong>{getCustomerName(customer)}</Text></Text>, key: 'deleteCustomer'})
    updateCustomer(mid, cid, {customer: {...customer, disabled: true}} as any)
      .then(refresh)
      .then(_ => {
        message.success({content: <Text>Customer <Text strong>{getCustomerName(customer)}</Text> deleted successfully!</Text>, key: 'deleteCustomer'})
        history.push(`/pl/${mid}/customers`)
      })
      .catch(e => {
        message.error({content: <Text>Customer <Text strong>{getCustomerName(customer)}</Text> could not be deleted</Text>, key: 'deleteCustomer'})
      })
  }, [mid, cid, customer])

  return <Spin spinning={loading}>
    {!!customer && <>
      <br />
      <Row gutter={[32, 32]}>
        {showForm
          ? <>
              <Col span={24}>
                <Card title='Edit Customer'>
                  <CustomerForm
                    initialValues={customer as CustomerRequest}
                    onSubmit={(values) => handleUpdate(values)}
                    onCancel={() => setShowForm(false)}
                  />
                </Card>
              </Col>
            </>
          : <>
              <Col span={24}>
                <Card>
                  <Result
                    title={<>
                      <CustomerTitle customer={customer} />
                    </>}
                    icon={<UserOutlined />}
                    extra={<Space size='middle'>
                      <Button type="primary" ghost icon={<EditOutlined />} onClick={() => setShowForm(true)}>Edit</Button>
                      <Popconfirm
                        onConfirm={() => handleDelete()}
                        title={<Text>Are you sure you want to delete customer <Text strong>{getCustomerName(customer!)}</Text>?</Text>}
                      >
                        <Button danger ghost icon={<DeleteOutlined />}>Delete</Button>
                      </Popconfirm>
                    </Space>}
                  />
                </Card>
              </Col>

              <Col span={24}>
                <Card title='Details'>
                  <Table
                    showHeader={false}
                    pagination={false}
                    columns={[{key: 'key', dataIndex: 'key', width: '50%', render: value => <Text strong>{value}</Text>}, {key: 'value', dataIndex: 'value', width: '50%'}]}
                    dataSource={[
                      {key: 'Email', value: customer.email},
                      {key: 'Phone Number', value: customer.phoneNumber},
                      {key: 'Merchant Account Number', value: customer.merchantAccountNumber}
                    ]}
                  />
                </Card>
              </Col>
            </>}
        

        <Col span={24}>
          <AddressesInner customer={customer} />
        </Col>

        <Col span={24}>
          <PaymentMethodsInner customer={customer} />
        </Col>
      </Row>
    </>}
    <br />
  </Spin>
}

import _ from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useAuth0 } from "@auth0/auth0-react"
import { useCasbinAPI } from "../hooks/useCasbinAPI"

export type useAuthorization_Shape = (object: string | undefined, actions: string[]) => {
  actionsLoaded: boolean,
  isAuthorized: (action: string) => boolean,
  isAuthorizedInScope: (action: string, scope: AuthorizationScope) => boolean,
  getIdToken: () => Promise<string | undefined>,
  hasFeature: (feature: string) => Promise<boolean>,
}

export enum AuthorizationScope {
  NONE = 'NONE',
  EXPLICIT = 'EXPLICIT',
  SUBCLASSED = 'SUBCLASSED',
  ADMIN = 'ADMIN'
}

const { NONE, EXPLICIT, SUBCLASSED, ADMIN } = AuthorizationScope

export const useAuthorization: useAuthorization_Shape = (object, actions) => {
  const { hasOwnPermissionSet } = useCasbinAPI()
  const { user, getIdTokenClaims } = useAuth0()

  const [allowedActions, setAllowedActions] = useState<any>({})
  const [actionsLoaded, setActionsLoaded] = useState<boolean>(false)

  const authorizationScopes = {
    [NONE]: 0,
    [EXPLICIT]: 1,
    [SUBCLASSED]: 2,
    [ADMIN]: 3
  }

  useEffect(() => {
    if (user) {
      (async () => {
        await loadPermissions(object, actions as never[])
      })()
    }
  }, [user])

  useEffect(() => {
    if (!_.isEmpty(allowedActions))
      setActionsLoaded(true)
  }, [allowedActions])

  const loadPermissions = useCallback(
    async (obj, acts = []) => {
      hasOwnPermissionSet(obj, acts)
        .then(actAuths => setAllowedActions(actAuths))
    }, [user]
  )

  const isAuthorized = useCallback(
    (act: string): boolean => {
      const authEntry = allowedActions[act]
      const authEntryExists = authEntry !== undefined
      const authEntryPasses = authorizationScopes[authEntry] > 0
      const isAuth = (authEntryExists && authEntryPasses) // if the value is "NONE", authorizationScopes["NONE"] returns 0, which is falsey

      //console.log(`isAuth returned ${isAuth}`)
      return isAuth
    }, [user, allowedActions]
  )

  const isAuthorizedInScope = useCallback(
    (act, scope: AuthorizationScope) => {
      const authEntry = allowedActions[act]
      const authEntryExists = authEntry !== undefined
      const authEntryPasses = authorizationScopes[authEntry] >= authorizationScopes[scope]
      const isAuthInScope = (authEntryExists && authEntryPasses)
      return isAuthInScope
    }, [user, allowedActions]
  )

  const getIdToken = useCallback(
    async () => {
      const c = await getIdTokenClaims()
      return c?.__raw
    }, [getIdTokenClaims]
  )

  const hasFeature = useCallback(
    async (feature) => {
      return hasOwnPermissionSet(`feature:${feature}`, ['viewer'])
        .then(r => {
          const { viewer } = r
          const x = authorizationScopes[ viewer ]
          // console.log("hasFeature", feature, x, r)
          return x
        })
    }, [user]
  )

  return { actionsLoaded, isAuthorized, isAuthorizedInScope, getIdToken, hasFeature }
}
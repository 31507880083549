import dayjs from "dayjs"
import { createReducer } from "typesafe-actions"
import { ProgramsFetched, ResetPrograms } from "./actions"
import { InterchangeAction, InterchangeState } from "./types"

const initialState = {
  jurisdictionToPrograms: [], // new Map<string, InterchangeProgramMetadata[]>(),
}

const reducer = createReducer<InterchangeState, InterchangeAction>(initialState)
  .handleAction(ProgramsFetched, (state, action) => {
    const {programs} = action

    let newKey: string | undefined = undefined
    programs.forEach((v, k) => {
      newKey = k
    })

    if (newKey) {
      let incoming = state.jurisdictionToPrograms
      let i = incoming.findIndex((v, i) => {
        return (v.jurisdiction === newKey)
      })

      let newPrograms = incoming.slice(0, i)
        .concat([{ jurisdiction: newKey, programWrapper: { lastFetch: dayjs().toISOString(), programs: programs.get(newKey) || [] }}])
        .concat(incoming.slice(i+1))

      if (newPrograms.length) {
        return {
          ...state,
          jurisdictionToPrograms: newPrograms
        }
      } else {
        return state
      }
    } else {
      return state
    }
  })
  .handleAction(ResetPrograms, (state, action) => {
    return {
      ...state,
      programs: [], // new Map<string, InterchangeProgramMetadata[]>(),
    }
  })

export { reducer as interchangeReducer }

import { blue } from "@ant-design/colors"
import { BookOutlined, ContactsOutlined, PlusOutlined } from "@ant-design/icons"
import { Button, Card, Col, Result, Row, Spin, Table, Typography } from "antd"
import { ColumnsType } from "antd/es/table"
import { FancyCard } from "components/Card"
import { CustomerForm } from "components/paylink/CustomerForm"
import { usePaylinkAPI } from "hooks/paylink/usePaylinkAPI"
import { useTableSearch } from "hooks/useTableSearch"
import { Merchant } from "models/models"
import { Customer, CustomerRequest } from "models/paylink"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router-dom"
import { usePaylinkMerchantContext } from "../PaylinkMerchantContext"

const { Text, Title } = Typography

const NewCustomerCard = ({mid, onRefresh}: {mid: string, onRefresh: () => Promise<void>}) => {
  const [ loading, setLoading ] = useState<boolean>(false)
  const [ showForm, setShowForm ] = useState<boolean>(false)

  const { createCustomer } = usePaylinkAPI()

  const handleSubmit = useCallback((request: CustomerRequest) => {
    setLoading(true)
    createCustomer(mid, {customer: request})
      .then(onRefresh)
      .then(_ => {
        setLoading(false)
        setShowForm(false)
      })
      .catch(e => {
        setLoading(false)
      })
  }, [])

  if (showForm) return <Spin spinning={loading}>
    <Card title='New Customer'>
      <CustomerForm onSubmit={handleSubmit} onCancel={() => setShowForm(false)} />
    </Card>
  </Spin>

  return <FancyCard style={{height: '100%', position: 'relative', overflow: 'hidden'}} icon={<PlusOutlined style={{color: blue[0]}} />} hoverable onClick={() => setShowForm(true)}>
    <Title level={4} style={{color: blue.primary, position: 'absolute', top: '50%', transform: 'translateY(-50%)'}}>New Customer</Title>
  </FancyCard>
}

const CustomersTable = ({mid, customers, loading}: {mid: string, customers: Customer[], loading: boolean}) => {
  const history = useHistory()

  const { getSearchProps } = useTableSearch<Customer>()

  const columns: ColumnsType<Customer> = [
    {
      title: 'Name',
      key: 'name',
      render: (_, customer) => <Text>{customer.firstName} {customer.lastName}</Text>,
      sorter: (a, b) => `${a.lastName} ${a.firstName}`.localeCompare(`${b.lastName} ${b.firstName}`),
      ...getSearchProps((value, record) => {
        const fullName = `${record.firstName} ${record.lastName}`.toLowerCase()
        const searchValue = value.toString().toLowerCase()
        return fullName.includes(searchValue)
      })
    },
    {
      title: 'Company',
      key: 'company',
      dataIndex: 'companyName',
      sorter: (a, b) => (a.companyName && b.companyName) ? a.companyName.localeCompare(b.companyName) : 0,
      ...getSearchProps((value, record) => {
        const searchValue = value.toString().toLowerCase()
        if (!record.companyName) return !searchValue.length
        const companyValue = record.companyName.toLowerCase()
        return companyValue.includes(searchValue)
      })
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email',
      sorter: (a, b) => a.email.localeCompare(b.email),
      ...getSearchProps((value, record) => {
        const searchValue = value.toString().toLowerCase()
        const recordValue = record.email.toLowerCase()
        return recordValue.includes(searchValue)
      })
    },
    {
      title: 'Merchant Account Number',
      key: 'merchantAccountNumber',
      dataIndex: 'merchantAccountNumber',
      sorter: (a, b) => a.merchantAccountNumber.localeCompare(b.merchantAccountNumber),
      ...getSearchProps((value, record) => {
        const searchValue = value.toString().toLowerCase()
        const recordValue = record.merchantAccountNumber.toLowerCase()
        return recordValue.includes(searchValue)
      })
    },
    {
      key: 'actions',
      render: (_, customer) => <div style={{textAlign: 'right'}}>
        <Button icon={<BookOutlined />} type='primary' ghost onClick={_ => history.push(`/pl/${mid}/customers/${customer.id}`)} />
      </div>
    }
  ]

  return <>
    <Spin spinning={loading}>
      <Table
        dataSource={customers}
        columns={columns}
      />
    </Spin>
  </>
}

export const CustomersInner = () => {
  const { merchant, customers, reloadCustomers } = usePaylinkMerchantContext()
  const mid = merchant!.id!
  const loading = useMemo(() => customers === undefined, [customers])

  const keyedCustomers = useMemo(() => (customers || []).map(c => ({...c, key: c.id})), [customers])

  const refresh = useCallback(() => {
    return reloadCustomers(mid)
  }, [mid])

  return <>
    <Row gutter={[32, 32]}>
      <Col span={24}>
        <Result
          title={<Title level={2}>Customers</Title>}
          icon={<ContactsOutlined />}
        />
      </Col>

      <Col span={24}>
        <NewCustomerCard mid={mid} onRefresh={refresh} />
      </Col>

      <Col span={24}>
        <CustomersTable mid={mid} customers={keyedCustomers} loading={loading} />
      </Col>
    </Row>
  </>
}

import { blue } from "@ant-design/colors"
import { BankTwoTone, BookOutlined, ContactsTwoTone, CreditCardTwoTone, DollarOutlined, HomeTwoTone, PlusCircleTwoTone } from "@ant-design/icons"
import { Alert, Button, Card, Cascader, Col, Empty, Form, Input, InputNumber, message, Modal, notification, Result, Row, Select, SelectProps, Spin, Switch, Table, Tag, Tooltip, Typography } from "antd"
import { v4 } from 'uuid'
import { PaylinkIFrame } from "components/paylink/PaylinkIFrame"
import { commaSeparatedFormatter, commaSeparatedParser } from "components/Processor/ProcessorCore"
import { usePaylinkAPI } from "hooks/paylink/usePaylinkAPI"
import { useAuthorization } from "hooks/useAuthorization"
import { filterUndefined, isRequiredRule, Merchant, UserRole } from "models/models"
import { Address, CardMethod, Customer, CustomerType, PaymentType, TransactionFeeResponse } from "models/paylink"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router-dom"
import { useForm, useWatch } from "antd/es/form/Form"
import useFormInstance from "antd/es/form/hooks/useFormInstance"
import useMessage from "antd/es/message/useMessage"
import { CustomTagProps } from "rc-select/lib/BaseSelect"
import { PhoneInput } from "components/PhoneInput"
import { ExpandableCard } from "components/ExpandableCard"
import { regionCascaderOptions } from "./RegionSelector"
import { usePaylinkMerchantContext } from "../PaylinkMerchantView/PaylinkMerchantContext"

const { Text, Paragraph } = Typography

type NewCustomer = 'newCustomer'
const NewCustomer: NewCustomer = 'newCustomer'

type NewPaymentMethod = 'newPaymentMethod'
const NewPaymentMethod: NewPaymentMethod = 'newPaymentMethod'

type NewAddress = 'newAddress'
const NewAddress: NewAddress = 'newAddress'

type NoAddress = 'noAddress'
const NoAddress: NoAddress = 'noAddress'

type SameAsBillingAddress = 'sameAsBillingAddress'
const SameAsBillingAddress: SameAsBillingAddress = 'sameAsBillingAddress'

type SameAsCustomerAddress = 'sameAsCustomerAddress'
const SameAsCustomerAddress: SameAsCustomerAddress = 'sameAsCustomerAddress'

type AddressOnFile = 'addressOnFile'
const AddressOnFile: AddressOnFile = 'addressOnFile'

enum AddressType {
  BILLING,
  SHIPPING
}

type AddressFormPartial_Shape = {
  line1: string,
  line2?: string,
  locality: string,
  region: [string, string],
  postalCode: string,
  phoneNumber?: string,
  email: string
}

type AddressForm_Shape = {
  firstName: string,
  lastName: string,
} & AddressFormPartial_Shape

type IndividualContact_Shape = {
  type: CustomerType.INDIVIDUAL,
  firstName: string,
  lastName: string,
  companyName?: string,
  merchantAccountNumber?: string,
} & AddressFormPartial_Shape

type CompanyContact_Shape = {
  type: CustomerType.COMPANY,
  companyName: string,
  merchantAccountNumber?: string,
} & AddressFormPartial_Shape

type Contact_Shape = IndividualContact_Shape | CompanyContact_Shape

type ExistingCustomer_Shape = {
  customerId: string,
}

type NewCustomer_Shape = {
  customerId: NewCustomer_Shape,
  customer: Contact_Shape,
}

type Customer_Shape = ExistingCustomer_Shape | NewCustomer_Shape

type ExistingAddress_Shape = {
  type: string,
}

type AddressOnFile_Shape = {
  type: AddressOnFile
}

type AddressSameAsCustomer_Shape = {
  type: SameAsCustomerAddress
}

type NewAddress_Shape = {
  type: NewAddress,
} & AddressForm_Shape

type Address_Shape = ExistingAddress_Shape | NewAddress_Shape | AddressSameAsCustomer_Shape | AddressOnFile_Shape

export type VTForm_Shape = {
  locationId: string,
  gatewayId: string,
  amount: number,
  paymentType: PaymentType,
  surcharge: boolean,
} & Customer_Shape & {
  paymentMethodId: string,

  billingAddress: Address_Shape,
  shippingAddress: Address_Shape,
  invoiceNumbers: string[],
  purchaseOrderNumbers: string[],
}

const keyValueColumns = [
  {key: 'key', dataIndex: 'key', render: (value) => <Text strong>{value}</Text>},
  {key: 'value', dataIndex: 'value'}
]

export const AddressForm = ({ path }: {path: string[]}) => {
  return <Row gutter={[16, 16]}>
    <Col span={12}>
      <Form.Item layout='vertical' label='First Name' name={[...path, 'firstName']} rules={[isRequiredRule('Please enter a first name')]}>
        <Input placeholder='John' />
      </Form.Item>
    </Col>

    <Col span={12}>
      <Form.Item layout='vertical' label='Last Name' name={[...path, 'lastName']} rules={[isRequiredRule('Please enter a last name')]}>
        <Input placeholder='Doe' />
      </Form.Item>
    </Col>

    <Col span={24}>
      <Form.Item layout='vertical' label='Address' required>
        <Form.Item noStyle name={[...path, 'line1']} rules={[isRequiredRule('Please enter a street address')]}>
          <Input placeholder='1 Sample Drive' />
        </Form.Item>
        <Form.Item noStyle name={[...path, 'line2']}>
          <Input placeholder='Unit 1' style={{marginBlockStart: '0.25em'}} />
        </Form.Item>
      </Form.Item>
    </Col>

    <Col span={8}>
      <Form.Item layout='vertical' label='City' name={[...path, 'locality']} rules={[isRequiredRule('Please enter a city')]}>
        <Input placeholder='Sample City' />
      </Form.Item>
    </Col>

    <Col span={8}>
      <Form.Item layout='vertical' label='Region' name={[...path, 'region']} rules={[{ type: 'array', required: true }]}>
        <Cascader onChange={v => console.log(v)} placeholder='Sample Country / Sample Region' options={regionCascaderOptions} />
      </Form.Item>
    </Col>

    <Col span={8}>
      <Form.Item layout='vertical' label='Postal Code' name={[...path, 'postalCode']} rules={[isRequiredRule('Please enter a postal code')]}>
        <Input placeholder='11111' />
      </Form.Item>
    </Col>

    <Col span={12}>
      <Form.Item layout='vertical' label='Phone Number' name={[...path, 'phoneNumber']} rules={[isRequiredRule('Please enter a phone number')]}>
        <PhoneInput />
      </Form.Item>
    </Col>

    <Col span={12}>
      <Form.Item layout='vertical' label='Email' name={[...path, 'email']} rules={[isRequiredRule('Please enter an email address'), {type: 'email', message: 'Please enter a valid email address'}]}>
        <Input placeholder='john@doe.com' />
      </Form.Item>
    </Col>
  </Row>
}

const CustomerSelector = () => {
  const { customers } = usePaylinkMerchantContext()

  const [ loading, setLoading ] = useState<boolean>(false)

  const options = useMemo(() => {
    if (!customers) return []
    return [
      {label: <Text strong style={{color: blue.primary}}><PlusCircleTwoTone twoToneColor={blue.primary} style={{marginInlineEnd: '0.5em'}} />New Customer</Text>, key: NewCustomer, value: NewCustomer},
      ...(customers.map((c, i) => {
        return {label: <Text><ContactsTwoTone twoToneColor={blue.primary} style={{marginInlineEnd: '0.5em'}} />{c.firstName} {c.lastName}{!!c.companyName ? <Text type='secondary'> ({c.companyName})</Text> : <></>} </Text>, key: c.id, value: c.id, customer: c}
      }))
    ]
  }, [customers])

  return <Form.Item noStyle name='customerId' rules={[isRequiredRule('Please select a customer')]}>
    <Select
      filterOption={(value, option) => {
        if (!option) return false
        if (option.key === NewCustomer) return true
        const searchValue = value.toLowerCase()
        const optionCustomer = (option as {customer: Customer}).customer
        const optionName = `${optionCustomer.firstName} ${optionCustomer.lastName}`.toLowerCase()
        const optionCompany = (optionCustomer.companyName || '').toLowerCase()
        return [optionName, optionCompany].some(v => v.includes(searchValue))
      }}
      disabled={loading}
      loading={loading}
      showSearch
      options={options}
      style={{width: '100%'}}
    />
  </Form.Item>
}

type CardLabel_Props = {
  card: CardMethod,
}
const CardLabel = ({ card }: CardLabel_Props) => {
  return <Text>
    <CreditCardTwoTone twoToneColor={blue.primary} style={{marginInlineEnd: '0.5em'}} />
    {card.cardNumber}
    <Text type='secondary'> ({card.nameOnCard})</Text>
  </Text>
}

type PaymentMethodSelector_Props = {
  customerId: string | undefined,
}
type PaymentCard = any
type BankAccount = any
const PaymentMethodSelector = ({ customerId }: PaymentMethodSelector_Props) => {
  const form = useFormInstance()

  const { merchant } = usePaylinkMerchantContext()
  const { getCards, getBankAccounts } = usePaylinkAPI()

  const [ loading, setLoading ] = useState<boolean>(false)
  const [ cards, setCards ] = useState<PaymentCard[]>([])
  const [ bankAccounts, setBankAccounts ] = useState<BankAccount[]>([])

  const options = useMemo(() => {
    console.log(cards, bankAccounts)
    return [
      {label: <Text strong style={{color: blue.primary}}><PlusCircleTwoTone twoToneColor={blue.primary} style={{marginInlineEnd: '0.5em'}} />New Payment Method</Text>, key: NewPaymentMethod, value: NewPaymentMethod},
      ...(cards.map((c, i) => {
        return {label: <CardLabel card={c} />, key: c.id, value: c.id}
      })),
      ...(bankAccounts.map((b, i) => {
        console.log(b)
        return {label: <Text><ContactsTwoTone twoToneColor={blue.primary} style={{marginInlineEnd: '0.5em'}} />Bank Account <Text type='secondary'>(Bank Account)</Text></Text>, key: b.id, value: b.id}
      }))
    ]
  }, [cards, bankAccounts])

  useEffect(() => {
    form.setFieldValue('paymentMethodId', NewPaymentMethod)
    setCards([])
    setBankAccounts([])
    if (!customerId || customerId === NewCustomer) {
      setLoading(false)
      return
    }
    setLoading(true)
    Promise.all([getCards(merchant!.id!, customerId), getBankAccounts(merchant!.id!, customerId)])
      .then(([c, b]) => {
        setCards(c.cards)
        setBankAccounts(b.bankAccounts)
        setLoading(false)
      })
  }, [merchant, customerId])

  return <Form.Item noStyle name='paymentMethodId' rules={[isRequiredRule('Please select a payment method')]}>
    <Select
      disabled={!merchant!.id! || !customerId || loading}
      loading={loading}
      options={options}
      style={{width: '100%'}}
    />
  </Form.Item>
}

type AddressLabel_Props = {
  address: Address,
}
const AddressLabel = ({ address }: AddressLabel_Props) => {
  return <Text>
    <ContactsTwoTone twoToneColor={blue.primary} style={{marginInlineEnd: '0.5em'}} />
    {address.firstName} {address.lastName}
    <Text type='secondary'> ({address.line1}, {address.locality}, {address.region} {address.postalCode})</Text>
  </Text>
}

const TransactionFeeConfirmationModal = ({ paymentData, transactionFeeData, onConfirm, onCancel }: {paymentData: DoIt_Shape, transactionFeeData: TransactionFeeResponse | undefined, onConfirm: () => void, onCancel: () => void}) => {
  const subtotal = paymentData.amount

  if (!transactionFeeData) {
    return <Modal open onOk={onConfirm} onCancel={() => {console.log('cancel'); onCancel();}} title='Surcharge Disclosure'>
      <Alert type='info' showIcon message='No additional fee will be applied to this payment' />
      <br />
      <Table
        columns={keyValueColumns}
        dataSource={[
          {key: 'Total', value: (subtotal / 100).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2})}
        ]}
        pagination={false}
        showHeader={false}
      />
    </Modal>
  }

  const transactionFee = transactionFeeData.transactionFee.amount
  const total = subtotal + transactionFee

  return <Modal open onOk={onConfirm} onCancel={() => {console.log('cancel'); onCancel();}} title='Surcharge Disclosure'>
    <Alert type='warning' showIcon message='We may apply a fee to cover all or part of our costs of accepting credit cards' />
    <br />
    <Table
      columns={keyValueColumns}
      dataSource={[
        {key: 'Subtotal', value: (subtotal / 100).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2})},
        {key: 'Transaction Fee', value: (transactionFee / 100).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2})},
        {key: 'Total', value: (total / 100).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2})}
      ]}
      pagination={false}
      showHeader={false}
    />
  </Modal>
}

type NewPaymentMethod_Shape = {
  nonce: string,
}
type ExistingPaymentMethod_Shape = {
  paymentMethod: CardMethod,
}

type PaymentMethod_Shape = NewPaymentMethod_Shape | ExistingPaymentMethod_Shape

type DoIt_Shape = VTForm_Shape & PaymentMethod_Shape

const CustomerFormControl = () => {
  const form = useFormInstance()

  const customerType = useWatch(['customer', 'type'], form)

  useEffect(() => {
    form.setFieldValue(['customer', 'type'], CustomerType.INDIVIDUAL)
  }, [])

  return <>
    <Row gutter={[16, 8]}>
      <Col span={24}>
        <Form.Item label='Customer Type' name={['customer', 'type']} layout='vertical' rules={[isRequiredRule('Please select a customer type')]}>
          <Select options={Object.values(CustomerType).map(t => ({key: t, value: t, label: t}))} />
        </Form.Item>
      </Col>

      {customerType === CustomerType.INDIVIDUAL && <>
        <Col xs={24} xl={12}>
          <Form.Item label='First Name' name={['customer', 'firstName']} layout='vertical' rules={[isRequiredRule('Please enter a first name')]}>
            <Input placeholder='John' />
          </Form.Item>
        </Col>
        <Col xs={24} xl={12}>
          <Form.Item label='Last Name' name={['customer', 'lastName']} layout='vertical' rules={[isRequiredRule('Please enter a last name')]}>
            <Input placeholder='Doe' />
          </Form.Item>
        </Col>
      </>}

      <Col span={24}>
        <Form.Item label='Company Name' name={['customer', 'companyName']} layout='vertical' rules={customerType === CustomerType.COMPANY ? [isRequiredRule('Please enter a company name')] : []}>
          <Input placeholder='Placeholder Industries' />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item layout='vertical' label='Address' required>
          <Form.Item noStyle name={['customer', 'line1']} rules={[isRequiredRule('Please enter a street address')]}>
            <Input placeholder='1 Sample Drive' />
          </Form.Item>
          <Form.Item noStyle name={['customer', 'line2']}>
            <Input placeholder='Unit 1' style={{marginBlockStart: '0.25em'}} />
          </Form.Item>
        </Form.Item>
      </Col>

      <Col xs={24} xl={8}>
        <Form.Item layout='vertical' label='City' name={['customer', 'locality']} rules={[isRequiredRule('Please enter a city')]}>
          <Input placeholder='Sample City' />
        </Form.Item>
      </Col>

      <Col xs={24} xl={8}>
        <Form.Item layout='vertical' label='Region' name={['customer', 'region']} rules={[{ type: 'array', required: true }]}>
          <Cascader onChange={v => console.log(v)} placeholder='Sample Country / Sample Region' options={regionCascaderOptions} />
        </Form.Item>
      </Col>

      <Col xs={24} xl={8}>
        <Form.Item layout='vertical' label='Postal Code' name={['customer', 'postalCode']} rules={[isRequiredRule('Please enter a postal code')]}>
          <Input placeholder='11111' />
        </Form.Item>
      </Col>

      <Col xs={24} xl={12}>
        <Form.Item label='Email' name={['customer', 'email']} layout='vertical' rules={[isRequiredRule('Please enter an email address'), {type: 'email', message: 'Please enter a valid email address'}]}>
          <Input placeholder='john@doe.com' />
        </Form.Item>
      </Col>

      <Col xs={24} xl={12}>
        <Form.Item label='Phone Number' name={['customer', 'phoneNumber']} layout='vertical' rules={[isRequiredRule('Please enter a phone number')]}>
          <PhoneInput />
        </Form.Item>
      </Col>

      <Col xs={24} xl={12}>
        <Form.Item label='Merchant Account Number' name={['customer', 'merchantAccountNumber']} layout='vertical'>
          <Input placeholder='123456789000000' />
        </Form.Item>
      </Col>
    </Row>
  </>
}

const VTHeader = () => {
  return <>
    <Result
      icon={<DollarOutlined />}
      extra={<>
        <Typography.Title level={2}>New Payment</Typography.Title>
        <div style={{maxWidth: '80ch', margin: '0 auto', fontSize: 'clamp(0.75rem, calc(0.75rem + 0.25vw), 1.125rem)'}}>
          {/* Idk put some copy here */}
        </div>
      </>}
    />
  </>
}

export const PaymentCard = ({ authorization }: {authorization: Set<UserRole>}) => {
  const { gateways, locations, gatewayConfigs } = usePaylinkMerchantContext()

  const form = useFormInstance()
  const locationId = useWatch(['locationId'], form)

  const locationOptions = useMemo(() => {
    if (!locations) return []
    return locations
      .sort((a, b) => a.contact.name.localeCompare(b.contact.name))
      .map(l => ({key: l.id, value: l.id, label: l.contact.name}))
  }, [locations])

  const gatewayOptions = useMemo(() => {
    if (!gateways) return []
    return gateways
      .filter(g => {
        const matchingGatewayConfig = (gatewayConfigs || []).find(gc => gc.gatewayId === g.id)
        if (!matchingGatewayConfig) return false
        return matchingGatewayConfig.locationIds.includes(locationId)
      })
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(g => ({key: g.id, value: g.id, label: g.name}))
  }, [gateways, gatewayConfigs, locationId])

  useEffect(() => {
    if (!locationOptions || locationOptions.length === 0) return
    form.setFieldValue('locationId', locationOptions[0].value)
  }, [locationOptions])

  useEffect(() => {
    if (!gatewayOptions || gatewayOptions.length === 0) return
    form.setFieldValue('gatewayId', gatewayOptions[0].value)
  })

  return <Card title='Payment'>
    <Row gutter={[16, 8]}>
      <Col xs={24} xl={12}>
        <Form.Item layout='vertical' label='Location' name={['locationId']} rules={[isRequiredRule('Please select a location')]}>
          <Select
            style={{width: '100%'}}
            options={locationOptions}
          />
        </Form.Item>
      </Col>

      <Col xs={24} xl={12}>
        <Form.Item layout='vertical' label='Gateway' name={['gatewayId']} rules={[isRequiredRule('Please select a gateway')]}>
          <Select
            style={{width: '100%'}}
            options={gatewayOptions}
          />
        </Form.Item>
      </Col>

      <Col xs={24} xl={12}>
        <Form.Item layout='vertical' label='Amount' name='amount' rules={[isRequiredRule('Please enter a transaction amount')]}>
          <InputNumber
            addonBefore='$'
            min={0}
            step={0.01}
            precision={2}
            style={{width: '100%'}}
            formatter={commaSeparatedFormatter}
            parser={commaSeparatedParser}
          />
        </Form.Item>
      </Col>

      <Col xs={24} xl={12}>
        <Form.Item layout='vertical' label='Payment Type' name='paymentType' rules={[isRequiredRule('Please select a payment type')]} initialValue={PaymentType.SALE}>
          <Select options={Object.values(PaymentType).map(t => ({label: t, key: t, value: t}))} />
        </Form.Item>
      </Col>

      <Col xs={24} xl={12}>
        <Form.Item layout='vertical' label='Apply Surcharge?' name='surcharge' valuePropName="checked" initialValue={true}>
          <Switch disabled={!authorization.has(UserRole.PAYLINK_ADMIN)} />
        </Form.Item>
      </Col>

    </Row>
  </Card>
}

const CustomerCard = () => {
  const form = useFormInstance()
  const customerId = useWatch('customerId', form)

  return <ExpandableCard
    enabled={customerId === NewCustomer}
    title='Customer'
    styles={{extra: {width: 'calc(50% - 8px)'}}}
    extra={<Form.Item noStyle name={['customerId']} rules={[isRequiredRule('Please select a customer')]} initialValue={NewCustomer}>
      <CustomerSelector />
    </Form.Item>}
  >
    <CustomerFormControl />
  </ExpandableCard>
}

const PaymentMethodCard = ({ submit, onTokenReceived }: {submit: boolean, onTokenReceived: (data: any) => void}) => {
  const { merchant } = usePaylinkMerchantContext()
  const merchantId = merchant!.id!

  const form = useFormInstance()
  const gatewayId = useWatch('gatewayId', form)
  const customerId = useWatch('customerId', form)
  const paymentMethodId = useWatch('paymentMethodId', form)

  const key = useMemo(() => Math.random(), [merchantId, gatewayId])

  return <ExpandableCard
    enabled={paymentMethodId === NewPaymentMethod}
    title='Payment Method'
    styles={{extra: {width: 'calc(50% - 8px)'}}}
    extra={<PaymentMethodSelector customerId={customerId} />}
  >
    <Row gutter={[32, 32]}>
      <Col span={24}>
        {!!merchantId && !!gatewayId && paymentMethodId === NewPaymentMethod
          ? <PaylinkIFrame key={key} merchantId={merchantId} gatewayId={gatewayId} submit={submit} onTokenReceived={onTokenReceived} />
          : <Empty description='Please select a merchant and/or gateway' />
        }
      </Col>
    </Row>
  </ExpandableCard>
}

const DetailsCard = () => {
  return <>
    <Card title='Details'>
      <Row gutter={[32, 32]}>
        <Col span={12}>
          <Form.Item layout='vertical' label='Invoice Number(s)' name='invoiceNumbers' rules={[]}>
            <Select
              style={{width: '100%'}}
              mode='tags'
              tokenSeparators={[',', '\r\n', '\n', '\t']}
              tagRender={({label, onClose}: CustomTagProps) => {
                const handleCopy = (event: React.MouseEvent<HTMLSpanElement>) => {
                  // prevent bubbling through the component and selecting the parent input
                  event.preventDefault()
                  event.stopPropagation()

                  // copy text to the clipboard
                  const labelText = label!.toString()
                  navigator.clipboard.writeText(labelText)
                    .then(_ => message.success(`Copied invoice number ${labelText} to the clipboard`))
                }

                return (
                  <Tooltip title={'Double click to copy'}>
                    <Tag
                      style={{cursor: 'pointer'}}
                      closable={true}
                      onDoubleClick={handleCopy}
                      onClose={onClose}
                    >
                      {label}
                    </Tag>
                  </Tooltip>
                )
              }}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item layout='vertical' label='Purchase Order Number(s)' name='purchaseOrderNumbers' rules={[]}>
            <Select
              style={{width: '100%'}}
              mode='tags'
              tokenSeparators={[',', '\r\n', '\n', '\t']}
              tagRender={({label, onClose}: CustomTagProps) => {
                const handleCopy = (event: React.MouseEvent<HTMLSpanElement>) => {
                  // prevent bubbling through the component and selecting the parent input
                  event.preventDefault()
                  event.stopPropagation()

                  // copy text to the clipboard
                  const labelText = label!.toString()
                  navigator.clipboard.writeText(labelText)
                    .then(_ => message.success(`Copied purchase order number ${labelText} to the clipboard`))
                }

                return (
                  <Tooltip title={'Double click to copy'}>
                    <Tag
                      style={{cursor: 'pointer'}}
                      closable={true}
                      onDoubleClick={handleCopy}
                      onClose={onClose}
                    >
                      {label}
                    </Tag>
                  </Tooltip>
                )
              }}
            />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  </>
}

const NoAddressLabel = () => <Text type='secondary'>No Address</Text>
const SameAsCustomerAddressLabel = () => <Text strong style={{color: blue.primary}}><ContactsTwoTone twoToneColor={blue.primary} style={{marginInlineEnd: '0.5em'}} />Same As Customer Address</Text>
const NewAddressLabel = () => <Text strong style={{color: blue.primary}}><PlusCircleTwoTone twoToneColor={blue.primary} style={{marginInlineEnd: '0.5em'}} />New Address</Text>
const AddressOnFileLabel = () => <Text strong style={{color: blue.primary}}><BankTwoTone twoToneColor={blue.primary} style={{marginInlineEnd: '0.5em'}} />Address On File</Text>

const BillingAddressCard = () => {
  const { merchant } = usePaylinkMerchantContext()
  const form = useFormInstance()
  const { getAddresses } = usePaylinkAPI()

  const merchantId = merchant!.id!
  const customerId = useWatch(['customerId'], form)
  const paymentMethodId = useWatch(['paymentMethodId'], form)
  const addressId = useWatch(['billingAddress', 'type'], form)

  const [ addresses, setAddresses ] = useState<Address[]>([])
  const [ loading, setLoading ] = useState<boolean>(false)

  useEffect(() => {
    if (!customerId) return
    if (customerId === NewCustomer) {
      setAddresses([])
      return
    }
    setLoading(true)
    getAddresses(merchantId, customerId)
      .then(a => {
        setAddresses(a.addresses)
        setLoading(false)
      })
  }, [customerId])

  useEffect(() => {
    if (paymentMethodId !== NewPaymentMethod) form.setFieldValue(['billingAddress', 'type'], AddressOnFile)
    else {
      if (customerId === NewCustomer) form.setFieldValue(['billingAddress', 'type'], SameAsCustomerAddress)
      else form.setFieldValue(['billingAddress', 'type'], NewAddress)
    }
  }, [customerId, paymentMethodId])

  const options = useMemo(() => {
    const sameAsCustomerAddress = {label: <SameAsCustomerAddressLabel />, key: SameAsCustomerAddress, value: SameAsCustomerAddress}
    const newAddress = {label: <NewAddressLabel />, key: NewAddress, value: NewAddress}
    const addressOnFile = {label: <AddressOnFileLabel />, key: AddressOnFile, value: AddressOnFile}
    if (paymentMethodId !== NewPaymentMethod) return [addressOnFile]
    return [
      ...(customerId === NewCustomer ? [sameAsCustomerAddress] : []),
      newAddress,
      ...addresses.map(a => ({label: <AddressLabel address={a} />, key: a.id, value: a.id}))
    ]
  }, [customerId, paymentMethodId, addresses])

  return <ExpandableCard
    enabled={addressId === NewAddress && paymentMethodId === NewPaymentMethod}
    title='Billing Address'
    styles={{extra: {width: 'calc(50% - 8px)'}}}
    extra={<Form.Item noStyle name={['billingAddress', 'type']} rules={[isRequiredRule('Please select an option')]}>
      <Select
        style={{width: '100%'}}
        disabled={!merchantId || !customerId || loading || paymentMethodId !== NewPaymentMethod}
        loading={loading}
        options={options}
      />
    </Form.Item>}
  >
    <AddressForm path={['billingAddress']} />
  </ExpandableCard>
}

const ShippingAddressCard = () => {
  const { merchant } = usePaylinkMerchantContext()
  const form = useFormInstance()
  const { getAddresses } = usePaylinkAPI()

  const merchantId = merchant!.id!
  const customerId = useWatch(['customerId'], form)
  const paymentMethodId = useWatch(['paymentMethodId'], form)
  const addressId = useWatch(['shippingAddress', 'type'], form)

  const [ addresses, setAddresses ] = useState<Address[]>([])
  const [ loading, setLoading ] = useState<boolean>(false)

  useEffect(() => {
    if (!customerId) return
    if (customerId === NewCustomer) {
      setAddresses([])
      return
    }
    setLoading(true)
    getAddresses(merchantId, customerId)
      .then(a => {
        setAddresses(a.addresses)
        setLoading(false)
      })
  }, [customerId])

  useEffect(() => {
    form.setFieldValue(['shippingAddress', 'type'], NoAddress)
  }, [customerId, paymentMethodId])

  const options = useMemo(() => {
    const noAddress = {label: <NoAddressLabel />, key: NoAddress, value: NoAddress}
    const sameAsCustomerAddress = {label: <SameAsCustomerAddressLabel />, key: SameAsCustomerAddress, value: SameAsCustomerAddress}
    const newAddress = {label: <NewAddressLabel />, key: NewAddress, value: NewAddress}
    return [
      noAddress,
      ...(customerId === NewCustomer ? [sameAsCustomerAddress] : []),
      newAddress,
      ...addresses.map(a => ({label: <AddressLabel address={a} />, key: a.id, value: a.id}))
    ]
  }, [customerId, paymentMethodId, addresses])

  return <ExpandableCard
    enabled={addressId === NewAddress && paymentMethodId === NewPaymentMethod}
    title='Shipping Address'
    styles={{extra: {width: 'calc(50% - 8px)'}}}
    extra={<Form.Item noStyle name={['shippingAddress', 'type']} rules={[isRequiredRule('Please select an option')]}>
      <Select
        style={{width: '100%'}}
        disabled={!merchantId || !customerId || loading}
        loading={loading}
        options={options}
      />
    </Form.Item>}
  >
    <AddressForm path={['shippingAddress']} />
  </ExpandableCard>
}

/*
const useVirtualTerminal = () => {
  const buildCustomer = (form: VTForm_Shape) => {
    if (form.customerId === NewCustomer) {
      const { customerType, ...customer } = (form as NewCustomer_Shape).customer
      return {customer}
    }
    return {customerId: form.customerId} as ExistingCustomer_Shape
  }

  const formToRequest = (form: VTForm_Shape) => {
    const customer = buildCustomer(form)
    const amount = Math.floor(form.amount * 100)
    return {
      locationId: form.locationId,
      gatewayId: form.gatewayId,
      amount,
      paymentType: form.paymentType,
      ...customer
    }
  }
}
*/

export const PaylinkVirtualTerminalView = ({merchant}: {merchant: Merchant}) => {
  const history = useHistory()

  const [ message, contextHolder ] = useMessage()

  const [ form ] = useForm()

  const mid = merchant.id!

  const { createPayment, createTransactionFee, getCard, getAddress } = usePaylinkAPI()
  const { reloadPayments } = usePaylinkMerchantContext()

  const { isAuthorized, actionsLoaded } = useAuthorization(`merchant:${mid}`, [UserRole.ADMIN, UserRole.PAYLINK_ADMIN])
  const notAuthorized = useMemo(() => actionsLoaded && !isAuthorized('admin'), [actionsLoaded, isAuthorized])

  const [ loading, setLoading ] = useState<boolean>(false)

  const [ iFrameKey, setIFrameKey ] = useState<string>(v4())
  const [ submitIFrame, setSubmitIFrame ] = useState<boolean>(false)

  const [ storedPaymentData, setStoredPaymentData ] = useState<DoIt_Shape | undefined>(undefined)
  const [ storedTransactionFeeData, setStoredTransactionFeeData ] = useState<TransactionFeeResponse | undefined>(undefined)
  const [ showTransactionFeeConfirmation, setShowTransactionFeeConfirmation ] = useState<boolean>(false)

  const authorization = useMemo(() => {
    const authorizationArray = [UserRole.PAYLINK_PAYER, UserRole.PAYLINK_ACCOUNTING, UserRole.PAYLINK_ADMIN].map(role => isAuthorized(role) ? role : undefined).filter(filterUndefined)
    return new Set(authorizationArray)
  }, [actionsLoaded, isAuthorized])

  const reset = useCallback(() => {
    setLoading(false)
    setIFrameKey(v4())
    setSubmitIFrame(false)
    setStoredPaymentData(undefined)
    setStoredTransactionFeeData(undefined)
    setShowTransactionFeeConfirmation(false)
  }, [mid, setLoading, setIFrameKey, setSubmitIFrame, setStoredPaymentData, setStoredTransactionFeeData, setShowTransactionFeeConfirmation])

  const adjustValues = useCallback((naiveValues: VTForm_Shape) => {
    const amount = Math.floor(naiveValues.amount * 100)
    return {...naiveValues, amount}
  }, [])

  const onTokenReceived = useCallback(async (data: any) => {
    // Second step for iFrame-included flows
    message.info({content: 'Card tokenized successfully!', key: 'tokenizeCard'})
    const values: VTForm_Shape = adjustValues(form.getFieldsValue())
    onPaymentSubmission({...values, nonce: data.nonce})
  }, [form])

  const submitPaymentForm = useCallback(async (naiveValues: VTForm_Shape) => {
    if (!mid) return

    const values = adjustValues(naiveValues)

    if (values.paymentMethodId === NewPaymentMethod) {
      // If we are submitting a new payment method, use IFrame's onMessageReceived to call second-stage logic
      message.info({content: 'Tokenizing card...', key: 'tokenizeCard'})
      setLoading(true)
      setSubmitIFrame(true)
      return
    }

    // Else call it directly
    setLoading(true)
    message.info({content: 'Loading stored card...', key: 'storedCard'})
    const card = await getCard(mid, values.paymentMethodId).then(r => r.card)
    message.success({content: 'Stored card loaded successfully!', key: 'storedCard'})
    return onPaymentSubmission({...values, paymentMethod: card})
  }, [mid])

  const calculateSurcharge = useCallback(async (values: DoIt_Shape) => {
    if (!mid) return Promise.reject()

    const region = await getSurchargeRegion(values)
    console.log('region is', region)

    if ('nonce' in values) {
      return createTransactionFee(mid, {idempotencyKey: v4(), transactionFee: {gatewayId: values.gatewayId, paymentMethodNonce: values.nonce, region, transaction: {amount: values.amount}}})
    } else if ('paymentMethod' in values) {
      return createTransactionFee(mid, {idempotencyKey: v4(), transactionFee: {gatewayId: values.gatewayId, paymentMethodToken: values.paymentMethod.token, region, transaction: {amount: values.amount}}})
    }

    return Promise.reject()
  }, [mid])

  const onPaymentSubmission = useCallback(async (values: DoIt_Shape) => {
    if (!mid) return

    const { surcharge } = values
    
    if (surcharge) {
      message.info({content: 'Calculating surcharge...', key: 'surcharge'})
      const surchargeResponse = await calculateSurcharge(values)
      message.success({content: 'Surcharge calculated successfully!', key: 'surcharge'})
      setStoredPaymentData(values)
      if (!surchargeResponse) {
        console.warn('No surcharge could be calculated')
        setStoredTransactionFeeData(undefined)
      } else {
        setStoredTransactionFeeData(surchargeResponse)
      }
    } else {
      setStoredPaymentData(values)
      setStoredTransactionFeeData(undefined)
    }

    setShowTransactionFeeConfirmation(true)
  }, [mid])

  const onTransactionFeeConfirmation = useCallback(async () => {
    if (!mid) return

    setShowTransactionFeeConfirmation(false)
    message.info({content: 'Submitting payment...', key: 'payment'})

    const values = storedPaymentData as DoIt_Shape
    const transactionFeeId = !!storedTransactionFeeData ? storedTransactionFeeData.transactionFee.id : undefined

    const { gatewayId } = values

    const customerValues = getCustomerValues(values)
    const shippingAddressValues = (!!values.shippingAddress) ? getAddressValues(values, AddressType.SHIPPING) : undefined
    const paymentValues = await getPaymentValues(values)
    console.log('payment values', paymentValues)

    const paymentRequest = {
      idempotencyKey: v4(),
      payment: {
        gatewayId,
        capture: values.paymentType === PaymentType.SALE,
        ...shippingAddressValues,
        ...customerValues,
        ...paymentValues,
        merchantReferenceCode: v4(),
        amount: values.amount,
        ...(!!transactionFeeId ? {transactionFeeId} : {}),
        invoiceNumbers: values.invoiceNumbers,
        purchaseOrderNumbers: values.purchaseOrderNumbers,
      }
    }

    return createPayment(mid, paymentRequest as any)
      .then(payment => {
        return reloadPayments(mid)
          .then(_ => payment)
      })
      .then(payment => {
        message.success({ content: 'Payment submitted successfully!', key: 'payment' })
        notification.success({ message: <>
          <Paragraph>Payment created!</Paragraph>
          <Button type='primary' icon={<BookOutlined />} onClick={() => history.push(`/pl/${mid}/payments/${payment.payment.id}`)}>Go to Payment</Button>
        </>, key: payment.payment.id })
      })
      .catch(error => {
        console.error('[PaylinkVirtualTerminalView] Error during payment creation', error)
        message.error({ content: 'Payment could not be created...', key: 'payment' })
        notification.error({ message: 'Payment could not be created...', description: (error?.message || '') })
      })
      .then(_ => {
        reset()
      })

  }, [mid, storedPaymentData, storedTransactionFeeData])

  const getCustomerValues = useCallback((values: VTForm_Shape) => {
    const { customerId } = values
    if (customerId === NewCustomer) {
      const scopedValues = values as (NewCustomer_Shape)
      const { customer } = scopedValues
      console.log(customer.type)
      return {
        customer: {
          firstName: customer.type === CustomerType.INDIVIDUAL ? (customer as IndividualContact_Shape).firstName : undefined,
          lastName: customer.type === CustomerType.INDIVIDUAL ? (customer as IndividualContact_Shape).lastName : undefined,
          companyName: customer.companyName,
          line1: customer.line1,
          line2: !!customer.line2 ? customer.line2 : undefined,
          locality: customer.locality,
          postalCode: customer.postalCode,
          region: customer.region[1],
          country: customer.region[0],
          email: customer.email,
          phoneNumber: !!customer.phoneNumber ? customer.phoneNumber.replace(/\D/g, "") : undefined,
          merchantAccountNumber: customer.merchantAccountNumber,
        }
      }
    } else {
      return { customerId }
    }
  }, [mid])

  const getNameOnCard = useCallback(async (values: DoIt_Shape) => {
    const address = getAddressValues(values, AddressType.BILLING)
    if (!!address.address) return `${address.address.firstName} ${address.address.lastName}`
    const stored = await getAddress(mid, address.addressId!)
    return `${stored.address.firstName} ${stored.address.lastName}`
  }, [mid])

  const getPaymentValues = useCallback(async (values: DoIt_Shape) => {
    console.log(values)
    if ('nonce' in values) return {
      card: {
        nonce: values.nonce,
        nameOnCard: await getNameOnCard(values),
        persist: true,
        ...getAddressValues(values, AddressType.BILLING)
      }
    }
    else if ('paymentMethod' in values) return {
      paymentMethodToken: (values).paymentMethod.token
    }
  }, [mid])

  const getAddressValues = useCallback((values: DoIt_Shape, address: AddressType) => {
    const addressPath = address === AddressType.SHIPPING ? values.shippingAddress : values.billingAddress
    if (addressPath === undefined) return {}
    const { type } = addressPath
    if (type === NoAddress) {
      return {}
    } else if (type === SameAsCustomerAddress) {
      const customerValues = getCustomerValues(values)
      if (!customerValues.customer) return {}
      return {address: {
        ...customerValues.customer,
      }}
    } else if (type === SameAsBillingAddress) {
      return getAddressValues(values, AddressType.BILLING)
    } else if (type === NewAddress) {
      const newAddress = addressPath as NewAddress_Shape
      const { firstName, lastName, locality, region, postalCode, phoneNumber, email } = newAddress
      return {address: {
        firstName,
        lastName,
        line1: newAddress.line1,
        ...(!!newAddress.line2 ? {line2: newAddress.line2} : {}),
        locality,
        region: region[1],
        country: region[0],
        postalCode: postalCode,
        phoneNumber: !!phoneNumber ? phoneNumber.replace(/\D/g, "") : undefined,
        email: email,
        persist: true,
      }}
    }
    return {addressId: type}
  }, [mid])

  const getSurchargeRegion = useCallback(async (values: VTForm_Shape) => {
    console.log('[getSurchargeRegion] values', values)
    if (values.paymentMethodId === NewPaymentMethod) {
      if (values.billingAddress!.type === NewAddress) return (values.billingAddress as NewAddress_Shape).postalCode
      else if (values.billingAddress.type === SameAsCustomerAddress) {
        return (values as NewCustomer_Shape).customer.postalCode
      }
      const { address } = await getAddress(mid, values.billingAddress!.type)
      return address.postalCode
    }
    const { card } = await getCard(mid, values.paymentMethodId)
    return card.address.postalCode
  }, [mid])

  if (notAuthorized) return <></>
  return <>
    {contextHolder}
    {showTransactionFeeConfirmation ? <TransactionFeeConfirmationModal paymentData={storedPaymentData as DoIt_Shape} transactionFeeData={storedTransactionFeeData} onConfirm={() => onTransactionFeeConfirmation()} onCancel={() => reset()} /> : <></>}
    <Spin spinning={loading}>
      <Form form={form} component={false} onFinish={(values) => submitPaymentForm(values)}>
        <Row gutter={[16, 16]}>
            {/* Main page */}
            <Col xs={24}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <VTHeader />
                </Col>

                <Col span={24}>
                  <PaymentCard authorization={authorization} />
                </Col>

                <Col span={24}>
                  <CustomerCard />
                </Col>

                <Col span={24}>
                  <PaymentMethodCard submit={submitIFrame} onTokenReceived={onTokenReceived} />
                </Col>

                <Col span={24}>
                  <BillingAddressCard />
                </Col>

                <Col span={24}>
                  <ShippingAddressCard />
                </Col>

                <Col span={24}>
                  <DetailsCard />
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <Button type='primary' block icon={<DollarOutlined />} onClick={_ => form.submit()}>Pay</Button>
            </Col>

        </Row>

        <br />
      </Form>
    </Spin>
  </>
}
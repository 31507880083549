/*
 * InterPayments Inc. ("COMPANY") CONFIDENTIAL
 * Unpublished Copyright © 2024 InterPayments Inc., All Rights Reserved.
 *
 * https://interpayments.com/copyright-policy/
 *
 * NOTICE: All information contained herein is, and remains the property of
 * COMPANY. The intellectual and technical concepts contained herein are
 * proprietary to COMPANY and may be covered by U.S. and Foreign Patents, patents
 * in process, and are protected by trade secret or copyright law. Dissemination
 * of this information or reproduction of this material is strictly forbidden
 * unless prior written permission is obtained from COMPANY. Access to the source
 * code contained herein is hereby forbidden to anyone except current COMPANY
 * employees, managers or contractors who have executed Confidentiality and
 * Non-disclosure agreements explicitly covering such access.
 *
 * The copyright notice above does not evidence any actual or intended publication
 * or disclosure of this source code, which includes information that is
 * confidential and/or proprietary, and is a trade secret, of COMPANY. ANY
 * REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY
 * OF OR THROUGH USE OF THIS SOURCE CODE WITHOUT THE EXPRESS WRITTEN CONSENT
 * OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 * INTERNATIONAL TREATIES. THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR
 * RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE
 * OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 * MAY DESCRIBE, IN WHOLE OR IN PART.
 *
 */

import React from 'react'
import 'rapidoc'
import {Layout} from 'antd'
import { AppHeader } from '../../../components/Layout/AppHeader'
import {AppFooter} from '../../../components/Layout/AppFooter'
import { Table } from 'antd';

const { Content } = Layout

const dataSource = [
  { key: '1', parameter: 'id', description: 'InterPayments assigned unique transaction identifier - also called "sTxId".' },
  { key: '2', parameter: 'mTxId', description: 'Optional merchant assigned unique transaction identifier. Must be unique per transaction.' },
  { key: '3', parameter: 'merchantId', description: 'InterPayments assigned merchant identification number.' },
  { key: '4', parameter: 'merchant', description: 'Merchant name configured in the InterPayments system.' },
  { key: '5', parameter: 'serviceFee', description: 'Interpayments fee for services for this transaction.' },
  { key: '6', parameter: 'transactionFeeMaxed', description: 'Final surcharge value returned, after rules are applied, after limits are applied, after region restrictions are considered. Can include the InterPayments service fee if the merchant is configured to include service fees in the surcharge amount.' },
  { key: '7', parameter: 'transactionFee', description: 'Current same as transactionFeeBase. Will be updated in April to be amount recovered.' },
  { key: '8', parameter: 'realizedRate', description: 'TransactionFeeMaxed / transactionTotal * 100. Transaction fee percentage of the initial submitted total amount of the transaction.' },
  { key: '9', parameter: 'transactionFeeForAvg', description: 'The actual calculated cost of acceptance for this transaction before any limits or business rules are applied. This value is saved so that it can be used to calculate averages for the merchant processor.' },
  { key: '10', parameter: 'transactionFeeBase', description: 'The calculated surcharge amount using the appropriate averaging method before any limits or business rules are applied.' },
  { key: '11', parameter: 'transactionTotal', description: 'The amount value supplied in the transaction fee request indicating the total amount of the transaction before surcharges are applied.' },
  { key: '12', parameter: 'merchantTransactionTotal', description: 'Used for multipay transactions when the merchant also has configured business rules that are based on transaction total. This is an optional parameter that can be supplied in the transaction fee request to indicate that the transaction is only a partial payment that is part of a larger transaction. It allows the merchant to have the business rules evaluated for the larger total transaction and applied to this partial transaction amount. If not supplied then it defaults to the same amount as transactionTotal.' },
  { key: '13', parameter: 'status', description: 'The transaction status in the InterPayments system. The transaction status is initially set to transient/assigned and can later be updated to completed, declined or canceled as needed.' },
  { key: '14', parameter: 'authCode', description: 'The processors authorization code for this transaction. This optional parameter can be supplied during a transaction fee update request or as part of the sale call.' },
  { key: '15', parameter: 'updatedAt', description: 'Date and time of the last change to the transaction in UTC.' },
  { key: '16', parameter: 'createdAt', description: 'Date and time that the transaction was originally created in UTC.' },
  { key: '17', parameter: 'completedAt', description: 'Date and time that the transaction was marked as completed in UTC.' },
  { key: '18', parameter: 'nicn', description: 'The BIN number provided in the transaction fee request. The BIN number (also called the IIN number) is the first 8 digits of the card used for payment. InterPayments also supports the older standard of 6 digit BIN numbers but recommends merchants use 8 digits.' },
  { key: '19', parameter: 'brand', description: 'Card brand used for payment on this transaction. Determined using the supplied BIN number.' },
  { key: '20', parameter: 'kind', description: 'Type of funding associated with the card used for this transaction (credit/ debit/ prepaid). Determined using the supplied BIN number.' },
  { key: '21', parameter: 'region', description: 'The supplied zip code or state code for the point of interaction for this transaction. For card not present, use the customer\'s home address zip code. For card present, use the store address where the transaction takes place.' },
  { key: '22', parameter: 'ruleMessage', description: 'If configured business rules were used to update the surcharge, then a message to indicate the rule that was invoked is listed.' },
  { key: '23', parameter: 'surchargeStatus', description: 'The merchant\'s surcharging status in the InterPayments system (Active, Free Trial, Provisional, Testing, Inactive).' },
  { key: '24', parameter: 'invoiceStatus', description: 'Indicates if the completed transaction has been included in an invoice cycle (Ready, Invoiced, blank).' },
  { key: '25', parameter: 'callData', description: 'Data sent in the transaction fee request using the optional data parameter.' },
  { key: '26', parameter: 'tokenData', description: 'Static text data that is encoded in the merchant token. Can be used to group transactions by channel or device by using separate tokens in the transaction fee calls.' },
  { key: '27', parameter: 'invoiceDate', description: 'If the transaction has been included in an invoicing cycle, then the date that the invoice was generated.' },
  { key: '28', parameter: 'regionNorm', description: 'The state or province that the provided region parameter above resolves to when we look up the zip code or province code supplied in the transaction fee request. Used to determine if surcharging exclusions or special treatment is needed due to configured regions or unique state/province laws.' },
  { key: '29', parameter: 'jurisdiction', description: 'The configured jurisdiction of the merchant in the InterPayments system (USA or CAN).' },
  { key: '30', parameter: 'collectiveId', description: 'The ID of the collective if the merchant is configured to be part of a collective group.' },
  { key: '31', parameter: 'reasonCodes', description: 'Contains a short text description of the reason that a surcharge value was changed, lowered, zeroed, or not calculated.' },
  { key: '32', parameter: 'blank', description: 'test field' },
]


const columns = [
  { title: 'Parameter', dataIndex: 'parameter', key: 'parameter' },
  { title: 'Description', dataIndex: 'description', key: 'description' },
]

const ExportTransactionsHelpPageFC = () => {
  
  return <>
  <Layout
  style={{minHeight:"100vh"}}
  >
    <AppHeader />
    <Content style={{minHeight: 'unset'}}>
      <Content
        className='main-content'
      >
      <div>
        <h1>Export Transactions to CSV Help Page:</h1>
        <p>Here are the column definitions transaction export to csv file:</p>
        <p>
          <Table dataSource={dataSource} columns={columns} pagination={false} />
        </p>
      </div>
      </Content>
    </Content>
    <AppFooter />
  </Layout>
  </>
  }

  export const ExportTransactionsHelpPage = ExportTransactionsHelpPageFC
/*
 * InterPayments Inc. ("COMPANY") CONFIDENTIAL
 * Unpublished Copyright © 2024 InterPayments Inc., All Rights Reserved.
 *
 * https://interpayments.com/copyright-policy/
 *
 * NOTICE: All information contained herein is, and remains the property of
 * COMPANY. The intellectual and technical concepts contained herein are
 * proprietary to COMPANY and may be covered by U.S. and Foreign Patents, patents
 * in process, and are protected by trade secret or copyright law. Dissemination
 * of this information or reproduction of this material is strictly forbidden
 * unless prior written permission is obtained from COMPANY. Access to the source
 * code contained herein is hereby forbidden to anyone except current COMPANY
 * employees, managers or contractors who have executed Confidentiality and
 * Non-disclosure agreements explicitly covering such access.
 *
 * The copyright notice above does not evidence any actual or intended publication
 * or disclosure of this source code, which includes information that is
 * confidential and/or proprietary, and is a trade secret, of COMPANY. ANY
 * REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY
 * OF OR THROUGH USE OF THIS SOURCE CODE WITHOUT THE EXPRESS WRITTEN CONSENT
 * OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 * INTERNATIONAL TREATIES. THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR
 * RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE
 * OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 * MAY DESCRIBE, IN WHOLE OR IN PART.
 *
 */

import dayjs from "dayjs"
import { useEffect, useMemo, useRef, useState } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { Collective, Merchant } from "../models/models"
import { ApplicationState, createGetCollectives, createGetMerchants } from "../reducers"
import { CollectivesFetched } from "../reducers/collective/actions"
import { MerchantsFetched } from "../reducers/merchant/actions"
import { env2 } from "../utils/env2"
import { useAuthorization } from "./useAuthorization"
import { useRequest } from "./useRequest"
import { useResourceGroupContext } from '../providers/ResourceGroup/ResourceGroupContext'

export const hardFetchTimeout: number = (Number.parseInt(env2("REACT_APP_RESOURCE_FETCH_TIMEOUT")) * 1000) || (1000 * 60 * 5) // hard fetching defaults to 5 minute timeout period, env variable timeout set in seconds

// Reducer Functions
export const usePortalResources = () => {
  const dispatch = useDispatch()

  const getCollectives = useMemo(createGetCollectives, [])
  const collectives: Collective[] = useSelector(getCollectives, shallowEqual)

  const getMerchants = useMemo(createGetMerchants, [])
  const merchants: Merchant[] = useSelector(getMerchants, shallowEqual)

  const lastCollectiveFetch = useSelector((s: ApplicationState) => s.collective.lastHardFetch)
  const lastMerchantFetch = useSelector((s: ApplicationState) => s.merchant.lastHardFetch)
  
  const { makeRequest } = useRequest()
  const { isAuthorized, actionsLoaded } = useAuthorization('*', ['admin'])

  const [ collectivesLoaded, setCollectivesLoaded ] = useState<boolean>(false)
  const [ merchantsLoaded, setMerchantsLoaded ] = useState<boolean>(false)

  const resourceGroupContext = useResourceGroupContext()

  useEffect(() => {
    if (!lastCollectiveFetch) fetchCollectives()
  }, [lastCollectiveFetch])

  useEffect(() => {
    if (!lastMerchantFetch && !!actionsLoaded) fetchMerchants(isAuthorized('admin'))
  }, [lastMerchantFetch, actionsLoaded])

  useEffect(() => {
    if (!!lastCollectiveFetch) setCollectivesLoaded(true)
  }, [lastCollectiveFetch])

  useEffect(() => {
    if (!!lastMerchantFetch) setMerchantsLoaded(true)
  }, [lastMerchantFetch])

  const fetchAll = (asAdmin: boolean = false, force: boolean = false) => {
    return Promise.all([fetchCollectives(force), fetchMerchants(asAdmin, force)])
      .then(r => r.reduce((a, o) => a && o, true))
      .catch((e: any) => Promise.reject(e))
  }

  const fetchCollectives = (force: boolean = false) => {
    console.log('[usePortalResources] Fetch collectives triggered')
    const shouldRefresh = force || lastCollectiveFetch === undefined || dayjs().diff(dayjs(lastCollectiveFetch)) >= hardFetchTimeout
    if (shouldRefresh) {
      console.log('[usePortalResources] Fetching new collectives')
      return makeRequest([`/v1/collective`, {}])
        .then(r => {
          dispatch(CollectivesFetched(r.collectives, true))
        })
        .then(_ => true)
        .catch((e: any) => Promise.reject('There was a problem fetching collectives.'))
    } else return Promise.resolve(false)
  }

  const fetchMerchants = (asAdmin: boolean = false, force: boolean = false) => {
    console.log('[usePortalResources] Fetch merchants triggered')
    const shouldRefresh = force || lastMerchantFetch === undefined || dayjs().diff(dayjs(lastMerchantFetch)) >= hardFetchTimeout
    if (shouldRefresh) {
      console.log('[usePortalResources] Fetching new merchants')
      if (asAdmin) {
        return makeRequest([`/v1/merchant`, {}])
          .then(r => {
            const merchants = r.map(m => m.merchant)
            dispatch(MerchantsFetched(merchants, true))
          })
          .then(_ => true)
          .catch((e: any) => Promise.reject('There was a problem fetching merchants.'))
      } else {
        return makeRequest([`/v1/collective`, {}]) // TODO: Right now we are calling /v1/collective to get merchants as a non-admin user.  This seems wonky to me.
          .then(r => {
            dispatch(MerchantsFetched(r.merchants, true))
          })
          .then(_ => true)
          .catch((e: any) => Promise.reject('There was a problem fetching merchants.'))
      }
    } else return Promise.resolve(false)
  }

  return { fetchAll, fetchCollectives, fetchMerchants, collectivesLoaded, merchantsLoaded, collectives, merchants }
}

import { useEffect } from 'react'
import { guid } from '../models/models'

export const AuthError = ({ error }: {error: Error | undefined}) => {
  console.warn('[AuthError] Error passed from Auth0 context', error)

  useEffect(() => {
    window.location.href = `https://${window.location.host}/p?asset=${guid()}`    
  }, [])

  return <></>
}
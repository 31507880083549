import React, {useEffect, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {useService} from "../../providers/service-provider/service-provider";
import {MerchantService} from "../../services/merchant-service";
import {useQueryParams} from "../../hooks/useQueryParams";

/*
   This class might not be used however wanted to keep it in source in case
   we use it for some auto-token generation path to support testing within
   our API documentation.
 */
const AuthFC: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { loginWithRedirect, getIdTokenClaims } = useAuth0()
  const service = useService(MerchantService)

  const { getParamByKey } = useQueryParams()

  const [ url, setUrl ] = useState<string | undefined>()

  useEffect(() => {
    const p = getParamByKey("payload")
    if (p) {
      const p2 = p as string
      console.log("REDIRECT", p2);
      console.log("REDIRECT BTOA", atob(p2));
      const whereTo = JSON.parse(atob(p2));
      (async () => {
        const url2 = await service.apiAuthorize(whereTo.redirectUri)
        setUrl(whereTo.redirectUri)
      })()
    }
    const p2 = getParamByKey("payload2")
    if (p2) {
      const p3 = p2 as string
      console.log("REDIRECT", p3);
      console.log("REDIRECT BTOA", atob(p3));
      const whereTo = JSON.parse(atob(p3));
      (async () => {
        const t = await getIdTokenClaims()
        if (t && t.__raw) {
          const url = `${whereTo.redirectUri}&_a=${t.__raw}`
          console.log("redirecting to complete", url);
          window.location.href = url
        }
      })()
    }
  }, [])

  useEffect(() => {
    if (url) {
      const p = getParamByKey("payload")
      const p2 = p as string
      const redirectUri = `${window.location.origin}/auth?payload2=${p2}`
      console.log("redirect to", url)
      console.log("redirect butFirst", redirectUri)
      loginWithRedirect({
        authorizationParams: {
          screen_hint: "login",
          display: "page",
          redirectUri
        }
      }).then(_ => console.log("in progress"))
    }
  }, [url])

  return (<></>)
}

export const Auth = AuthFC

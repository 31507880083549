import { Button, Card, Checkbox, Form, Input, Select, Space, Switch, Typography } from "antd"
import { useForm } from "antd/es/form/Form"
import { formLayout, Merchant, tailLayout } from "models/models"
import { AuthorizeNetGatewayRequest, CybersourceGatewayRequest, Gateway, GatewayRequest, GatewayType, StripeGatewayRequest } from "models/paylink"
import { useEffect, useMemo, useState } from "react"

const { Text } = Typography

const NameInput = () => <Form.Item label='Name' name='name' rules={[{required: true}]}>
  <Input placeholder='Default Gateway' />
</Form.Item>

const ProcessorInput = ({ merchant }: { merchant: Merchant }) => {
  const options = useMemo(() => {
    return merchant.processor.map(p => ({ key: p.id, value: p.id, label: <Text>{p.name} <Text type='secondary'>({p.id})</Text></Text> }))
  }, [merchant])

  return <Form.Item label='Processor' name='processor' rules={[{required: true}]}>
    <Select options={options} />
  </Form.Item>
}

const CurrencyInput = () => <Form.Item label='Currency' name='currency' rules={[{required: true}]}>
  <Select options={[{ key: 'USD', value: 'USD', label: 'USD' }]} />
</Form.Item>

const ProcessingLevelInput = () => <Form.Item label='Processing Level Type' name='processingLevelType' rules={[{required: true}]}>
  <Select>
    <Select.Option key='Never' value='Never' >Never</Select.Option>
    <Select.Option key='Qualification' value='Qualification' >Qualification</Select.Option>
    <Select.Option key='Always' value='Always' >Always</Select.Option>
  </Select>
</Form.Item>

const SandboxInput = () => <Form.Item label='Sandbox' name='sandbox' valuePropName='checked'>
  <Checkbox />
</Form.Item>

const ButtonControls = ({ onCancel }: { onCancel: () => void }) => <Form.Item {...tailLayout}>
  <Space size='small'>
    <Button htmlType='submit' type='primary'>Submit</Button>
    <Button onClick={() => onCancel()}>Cancel</Button>
  </Space>
</Form.Item>

const CommonInputs = ({ merchant, onCancel }: {merchant: Merchant, onCancel: () => void}) => <>
  <NameInput />
  <ProcessorInput merchant={merchant} />
  <CurrencyInput />
  <ProcessingLevelInput />
  <SandboxInput />
  <ButtonControls onCancel={onCancel} />
</>

const AuthorizeNetGatewayForm = ({ merchant, initialValues, onSubmit, onCancel, updateCredentials }: {merchant: Merchant, initialValues?: Gateway, onSubmit: (request: AuthorizeNetGatewayRequest) => void, onCancel: () => void, updateCredentials: boolean}) => {
  const [ form ] = useForm()

  console.log(updateCredentials)
  
  const handleSubmit = (values: any) => {
    onSubmit({ gateway: {...values, gatewayType: GatewayType.AuthorizeNet} } as AuthorizeNetGatewayRequest)
  }

  return <Form form={form} {...formLayout} onFinish={handleSubmit} initialValues={initialValues}>
    {updateCredentials && <>
      <Card type='inner' title='Credentials'>
        <Form.Item label='Name' name={['credentials', 'name']} rules={[{required: true}]}>
          <Input placeholder='apiKey_0xff0000' />
        </Form.Item>

        <Form.Item label='Transaction Key' name={['credentials', 'transactionKey']} rules={[{required: true}]}>
          <Input placeholder='apiKey_0xff0000' />
        </Form.Item>
      </Card>

      <br />
    </>}

    <CommonInputs merchant={merchant} onCancel={onCancel} />
  </Form>
}

const CybersourceGatewayForm = ({ merchant, initialValues, onSubmit, onCancel, updateCredentials }: {merchant: Merchant, initialValues?: Gateway, onSubmit: (request: CybersourceGatewayRequest) => void, onCancel: () => void, updateCredentials: boolean}) => {
  const [ form ] = useForm()
  
  const handleSubmit = (values: any) => {
    onSubmit({ gateway: {...values, gatewayType: GatewayType.Cybersource} } as CybersourceGatewayRequest)
  }

  return <Form form={form} {...formLayout} onFinish={handleSubmit} initialValues={initialValues}>
    {updateCredentials && <>
      <Card type='inner' title='Credentials'>
        <Form.Item label='Key ID' name={['credentials', 'keyId']} rules={[{required: true}]}>
          <Input placeholder="apiKey_0xff0000" />
        </Form.Item>

        <Form.Item label='Merchant ID' name={['credentials', 'merchantId']} rules={[{required: true}]}>
          <Input placeholder="123456789000000" />
        </Form.Item>

        <Form.Item label='Shared Secret' name={['credentials', 'sharedSecret']} rules={[{required: true}]}>
          <Input placeholder="apiKey_0xff0000" />
        </Form.Item>
      </Card>

      <br />
    </>}

    <CommonInputs merchant={merchant} onCancel={onCancel} />
  </Form>
}

const StripeGatewayForm = ({ merchant, initialValues, onSubmit, onCancel, updateCredentials }: {merchant: Merchant, initialValues?: Gateway, onSubmit: (request: StripeGatewayRequest) => void, onCancel: () => void, updateCredentials: boolean}) => {
  const [ form ] = useForm()
  
  const handleSubmit = (values: any) => {
    onSubmit({ gateway: {...values, gatewayType: GatewayType.Stripe} } as StripeGatewayRequest)
  }

  return <Form form={form} {...formLayout} onFinish={handleSubmit} initialValues={initialValues}>
    {updateCredentials && <>
      <Card type='inner' title='Credentials'>
        <Form.Item label='Login' name={['credentials', 'login']} rules={[{required: true}]}>
          <Input placeholder="apiKey_0xff0000" />
        </Form.Item>
      </Card>

      <br />
    </>}

    <CommonInputs merchant={merchant} onCancel={onCancel} />
  </Form>
}

export const GatewayForm = ({ merchant, initialValues, onSubmit, onCancel, requireCredentials }: {merchant: Merchant, initialValues?: Gateway, onSubmit: (request: GatewayRequest) => void, onCancel: () => void, requireCredentials: boolean}) => {
  const [ gatewayType, setGatewayType ] = useState<GatewayType>(GatewayType.AuthorizeNet)
  const [ updateCredentials, setUpdateCredentials ] = useState<boolean>(requireCredentials)

  useEffect(() => {
    if (!initialValues || !initialValues.gatewayType) return
    setGatewayType(initialValues.gatewayType)
  }, [initialValues])

  return <>
    <Form.Item label='Gateway Type' {...formLayout} required>
      <Select<GatewayType> value={gatewayType} onChange={v => setGatewayType(v)}>
        {Object.values(GatewayType).map(v => <Select.Option key={v} value={v}>{v}</Select.Option>)}
      </Select>
    </Form.Item>

    {!requireCredentials && <Form.Item label='Update Credentials' {...formLayout}>
      <Switch onChange={value => setUpdateCredentials(value)} />
    </Form.Item>}

    {gatewayType === GatewayType.AuthorizeNet && <AuthorizeNetGatewayForm merchant={merchant} initialValues={initialValues} onSubmit={onSubmit} onCancel={onCancel} updateCredentials={updateCredentials} />}
    {gatewayType === GatewayType.Cybersource && <CybersourceGatewayForm merchant={merchant} initialValues={initialValues} onSubmit={onSubmit} onCancel={onCancel} updateCredentials={updateCredentials} />}
    {gatewayType === GatewayType.Stripe && <StripeGatewayForm merchant={merchant} initialValues={initialValues} onSubmit={onSubmit} onCancel={onCancel} updateCredentials={updateCredentials} />}
  </>
}
/*
 * InterPayments Inc. ("COMPANY") CONFIDENTIAL
 * Unpublished Copyright © 2023 InterPayments Inc., All Rights Reserved.
 *
 * https://interpayments.com/copyright-policy/
 *
 * NOTICE: All information contained herein is, and remains the property of
 * COMPANY. The intellectual and technical concepts contained herein are
 * proprietary to COMPANY and may be covered by U.S. and Foreign Patents, patents
 * in process, and are protected by trade secret or copyright law. Dissemination
 * of this information or reproduction of this material is strictly forbidden
 * unless prior written permission is obtained from COMPANY. Access to the source
 * code contained herein is hereby forbidden to anyone except current COMPANY
 * employees, managers or contractors who have executed Confidentiality and
 * Non-disclosure agreements explicitly covering such access.
 *
 * The copyright notice above does not evidence any actual or intended publication
 * or disclosure of this source code, which includes information that is
 * confidential and/or proprietary, and is a trade secret, of COMPANY. ANY
 * REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY
 * OF OR THROUGH USE OF THIS SOURCE CODE WITHOUT THE EXPRESS WRITTEN CONSENT
 * OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 * INTERNATIONAL TREATIES. THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR
 * RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE
 * OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 * MAY DESCRIBE, IN WHOLE OR IN PART.
 *
 */

import React from 'react'
import { Col, Layout, Row } from 'antd'
import { PortalTheme } from '../../models/models'
import { usePortalTheme } from '../../App'
import { getThemeStyle } from './AppHeader'
import dayjs from 'dayjs'

export const AppFooter:React.FC<React.PropsWithChildren<unknown>> = () => {
  const [portalTheme, setPortalTheme] = usePortalTheme()

  const currentYear = dayjs().get('year')

  return (
    <Layout.Footer className='footer-outer' style={{...getThemeStyle(portalTheme)}}>
    <Row style={{marginTop: '-12px'}}>
      <Col span={24}><span className='footer-tag'>&#169;2020-{currentYear} InterPayments.  All rights reserved.</span></Col>
    </Row>
    <Row style={{marginBottom: '-12px'}}>
      <Col span={6}><a href='https://www.interpayments.com/copyright-policy/'>Copyright Policy</a></Col>
      <Col span={6}><a href='https://www.interpayments.com/privacy-policy/'>Privacy Policy</a></Col>
      <Col span={6}><a href='https://www.interpayments.com/terms-of-use/'>Terms of Use</a></Col>
      <Col span={6}><a href='https://www.interpayments.com/license-agreement/'>License Agreement</a></Col>
    </Row>
    </Layout.Footer>
  )
}
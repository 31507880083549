import { Button, Result } from 'antd'
import React, { ReactNode } from 'react'

const ErrorBoundaryView: React.FC<React.PropsWithChildren<unknown>> = () => {
  return <Result
    status="500"
    title="Something went wrong!"
    subTitle="The portal encountered an error and exited to preserve your data.  Click the button below to reload the page you were on."
    extra={<Button type="primary" onClick={() => window.location.reload()}>Refresh</Button>}
  />
}

// There is currently no hook for creating an error boundary (as of React 18), so we are using a class component per the documentation: https://reactjs.org/docs/error-boundaries.html
type ErrorBoundary_Props = { children: ReactNode, fallback?: ReactNode }
type ErrorBoundary_State = {
  hasError: boolean
}
export class ErrorBoundary extends React.Component<ErrorBoundary_Props, ErrorBoundary_State> {
  constructor(props) {
    super(props)
    this.state = {hasError: false}
    
  }
  static getDerivedStateFromError(error) {
    return { hasError: true }
  }
  componentDidCatch(error, errorInfo) {
    console.log('Error boundary caught error', error, errorInfo)
  }
  render() {
    if (this.state.hasError) {
      const fallback = this.props.fallback ?? <ErrorBoundaryView />
      return fallback
    }
    return this.props.children
  }
} 
import { blue } from "@ant-design/colors"
import { BankOutlined, DeleteOutlined, PlusOutlined, ShopOutlined } from "@ant-design/icons"
import { Button, Card, Col, message, Modal, Popconfirm, Result, Row, Space, Spin, Table, Tooltip, Typography } from "antd"
import { ColumnsType } from "antd/es/table"
import { FancyCard } from "components/Card"
import { usePaylinkAPI } from "hooks/paylink/usePaylinkAPI"
import { Merchant } from "models/models"
import { Gateway, GatewayConfig, Location, LocationRequest } from "models/paylink"
import { Key, useCallback, useEffect, useMemo, useState } from "react"
import { LocationForm } from "components/paylink/LocationForm"
import { usePaylinkMerchantContext } from "../PaylinkMerchantContext"
import { Link } from "react-router-dom"

const { Text, Title } = Typography

const NewLocationCard = ({merchant, onRefresh}: {merchant: Merchant, onRefresh: () => Promise<void>}) => {
  const [ loading, setLoading ] = useState<boolean>(false)
  const [ showForm, setShowForm ] = useState<boolean>(false)

  const { createLocation } = usePaylinkAPI()

  const handleSubmit = useCallback((request: LocationRequest) => {
    setLoading(true)
    message.info({content: <Text>Creating a new location</Text>, key: 'createLocation'})
    createLocation(merchant.id!, request)
      .then(onRefresh)
      .then(_ => {
        message.success({content: <Text>Location was created successfully!</Text>, key: 'createLocation'})
        setLoading(false)
        setShowForm(false)
      })
      .catch(() => {
        message.error({content: <Text>Location could not be created</Text>, key: 'createLocation'})
        setLoading(false)
      })
  }, [merchant])

  if (showForm) return <Spin spinning={loading}>
    <Card>
      <LocationForm merchant={merchant} onSubmit={handleSubmit} onCancel={() => setShowForm(false)} />
    </Card>
  </Spin>

  return <FancyCard style={{height: '100%', position: 'relative', overflow: 'hidden'}} icon={<PlusOutlined style={{color: blue[0]}} />} hoverable onClick={() => setShowForm(true)}>
    <Title level={4} style={{color: blue.primary, position: 'absolute', top: '50%', transform: 'translateY(-50%)'}}>New Location</Title>
  </FancyCard>
}

const AssignGatewaysInner = ({ initialValue, onSelect }: {location: Location, initialValue: Key[], onSelect: (gateways: Key[]) => void}) => {
  const { gateways } = usePaylinkMerchantContext()

  const keyedGateways = useMemo(() => (gateways || []).map(g => ({...g, key: g.id})), [initialValue])

  const [ selectedGateways, setSelectedGateways ] = useState<Key[]>(initialValue)

  const columns = useMemo(() => [
    {
      title: 'Gateway',
      render: (_, gateway) => <Text strong>{gateway.name}</Text>
    },
  ], [])

  const onSelectionChange = useCallback((keys: Key[]) => {
    setSelectedGateways(keys)
    onSelect(keys)
  }, [setSelectedGateways])

  return <>
    <Table
      dataSource={keyedGateways}
      columns={columns}
      rowSelection={{
        onChange: onSelectionChange,
        selectedRowKeys: selectedGateways
      }}
      pagination={false}
    />
  </>
}

const LocationsTableActions = ({ location, gateways, onUpdateGateways, onDelete }: {location: Location, gateways: Key[], onUpdateGateways: (gateways: Key[]) => void, onDelete: () => void}) => {
  const [ open, setOpen ] = useState<boolean>(false)
  const [ targetGateways, setTargetGateways ] = useState<Key[]>(gateways)

  return <>
    <Modal open={open} onClose={() => setOpen(false)} onOk={() => onUpdateGateways(targetGateways)} onCancel={() => setOpen(false)}>
      <AssignGatewaysInner location={location} initialValue={gateways} onSelect={g => setTargetGateways(g)} />
    </Modal>
    <div style={{textAlign: 'right'}}>
      <Space size='small'>
        <Tooltip title='Assign Gateways'>
          <Button type='primary' onClick={() => setOpen(true)} ghost icon={<BankOutlined />} />
        </Tooltip>
        <Popconfirm onConfirm={() => onDelete()} title={<Text>Are you sure you want to delete location <Text strong>{location.contact.name}</Text>?</Text>}>
          <Tooltip title='Delete'>
            <Button danger icon={<DeleteOutlined />} />
          </Tooltip>
        </Popconfirm>
      </Space>
    </div>
  </>
}

const GatewayLink = ({ gatewayId }: {gatewayId: string}) => {
  const { gateways } = usePaylinkMerchantContext()
  if (!gateways) return <></>
  const gateway = gateways.find(g => g.id === gatewayId)
  if (!gateway) return <></>
  return <Text>{gateway.name}</Text>
}

const LocationsTable = ({ locations, gatewayConfigs, loading, onUpdateGateways, onDelete }: {merchant: Merchant, locations: Location[], gateways: Gateway[], gatewayConfigs: GatewayConfig[], loading: boolean, onRefresh: () => void, onUpdateGateways: (location: Location, gateways: Key[]) => void, onDelete: (location: Location) => void}) => {  
  const gatewaysPerLocation = useMemo(() => {
    return gatewayConfigs.reduce((map, gc) => {
      gc.locationIds.forEach(lid => {
        const existing = map[lid]
        if (!existing) map[lid] = [gc.gatewayId]
        else map[lid].push(gc.gatewayId)
      })
      return map
    }, {})
  }, [locations, gatewayConfigs])
  
  const columns: ColumnsType<Location> = [
    {
      title: 'Name',
      key: 'name',
      render: (_, location) => <Text>{location.contact.name}</Text>
    },
    {
      title: 'Email',
      key: 'email',
      render: (_, location) => <Text>{location.contact.email || ''}</Text>
    },
    {
      title: 'Gateways',
      key: 'gateways',
      render: (_, location) => {
        const gateways = gatewaysPerLocation[location.id] || []
        const noGateways = !gateways.length
        return <>
          {noGateways && <Text type='secondary'>No gateways assigned</Text>}
          {!noGateways && gateways.map((g, i) => <>
            {(i > 0) && <span>,&nbsp;</span>}
            <GatewayLink key={g} gatewayId={g} />
          </>)}
        </>
      }
    },
    {
      key: 'actions',
      render: (_, location) => {
        return <>
          <LocationsTableActions
            location={location}
            gateways={gatewaysPerLocation[location.id] || []}
            onUpdateGateways={(gateways: Key[]) => onUpdateGateways(location, gateways)}
            onDelete={() => onDelete(location)}
          />
        </>
      }
    }
  ]

  return <>
    <Spin spinning={loading}>
      <Table
        dataSource={locations}
        columns={columns}
      />
    </Spin>
  </>
}

export const LocationsInner = () => {
  const { merchant, gateways, locations, gatewayConfigs, reloadLocations } = usePaylinkMerchantContext()
  const { createGatewayConfig, updateGatewayConfig, deleteLocation } = usePaylinkAPI()

  const mid = merchant!.id!

  const loading = useMemo(() => [gateways, locations, gatewayConfigs].some(v => v === undefined), [gateways, locations, gatewayConfigs])

  const keyedGateways = useMemo(() => (gateways || []).map(g => ({...g, key: g.id})), [gateways])
  const keyedLocations = useMemo(() => (locations || []).map(l => ({...l, key: l.id})), [locations])
  const keyedGatewayConfigs = useMemo(() => (gatewayConfigs || []).map(gc => ({...gc, key: gc.gatewayId})), [gatewayConfigs])

  const refresh = useCallback(() => {
    return reloadLocations(mid)
  }, [mid])

  const handleUpdateGateways = useCallback((location: Location, gateways: Key[]) => {
    message.info({content: <Text>Updating gateway configuration for location <Text strong>{location.contact.name}</Text></Text>, key: 'updateGatewayConfig'})
    const existingConfigs = gatewayConfigs || []

    const relevantGateways = new Set<string>()
    existingConfigs.filter(c => c.locationIds.includes(location.id)).forEach(gc => relevantGateways.add(gc.gatewayId))
    gateways.forEach(g => relevantGateways.add(g.toString()))

    const updatePromises = Array.from(relevantGateways).map(gid => {
      const existingConfig = existingConfigs.find(c => c.gatewayId === gid)
      if (!existingConfig) return createGatewayConfig(mid, {gatewayId: gid, locationIds: [location.id]})
      const existingConfigHasLocation = existingConfig.locationIds.includes(location.id)
      if (!existingConfigHasLocation) return updateGatewayConfig(mid, existingConfig.gatewayId, {gatewayId: gid.toString(), locationIds: [...existingConfig.locationIds, location.id]})
      if (existingConfigHasLocation && !gateways.includes(gid)) {
        const newLocations = existingConfig.locationIds.filter(l => l !== location.id)
        return updateGatewayConfig(mid, existingConfig.gatewayId, {gatewayId: gid, locationIds: newLocations})
      }
      return Promise.resolve()
    })
    return Promise.all(updatePromises)
      .then(refresh)
      .then(_ => {
        message.success({content: <Text>Gateway configuration for location <Text strong>{location.contact.name}</Text> updated successfully!</Text>, key: 'updateGatewayConfig'})
      })
      .catch(() => {
        message.error({content: <Text>Gateway configuration for location <Text strong>{location.contact.name}</Text> could not be updated</Text>, key: 'updateGatewayConfig'})
        console.error(`[LocationsInner] handleUpdateGateways was unsuccessful`)
      })
  }, [gatewayConfigs])

  const handleDelete = useCallback((location: Location) => {
    message.info({content: <Text>Deleting location <Text strong>{location.contact.name}</Text></Text>, key: 'deleteLocation'})
    return deleteLocation(mid, location.id)
      .then(refresh)
      .then(_ => {
        message.success({content: <Text>Location <Text strong>{location.contact.name}</Text> deleted successfully!</Text>, key: 'deleteLocation'})
      })
      .catch(() => {
        message.error({content: <Text>Location <Text strong>{location.contact.name}</Text> could not be deleted</Text>, key: 'deleteLocation'})
        console.error(`[LocationsInner] handleDelete was unsuccessful`)
      })
  }, [deleteLocation, refresh])

  return <>
    <Row gutter={[32, 32]}>
      <Col span={24}>
        <Result
          title={<Title level={2}>Locations</Title>}
          icon={<ShopOutlined />}
        />
      </Col>

      <Col span={24}>
        <NewLocationCard merchant={merchant!} onRefresh={refresh} />
      </Col>

      <Col span={24}>
        <LocationsTable merchant={merchant!} gateways={keyedGateways} locations={keyedLocations} gatewayConfigs={keyedGatewayConfigs} loading={loading} onRefresh={refresh} onUpdateGateways={handleUpdateGateways} onDelete={handleDelete} />
      </Col>
    </Row>
  </>
}

import { intersection, TxLogResponse } from './models'

type BsonDate = {"$date": { "$numberLong": number }}

type FeeDataBaseFacets = {
  total: number,

  // By type
  credit: number,
  debit: number,
  mixed: number,
  prepaid: number,

  // By fee
  feeSome: number,
  feeNone: number,
}

type FeeDataBaseCountFacets = Omit<FeeDataBaseFacets, "total"> & {count: number}
type FeeDataBrandCountFacets = FeeDataBaseCountFacets & {productLevel: FeeDataProductLevel}

type FeeDataRootFacets = FeeDataBaseFacets & {
  amex: FeeDataBrandFacets,
  mastercard: FeeDataBrandFacets,
  discover: FeeDataBrandFacets,
  visa: FeeDataBrandFacets,
  refund: number
}

type FeeDataRootCountFacets = FeeDataBaseCountFacets & {
  amex: FeeDataBrandCountFacets,
  mastercard: FeeDataBrandCountFacets,
  discover: FeeDataBrandCountFacets,
  visa: FeeDataBrandCountFacets,
  refund: number
}

type FeeDataProductLevel = {
  consumer: {
    consumer1: number,
    consumer2: number,
    consumer3: number,
    consumer4: number,
  },
  business: {
    business1: number,
    business2: number,
    business3: number,
    business4: number,
    business5: number,
  },
  corporate: {
    corporate1: number,
    corporate2: number,
    corporate3: number
  }
}
type FeeDataBrandFacets = FeeDataBaseFacets & {
  productLevel: FeeDataProductLevel
}

const feeDataBaseFacetKeys: (keyof Required<FeeDataBaseFacets>)[] = ['total', 'credit', 'debit', 'mixed', 'prepaid', 'feeSome', 'feeNone']
const emptyFeeDataBaseFacets: FeeDataBaseFacets = feeDataBaseFacetKeys.reduce((a, o) => ({...a, [o]: 0}), {}) as FeeDataBaseFacets
const feeDataBaseCountFacetKeys: (keyof Required<FeeDataBaseCountFacets>)[] = ['count', 'credit', 'debit', 'mixed', 'prepaid', 'feeSome', 'feeNone']
const emptyFeeDataBaseCountFacets: FeeDataBaseCountFacets = feeDataBaseCountFacetKeys.reduce((a, o) => ({...a, [o]: 0}), {}) as FeeDataBaseCountFacets
const emptyFeeDataProductLevel: FeeDataProductLevel = {
  consumer: {
    consumer1: 0,
    consumer2: 0,
    consumer3: 0,
    consumer4: 0,
  },
  business: {
    business1: 0,
    business2: 0,
    business3: 0,
    business4: 0,
    business5: 0,
  },
  corporate: {
    corporate1: 0,
    corporate2: 0,
    corporate3: 0
  }
}
const emptyFeeDataBrandFacets: FeeDataBrandFacets = {...emptyFeeDataBaseFacets, productLevel: emptyFeeDataProductLevel}
const emptyFeeDataBrandCountFacets: FeeDataBrandCountFacets = {...emptyFeeDataBaseCountFacets, productLevel: emptyFeeDataProductLevel}
const emptyFeeDataRootFacets: FeeDataRootFacets = {...emptyFeeDataBaseFacets, amex: emptyFeeDataBrandFacets, mastercard: emptyFeeDataBrandFacets, discover: emptyFeeDataBrandFacets, visa: emptyFeeDataBrandFacets, refund: 0}
const emptyFeeDataRootCountFacets: FeeDataRootCountFacets = {...emptyFeeDataBaseCountFacets, amex: emptyFeeDataBrandCountFacets, mastercard: emptyFeeDataBrandCountFacets, discover: emptyFeeDataBrandCountFacets, visa: emptyFeeDataBrandCountFacets, refund: 0}

export type FeeData = {
  counts: FeeDataRootCountFacets,
  sums: {
    transactionAmount: number,
    transactionFeeMaxed: number,
    transactionFee: number,
    transactionFeeForAvg: number,
    serviceFee: number,
    collectiveServiceFee: number,
    //ruleAdjusted: {},
    amountDetails: FeeDataRootFacets,
    transactionFeeDetails: FeeDataRootFacets,
    transactionFeeForAvgDetails: FeeDataRootFacets,
    serviceFeeDetails: FeeDataRootFacets,
  }
}
export const emptyFeeData: FeeData = {
  counts: emptyFeeDataRootCountFacets,
  sums: {
    transactionAmount: 0,
    transactionFeeMaxed: 0,
    transactionFee: 0,
    transactionFeeForAvg: 0,
    serviceFee: 0,
    collectiveServiceFee: 0,
    //ruleAdjusted: {},
    amountDetails: emptyFeeDataRootFacets,
    transactionFeeDetails: emptyFeeDataRootFacets,
    transactionFeeForAvgDetails: emptyFeeDataRootFacets,
    serviceFeeDetails: emptyFeeDataRootFacets,
  }
}

export type FeeDataRefs = {
  processorDefinitionId: string,
  processorName: string,
  merchantId: string,
}

export type FeeDataRecord = FeeData & {
  businessDay: BsonDate,
  //id: string,
  //merchantId: string,
  //isoCreatedAt: BsonDate,
  //refs: FeeDataRefs
}

export const onlyAccumulableData = (r: FeeDataRecord): FeeData => {
  const {businessDay, ...feeData} = r
  return feeData
}

export const accumulate = <T extends unknown>(a: T, b: T) => {
  if (a === null || b === null) return undefined
  if (typeof a !== typeof b) return undefined
  else if (typeof a === 'number' && typeof b === 'number') return a + b
  else if (typeof a === 'object' && typeof b === 'object') return Array.from(intersection(new Set(Object.keys(a as object)), new Set(Object.keys(b as object))) as Set<string>).reduce((obj, key) => ({...obj, [key]: accumulate(a[key], b[key])}), {}) as object
  else return undefined
}

export const txLogsToFeeData = (logs: TxLogResponse[]) => {
  const rollup = logs
    .reduce((rollup, transaction) => {
      const { brand, kind } = transaction.businessData
      const isCredit = kind === 'CREDIT'
      const isDebit = kind === 'DEBIT'
      const withFee = transaction.transactionFeeMaxed !== 0
      const withoutFee = !withFee
      const isVisa = brand === 'VISA'
      const isMastercard = brand === 'MASTERCARD'
      const isDiscover = brand === 'DISCOVER'
      const isAmex = brand === 'AMEX'
      const transactionTotal = (transaction.transactionTotal || 0)
      const transactionFeeMaxed = (transaction.transactionFeeMaxed || 0)
      const transactionFee = (transaction.transactionFee || 0)
      return {
        ...rollup,
        counts: {
          ...rollup.counts,
          count: rollup.counts.count + 1,
          ...(isCredit ? {credit: rollup.counts.credit + 1} : {}),
          ...(isDebit ? {debit: rollup.counts.debit + 1} : {}),
          ...(withFee ? {feeSome: rollup.counts.feeSome + 1} : {}),
          ...(withoutFee ? {feeNone: rollup.counts.feeNone + 1} : {}),
          ...(isVisa ? {brandVisa: rollup.counts.visa.count + 1} : {}),
          ...(isMastercard ? {brandMastercard: rollup.counts.mastercard.count + 1} : {}),
          ...(isDiscover ? {brandDiscover: rollup.counts.discover.count + 1} : {}),
          ...(isAmex ? {brandAmex: rollup.counts.amex.count + 1} : {})
        },
        sums: {
          ...rollup.sums,
          transactionAmount: rollup.sums.transactionAmount + transactionTotal,
          transactionFeeMaxed: rollup.sums.transactionFeeMaxed + transactionFeeMaxed,
          transactionFee: rollup.sums.transactionFee + transactionFee,
          transactionFeeForAvg: rollup.sums.transactionFeeForAvg + ((transaction as any).transactionFeeForAvg || 0),
          serviceFee: rollup.sums.serviceFee + (transaction.serviceFee || 0),
          amountDetails: {
            ...rollup.sums.amountDetails,
            ...(isCredit ? {credit: rollup.sums.amountDetails.credit + transactionTotal} : {}),
            ...(isDebit ? {debit: rollup.sums.amountDetails.debit + transactionTotal} : {}),
            ...(withFee ? {feeSome: rollup.sums.amountDetails.feeSome + transactionTotal} : {}),
            ...(withoutFee ? {feeNone: rollup.sums.amountDetails.feeNone + transactionTotal}: {}),
            ...(isVisa ? {brandVisa: rollup.sums.amountDetails.visa.total + transactionTotal}: {}),
            ...(isMastercard ? {brandMastercard: rollup.sums.amountDetails.mastercard.total + transactionTotal} : {}),
            ...(isDiscover ? {brandDiscover: rollup.sums.amountDetails.discover.total + transactionTotal}: {}),
            ...(isAmex ? {brandAmex: rollup.sums.amountDetails.amex.total + transactionTotal}: {}),
          }
        }
      }
    }, emptyFeeData)
  return [ rollup ]
}

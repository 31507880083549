import React, {useEffect} from "react";
import {RedirectLoginOptions, useAuth0} from "@auth0/auth0-react";
import {Button, Card, Col, Descriptions, Result, Row, Space, Typography} from "antd";
import {env2} from "../../utils/env2";
import {Base64} from "js-base64";
import Loading from "../../components/Loading/Loading";
import { usePortalTheme } from "App"
import { PortalTheme } from "models/models"

type InviteNotification_Props = {
  inviteEmail: string,
  signupToken: string,
  autoSignup: boolean,
  returnTo?: string
}

const SsoService: boolean = env2("REACT_APP_SSOSERVICE", "false") === "true"
const ForceEmailMatching: boolean = env2("REACT_APP_FORCE_EMAIL_MATCHING", "true") === "true"

const DefaultConnection: (string | undefined) = env2("REACT_APP_DEFAULT_CONNECTION", undefined)
const DefaultPrompt: (string | undefined) = env2("REACT_APP_DEFAULT_PROMPT", undefined)

const isDev = window.location.hostname.indexOf("localhost") > -1
const isTest = window.location.hostname.indexOf("-test") > -1 || isDev
const SignupHostname = env2("REACT_APP_SIGNUPHOSTNAME", isTest ? "signup-test.interpayments.com" : "signup.interpayments.com")

export const InviteNotification: React.FC<React.PropsWithChildren<InviteNotification_Props>> = ({
                                                                         inviteEmail,
                                                                         signupToken,
                                                                         autoSignup = false,
                                                                         returnTo}) => {

  const { loginWithRedirect } = useAuth0()

  const [ portalTheme ] = usePortalTheme()
  const SignupService: boolean = portalTheme === PortalTheme.SIGNUP

  // https://interpayments.atlassian.net/browse/PRO-2157
  // something is required here for SSO and only enterprise login
  const optionalConnection = DefaultConnection ? { connection: DefaultConnection } : {}
  const prompt = { prompt: DefaultPrompt || undefined }

  const loginOptions: (RedirectLoginOptions | undefined) = {
    authorizationParams: {
      screen_hint: "login",
      display: "page",
      ipContext: "invite-login",
      ...optionalConnection,
      ...prompt as { prompt: 'none' | 'login' | 'consent' | 'select_account' | undefined }
    },
    appState: {
      targetUrl: window.location.pathname
    },
  }

  // goto have to correct application that originally sent us to the signup application
  const getAbsoluteAcceptUrl = () => {
    const here = window.location
    const rv = here.href.replace(/\/u\/s$/, '/u')
    if (returnTo) {
      return Base64.decode(returnTo)
    } else {
      return rv
    }
  }

  //
  // if we are in the portal "app" (e.g., binserv, reseller-portal, etc...) and we have some secret
  // identity, then we'll be need to redirect back to this application instance verses a route change
  //
  const signupReturnUrl = (returnTo) ? getAbsoluteAcceptUrl() : window.location.pathname

  const signUpOptions: RedirectLoginOptions = {
    authorizationParams: {
      screen_hint: "signup",
      display: "page",
      signupToken: signupToken,
      ipContext: "invite-signup",
      login_hint: inviteEmail,
      ...optionalConnection,
      ...prompt as { prompt: 'none' | 'login' | 'consent' | 'select_account' | undefined }  
    },
    appState: {
      targetUrl: signupReturnUrl
    },
  }

  useEffect(() => {
    if (SignupService && autoSignup) {
      (async () => {
        console.log("redirecting to autoSignup");
        await loginWithRedirect(signUpOptions)
      })()
    }
  }, [SignupService, autoSignup])

  return (
    (<Card>
      {autoSignup && <Loading />}
      {!autoSignup && <>
        <Result
            title={SsoService ? 'Log in to accept this invitation.' : 'Log in or sign up to accept this invitation.'}
            subTitle={ForceEmailMatching ?
                'Please note the email on the invitation and the email associated with your account must match in order to accept the invitation.' :
                'Please note the email on the invitation and the email associated with your account should represent the same person or identity.'}
            extra={<>
              <Row gutter={[32, 16]}>
                <Col xs={{span: 24, offset: 0}} lg={{span: 18, offset: 3}} xl={{span: 12, offset: 6}}>
                  <Descriptions bordered column={1}>
                    <Descriptions.Item label='Invited Email'>{inviteEmail}</Descriptions.Item>
                  </Descriptions>
                </Col>
              </Row>
              <Row gutter={[32, 16]}>
                <Col xs={{span: 24, offset: 0}} lg={{span: 18, offset: 3}} xl={{span: 12, offset: 6}}>
                  {SsoService && <Typography.Paragraph></Typography.Paragraph>}
                  {!SsoService && <Typography.Paragraph>
                    If you have already created an account, you may log into it using the log in button below - otherwise, you may create a
                    new account using the sign up button adjacent to it.  Please note that you will not be able to complete the invitation
                    process if the email associated with your account does not match the email on the invitation.
                  </Typography.Paragraph>}
                  <Space>
                    <Button type="primary" onClick={async () => await loginWithRedirect({
                      ...loginOptions,
                      appState: {
                        ...loginOptions?.appState,
                        targetUrl: window.location.href.replace(/\/u$/, '/a')
                      }
                    })}>
                      Log In
                    </Button>
                    {!SsoService && !SignupService && <Button onClick={() => {
                      // redirect to the signup service auto login...
                      let there = window.location.href
                      const base64return = Base64.encode(window.location.href.replace(/\/u$/, '/a'))
                      there = there.replace(/\/u$/, `/u/${base64return}/s`)
                      there = there.replace(window.location.hostname, SignupHostname)
                      console.log("redirecting to complete", there);
                      if (!isDev) {
                        window.location.href = there
                      }
                    }}>
                      Sign Up
                    </Button>}
                  </Space>
                </Col>
              </Row>
            </>}
        />
      </>}
      {autoSignup && <>
        <h1>remove this later... should auto-redirect</h1>
      </>}
    </Card>)
  );
}


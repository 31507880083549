/*
 * InterPayments Inc. ("COMPANY") CONFIDENTIAL
 * Unpublished Copyright © 2023 InterPayments Inc., All Rights Reserved.
 *
 * https://interpayments.com/copyright-policy/
 *
 * NOTICE: All information contained herein is, and remains the property of
 * COMPANY. The intellectual and technical concepts contained herein are
 * proprietary to COMPANY and may be covered by U.S. and Foreign Patents, patents
 * in process, and are protected by trade secret or copyright law. Dissemination
 * of this information or reproduction of this material is strictly forbidden
 * unless prior written permission is obtained from COMPANY. Access to the source
 * code contained herein is hereby forbidden to anyone except current COMPANY
 * employees, managers or contractors who have executed Confidentiality and
 * Non-disclosure agreements explicitly covering such access.
 *
 * The copyright notice above does not evidence any actual or intended publication
 * or disclosure of this source code, which includes information that is
 * confidential and/or proprietary, and is a trade secret, of COMPANY. ANY
 * REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY
 * OF OR THROUGH USE OF THIS SOURCE CODE WITHOUT THE EXPRESS WRITTEN CONSENT
 * OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 * INTERNATIONAL TREATIES. THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR
 * RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE
 * OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 * MAY DESCRIBE, IN WHOLE OR IN PART.
 *
 */

import React, {useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {RedirectLoginOptions, useAuth0} from "@auth0/auth0-react"

import { PageHeader } from '@ant-design/pro-layout';

import {
  Button,
  Card,
  Col,
  Descriptions,
  Layout,
  Result,
  Row,
  Space,
  Spin,
  Typography,
} from 'antd';
import {Base64} from 'js-base64'

import { AppHeader } from '../../components/Layout/AppHeader'
import { AppFooter } from '../../components/Layout/AppFooter'
import {useMerchantAPI} from "../../hooks/useMerchantAPI";
import {useCollectiveAPI} from "../../hooks/useCollectiveAPI";
import {env2} from "../../utils/env2";
import {InviteNotification} from "./InviteNotification";

const DefaultConnection: (string | undefined) = env2("REACT_APP_DEFAULT_CONNECTION", undefined)
const DefaultPrompt: (string | undefined) = env2("REACT_APP_DEFAULT_PROMPT", undefined)

const { Content } = Layout

interface AcceptParams {
  cid?: string,
  mid?: string,
  token: string,
  email: string,
  returnTo?: string
}

enum InvitationAccept_ComponentState {
  LOADING,
  VALIDATING,
  NO_USER,
  USER_MISMATCH,
}

const InvitationSignupFC: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { LOADING, VALIDATING, NO_USER, USER_MISMATCH } = InvitationAccept_ComponentState

  const { user } = useAuth0()
  const history = useHistory()

  const { checkInvitation: merchantInvitationCheck } = useMerchantAPI()
  const { checkInvitation: collectiveInvitationCheck } = useCollectiveAPI()

  const { cid, mid, email, token, returnTo } = useParams<AcceptParams>()
  const decodedEmail = Base64.decode(email)

  const [ componentState, setComponentState ] = useState<InvitationAccept_ComponentState>(LOADING)

  useEffect(() => {
    console.log("username matching", user)
    if (!!user) {
      const { email } = user
      if (email!.toLowerCase() === decodedEmail.toLowerCase()) {
        console.log("username match, redirect ")
        const r = window.location.pathname.replace(/\/u$/, '/a')
        history.replace(r)
      } // if we are logged in as the right user, forward to rsvp page
      else setComponentState(USER_MISMATCH) // if we are logged in as the wrong user, show logout dialogue
    } else setComponentState(VALIDATING) // if we are not logged in, show login dialogue
  }, [user])

  useEffect(() => {
    if (!!mid || !!cid) {
      if (componentState === VALIDATING) {
        const inviteCheck: Promise<boolean> = !!mid ? merchantInvitationCheck(mid!, token) : collectiveInvitationCheck(cid!, token)
        inviteCheck
          .then(r => setComponentState(NO_USER))
          .catch((e: any) => console.log('error validation invitation'))
      } else if (componentState === NO_USER) {
        console.log("NO_USER")
      }
    }
  }, [componentState])

  return (<>
      <Layout
        style={{minHeight: '100vh'}}
      >
        <AppHeader />
        <Content style={{padding: '0 56px'}}>
          {!!mid && <PageHeader title="InterPayments Invitation RSVP to Merchant" />}
          {!!cid && <PageHeader title="InterPayments Invitation RSVP to Collective" />}
          {(componentState === LOADING || componentState === VALIDATING) && <Spin tip="Loading..."/>}
          {componentState === NO_USER && <InviteNotification signupToken={token} inviteEmail={decodedEmail} autoSignup={true} returnTo={returnTo} />}
        </Content>
        <AppFooter />
      </Layout>
    </>
  )
}

export const InvitationSignup = InvitationSignupFC

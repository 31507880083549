import { usePaylinkAPI } from "hooks/paylink/usePaylinkAPI"
import { CardMethod, IFrameLayout, SessionRequest } from "models/paylink"
import { useCallback, useEffect, useRef, useState } from "react"

type IFrameEventType_Error = 'error'
type IFrameEventType_Load = 'load'
type IFrameEventType_PaymentMethod = 'paymentMethod'
type IFrameEventType_Tokenizing = 'tokenizing'
type IFrameEventType_Validation = 'validation'
type IFrameEventType = IFrameEventType_Error | IFrameEventType_Load | IFrameEventType_PaymentMethod | IFrameEventType_Tokenizing | IFrameEventType_Validation

enum IFrame_Control {
  ACCOUNT_NUMBER = "accountNumber",
  CARD_CODE = "cardCode",
  CARD_NUMBER = "cardNumber",
  EXPIRATION_DATE = "expirationDate",
  POSTAL_CODE = "postalCode",
  ROUTING_NUMBER = "routingNumber"
}
enum IFrame_ValidationResult {
  INVALID = "invalid",
  REQUIRED = "required"
}

enum IFrame_ErrorCode {
  INTERNAL_CLIENT_ERROR = 100,
  SESSION_NOT_FOUND = 101,
  NONCE_REQUEST_CLIENT_ERROR = 200,
  NONCE_REQUEST_SESSION_NOT_FOUND = 201,
}
type IFrame_ErrorResult = {
  code: IFrame_ErrorCode,
  message: string,
}

type IFrameEventParameters<T> =
  T extends IFrameEventType_Error ? IFrame_ErrorResult :
  T extends IFrameEventType_Load ? Record<string, never> :
  T extends IFrameEventType_PaymentMethod ? {card?: {cardBrand: string, cardNumber: string, expirationMonth: number, expirationYear: number}, expirationMoment: string, nonce: string} :
  T extends IFrameEventType_Tokenizing ? Record<string, never> :
  T extends IFrameEventType_Validation ? {control: IFrame_Control, type?: IFrame_ValidationResult, valid?: boolean} :
  never

declare const paylink: {
  initialize: (sessionId: string) => void,
  tokenize: () => void,
  on: <T extends IFrameEventType>(type: T, callback: (params: IFrameEventParameters<T>) => void) => void
}

type SessionConfigProps = {
  gatewayId: string,
  integrated: boolean,
  container: string,
}

const getSessionConfig = (props: SessionConfigProps): SessionRequest => {
  return {
    session: {
      gatewayId: props.gatewayId,
      dropIn: {
        appearance: {
          styles: {
            "--body-background-color": "transparent",
            "--tabs-content-background-color": "transparent",
          }
        },
        card: {},
        container: props.container,
        ...(props.integrated ? {
          button: {
            text: 'Pay'
          }
        } : {}),
        layout: {
          collapsed: false,
          layoutType: IFrameLayout.Tabs,
          radios: false,
          spaced: false,
        }
      },
    }  
  }
}

export const PaylinkIFrame = ({ merchantId, gatewayId, submit, onTokenReceived }: {merchantId: string, gatewayId: string, submit: boolean, onTokenReceived: (data: any) => void}) => {
  const { createSession } = usePaylinkAPI()

  const [ session, setSession ] = useState<{id: string, expirationMoment: string} | undefined>(undefined)

  const handlePaymentMethodReceived = useCallback((data: any) => {
    onTokenReceived(data)
  }, [])

  useEffect(() => {
    createSession(merchantId, getSessionConfig({ gatewayId, integrated: false, container: '#vt-container' }))
      .then(response => setSession(response.session))
  }, [getSessionConfig, createSession, gatewayId])

  useEffect(() => {
    if (!session) return
    paylink.initialize(session.id)
  }, [session])

  useEffect(() => {
    if (submit) paylink.tokenize()
  }, [submit])

  /*
  const handleMessage = useCallback((event: MessageEvent) => {
    if (event.origin !== getOrigin()) return
    const message = event.data
    const { data } = message

    if (message.type === 4) handlePaymentMethodReceived(data)
    else console.log('iFrame message', data, message.type)
  }, [handlePaymentMethodReceived])
  */

  useEffect(() => {
    paylink.on('error', (params) => console.error(params))
    paylink.on('paymentMethod', (params) => handlePaymentMethodReceived(params))
  }, [])

  return <>
    <div id='vt-container' />
  </>  
}
import { Button, Form, Input, Select, Space } from "antd"
import { useForm, useWatch } from "antd/es/form/Form"
import { PhoneInput } from "components/PhoneInput"
import { request } from "http"
import { formLayout, isRequiredRule, tailLayout } from "models/models"
import { CustomerRequest, CustomerType } from "models/paylink"
import { useCallback, useEffect } from "react"

const isRequired = (message?: string) => !!message ? {required: true, message} : {required: true}

type CustomerForm_Shape = CustomerRequest & {
  customerType: CustomerType
}

export const CustomerForm = ({ onSubmit, onCancel, initialValues }: {onSubmit: (request: CustomerRequest) => void, onCancel: () => void, initialValues?: CustomerRequest}) => {
  const [ form ] = useForm<CustomerForm_Shape>()

  const customerType = useWatch(['customerType'], form)

  useEffect(() => {
    if (!initialValues) return
    const calculatedCustomerType = !!initialValues.firstName ? CustomerType.INDIVIDUAL : CustomerType.COMPANY
    form.setFieldsValue({...initialValues, customerType: calculatedCustomerType})
  }, [initialValues])

  const handleSubmit = useCallback((values: CustomerForm_Shape) => {
    const { customerType,  ...request } = values
    const formattedRequest = Object.entries(request).reduce((a, [k, v]) => {
      if (v === null || v === undefined || v === "") return a
      return {...a, [k]: v}
    }, {}) as CustomerRequest
    return onSubmit({...formattedRequest, phoneNumber: values.phoneNumber.replace(/\D/g, "")})
  }, [onSubmit])

  return <Form form={form} {...formLayout} onFinish={handleSubmit}>
    <Form.Item label='Customer Type' name={'customerType'} rules={[isRequiredRule('Please select a customer type')]}>
      <Select options={Object.values(CustomerType).map(t => ({key: t, value: t, label: t}))} />
    </Form.Item>

    {customerType === CustomerType.INDIVIDUAL && <>
      <Form.Item label='First Name' name='firstName' rules={[isRequired('Please enter a first name')]}>
        <Input placeholder="John" />
      </Form.Item>

      <Form.Item label='Last Name' name='lastName' rules={[isRequired('Please enter a last name')]}>
        <Input placeholder="Doe" />
      </Form.Item>
    </>}

    <Form.Item label='Company Name' name='companyName' rules={customerType === CustomerType.COMPANY ? [isRequired('Please enter a company name')] : []}>
      <Input  />
    </Form.Item>

    <Form.Item label='Email' name='email' rules={[isRequired('Please enter an email address'), {type: 'email', message: 'Please enter a valid email address'}]}>
      <Input placeholder="john@doe.com" />
    </Form.Item>

    <Form.Item label='Phone Number' name='phoneNumber'>
      <PhoneInput />
    </Form.Item>

    <Form.Item label='Merchant Account Number' name='merchantAccountNumber'>
      <Input />
    </Form.Item>

    <Form.Item {...tailLayout}>
      <Space size='small'>
        <Button htmlType='submit' type='primary'>Submit</Button>
        <Button onClick={() => onCancel()}>Cancel</Button>
      </Space>
    </Form.Item>
  </Form>
}

import { Dayjs } from "dayjs"
import { useCallback } from "react"
import {
    Collective,
    Contact,
    formatURIDate,
    formatURIDatetime,
    Merchant,
    MerchantAcquirerReport,
    RollupSummaryRecord,
    RuleType,
    serializeObjectToURLParams,
    Webhook
} from "../models/models"
import { ContentType, useRequest } from "./useRequest"
import { CollectiveRegionConfig_Response } from "pages/CollectiveView/CollectiveRegionView"
import { FilterProps } from "utils/txlogHelper"
import qs from "qs"

// Collective API

export const useCollectiveAPI = () => {
  const { makeRequest } = useRequest()

  // gets all collectives and merchants, probably needs a better name
  const getAllObjects = useCallback(
    () => {
      return makeRequest([`/v1/collective`, {}])
    }, [])

  const getCollective = useCallback(
    (cid: string, token?: string) => {
      const authHeader = token ? { Authorization: `Bearer ${token}` } : {}
      return makeRequest([`/v1/collective/${cid}`, {
        headers: { ...authHeader }
      }], { injectAuthHeaders: !!!token })
    }, [])

  const getCollectiveDashboard = useCallback(
    (cid: string) => {
      return makeRequest([`/v1/collective/dashboard?cid=${cid}`])
    }, [])

  const getMyCollectives = useCallback(
    () => {
      return makeRequest([`/v1/collective`, {}])
    }, [])

  const createCollective = useCallback(
    (c: Collective) => {
      return makeRequest([`/v1/support/collective`, {
        method: "POST",
        body: JSON.stringify(c),
        headers: {
          'content-type': 'application/json'
        }
      }])
    }, [])

  const updateCollectiveAdmin = useCallback(
    (c: Collective) => {
      return makeRequest([`/v1/support/collective/${c.id}`, {
        method: 'POST',
        body: JSON.stringify(c),
        headers: {
          'content-type': 'application/json'
        }
      }])
    }, [])

  const updateCollectiveLimited = useCallback(
    (c: Collective) => {
      return makeRequest([`/v1/collective/${c.id}`, {
        method: 'POST',
        body: JSON.stringify(c),
        headers: {
          'content-type': 'application/json'
        }
      }])
    }, [])

  const createMerchantInCollective = useCallback(
    (cid: string, m: Merchant) => {
      return makeRequest([`/v1/collective/${cid}/merchant`, {
        method: 'POST',
        body: JSON.stringify(m),
        headers: {
          'content-type': 'application/json'
        }
      }])
    }, [])

  const getWebhooks = useCallback(
    (cid: string): Promise<Webhook[]> => {
      return makeRequest([`/v1/collective/${cid}/webhook`])
    }, [])

  const whoAmI = useCallback(
    (token?: string) => {
      const authHeader = token ? { Authorization: `Bearer ${token}` } : {}
      return makeRequest(["/v1/collective/whoami", {
        headers: { ...authHeader }
      }], { injectAuthHeaders: false })
    }, [])

  const getTxLogs = useCallback(
    (cid: string, attributes: FilterProps & {skip?: any, limit?: any}) => {
      const params = qs.stringify(attributes, {arrayFormat: 'repeat'})
      const responseType = attributes.format === 'csv' || attributes.format === 'csv-remote' ? ContentType.TEXT : ContentType.JSON
      return makeRequest([`/v1/collective/${cid}/txlog?${params}`, {}], { responseType })
    }, [])

  const apiGetClient = useCallback(
    (cid: string) => {
      return makeRequest([`/v1/collective/${cid}/api/client`, {}])
    }, [])

  const apiCreateClient = useCallback(
    (cid: string) => {
      return makeRequest([`/v1/collective/${cid}/api`, {
        method: "POST",
        body: JSON.stringify({}),
        headers: {
          "content-type": "application/json"
        }
      }])
    }, [])

  const apiDeleteClient = useCallback(
    (cid: string, clientId: string) => {
      return makeRequest([`/v1/collective/${cid}/api?clientId=${clientId}`, {
        method: "DELETE",
        body: JSON.stringify({}),
        headers: {
          "content-type": "application/json"
        }
      }], {responseType: ContentType.NONE})
    }, [])

  const supportInvoiceRegister = useCallback(
    (cid: string, data: any) => {
      return makeRequest([`/v1/collective/${cid}/invoice/register`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "content-type": "application/json"
        }
      }])
    }, [])

  const supportInvoiceRegisterCustomerId = useCallback(
    (cid: string, customerId: any) => {
      return makeRequest([`/v1/collective/${cid}/invoice/register/customer`, {
        method: "PATCH",
        body: JSON.stringify({ customerId }),
        headers: {
          "content-type": "application/json"
        }
      }], { responseType: ContentType.NONE })
    }, [])

  const apiToggleGrantType = useCallback(
    (cid: string, grantType: string, toggle: boolean) => {
      return makeRequest([`/v1/collective/${cid}/api/${grantType}/${toggle}`, {
        method: "POST",
        body: JSON.stringify({}),
        headers: {
          "content-type": "application/json"
        }
      }], { responseType: ContentType.NONE })
    }, [])

  const checkInvitation = useCallback(
    (mid: string, token: string): Promise<boolean> => {
      return makeRequest([`/v1/collective/${mid}/user/invite/${token}`, {}]).then(_ => true)
    }, [])

  const collectiveHasSyncId = useCallback(
    (cid: string): Promise<boolean> => {
      return makeRequest([`/v1/collective/${cid}/config`, {}])
        .then(c => {
          console.log("gotten config", c)
          if (c && c.config) {
            const map = c.config
            return !!Object.keys(map).find(x => x === "sync-id")
          } else {
            return false
          }
        })
    }, [])

  const getCollectiveInviteMetadata = useCallback(
    (cid: string) => {
      return makeRequest([`/v1/collective/${cid}/metadata`, {}])
    }, [])

  const updateCollectiveTermsAdmin = useCallback(
    (cid: string, d: any) => {
      return makeRequest([`/v1/support/collective/${cid}/terms`, {
        method: "POST",
        body: JSON.stringify(d),
        headers: {
          "content-type": "application/json"
        }
      }])
    }, [])

  const getCollectiveTermsAdmin = useCallback(
    (cid: string) => {
      return makeRequest([`/v1/support/collective/${cid}/terms`, {}])
    }, [])

  const updateContact = useCallback(
    (cid: string, c: Contact) => {
      return makeRequest([`/v1/collective/${cid}/contact`, {
        method: "POST",
        body: JSON.stringify(c),
        headers: {
          "content-type": "application/json"
        }
      }])
    }, [])

  const getCollectiveMerchants = useCallback(
    (cid: string) => {
      return makeRequest([`/v1/collective/${cid}/merchant`, {}])
    }, [])

  const cloneProcessor = useCallback(
    (cid: string, midFrom: string, midTo: string, pid: string) => {
      return makeRequest([`/v1/collective/${cid}/processor/clone?midFrom=${midFrom}&midTo=${midTo}&pid=${pid}`, {
        method: "POST"
      }])
    }, [])

  const removeUser = useCallback(
    (cid: string, userId: string, role: string) => {
      return makeRequest([`/v1/collective/${cid}/user/${userId}/${role}`, {
        method: "DELETE"
      }])
    }, [])

  const getConfig = useCallback(
    (cid: string) => {
      return makeRequest([`/v1/collective/${cid}/config`, {}])
    }, [])

  const getRegionConfig = useCallback(
    (cid: string): Promise<CollectiveRegionConfig_Response> => {
      return makeRequest([`/v1/collective/${cid}/region`, {}])
    }, [])

  const addConfig = useCallback(
    (cid: string, data: any) => {
      return makeRequest([`/v1/collective/${cid}/config`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "content-type": "application/json"
        }
      }])
    }, [])

  const deleteConfig = useCallback(
    (cid: string, key: string) => {
      return makeRequest([`/v1/collective/${cid}/config/${key}`, {
        method: "DELETE"
      }])
    }, [])

  const getFeedataSummary = useCallback(
    (cid: string, start: Dayjs, end: Dayjs) => {
      const params = serializeObjectToURLParams({
        start: formatURIDate(start),
        end: formatURIDate(end)
      })
      return makeRequest([`/v1/collective/${cid}/feedata/summary?${params}`])
    }, [])
  
  const getAccountInfo = useCallback(
    (cid: string) => {
      return makeRequest([`/v1/collective/${cid}/invoice/account/info`, {
        method: 'GET'
      }])
    }, [])

  const getAcquirerReport = useCallback(
    (): Promise<{result: MerchantAcquirerReport[]}> => {
      return makeRequest([`/v1/collective/acquirer/report`])
    }, [])

  const getUserInfo = useCallback(
    (authId: string): Promise<{name: string, email: string}> => {
      return makeRequest([`/v1/collective/authinfo/${authId}`])
    }, [])

  const enableRule = useCallback(
    (cid: string, data: any) => {
      return makeRequest([`/v1/collective/${cid}/rule`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'content-type': 'application/json'
        }
      }], {responseType: ContentType.NONE})
    }, [])

  const disableRule = useCallback(
    (cid: string, ruleType: RuleType) => enableRule(cid, {ruleType, enable: false, data: {}}), [])

  return {
    getAllObjects, getCollective, getCollectiveDashboard, getMyCollectives,
    createCollective, updateCollectiveAdmin, updateCollectiveLimited,
    createMerchantInCollective,
    getWebhooks,
    whoAmI,
    getTxLogs,
    apiGetClient, apiCreateClient, apiDeleteClient,
    supportInvoiceRegister, supportInvoiceRegisterCustomerId, checkInvitation, getCollectiveInviteMetadata, apiToggleGrantType,
    collectiveHasSyncId, updateCollectiveTermsAdmin, getCollectiveTermsAdmin, updateContact,
    getCollectiveMerchants,
    cloneProcessor,
    removeUser,
    getConfig, getRegionConfig, addConfig, deleteConfig,
    getFeedataSummary, getAccountInfo,
    getAcquirerReport,
    getUserInfo,
    enableRule,
    disableRule
  }

}

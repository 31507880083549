/*
 * InterPayments Inc. ("COMPANY") CONFIDENTIAL
 * Unpublished Copyright © 2023 InterPayments Inc., All Rights Reserved.
 *
 * https://interpayments.com/copyright-policy/
 *
 * NOTICE: All information contained herein is, and remains the property of
 * COMPANY. The intellectual and technical concepts contained herein are
 * proprietary to COMPANY and may be covered by U.S. and Foreign Patents, patents
 * in process, and are protected by trade secret or copyright law. Dissemination
 * of this information or reproduction of this material is strictly forbidden
 * unless prior written permission is obtained from COMPANY. Access to the source
 * code contained herein is hereby forbidden to anyone except current COMPANY
 * employees, managers or contractors who have executed Confidentiality and
 * Non-disclosure agreements explicitly covering such access.
 *
 * The copyright notice above does not evidence any actual or intended publication
 * or disclosure of this source code, which includes information that is
 * confidential and/or proprietary, and is a trade secret, of COMPANY. ANY
 * REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY
 * OF OR THROUGH USE OF THIS SOURCE CODE WITHOUT THE EXPRESS WRITTEN CONSENT
 * OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 * INTERNATIONAL TREATIES. THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR
 * RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE
 * OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 * MAY DESCRIBE, IN WHOLE OR IN PART.
 *
 */

import React from 'react'
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom'

import {composeWithDevTools} from "redux-devtools-extension"
import {rootReducer} from "./reducers"
import {Provider} from "react-redux"
import {PersistGate} from 'redux-persist/integration/react'
import {persistStore} from 'redux-persist'

import {useAuth0} from "@auth0/auth0-react"
import {createStore} from "redux"

import { AuthError } from './components/AuthErrorMagicRedirect'

import 'antd/dist/reset.css'

/* Custom CSS for InterPayments styling */
import './theme/App.scss'

import Loading from "./components/Loading/Loading"
import {OpenServiceProvider, ServiceProvider} from './providers/service-provider/service-provider'
import {SseProvider} from './providers/sse-provider/sse-provider'
import {InvitationAccept} from "./pages/InvitationAccept/InvitationAccept"
import {Main} from "./pages/Main"
import {InvitationRsvp} from "./pages/InvitationAccept/InvitationRsvp"

import {createGlobalState} from "react-use"
import {DocsLandingPage} from "./pages/docs/DocsLandingPage"
import {PortalHelpPage} from "./pages/docs/help/PortalHelpPage"
import {ExportTransactionsHelpPage} from "./pages/docs/help/ExportTransactionsHelpPage"

import {Auth} from "./pages/Auth/Auth";
import {PortalTheme} from './models/models'
import {env2} from './utils/env2'
import {InvitationSignup} from "./pages/InvitationAccept/InvitationSignup";
import { ResourceGroupProvider } from 'providers/ResourceGroup/ResourceGroupContext'

const composeEnhancers = composeWithDevTools({})
const store = createStore(rootReducer, composeEnhancers())
const persistor = persistStore(store as any)

const BinServiceDeployment: boolean = env2("REACT_APP_BINSERVICE", "false") === "true"
const FeeServiceDeployment: boolean = env2("REACT_APP_FEESERVICE", "false") === "true"
const AuditPortalDeployment: boolean = env2("REACT_APP_AUDIT_PORTAL", "false") === "true"
const AdminPortalDeployment: boolean = env2("REACT_APP_ADMIN_PORTAL", "false") === "true"
const PortalDeployment: boolean = !(BinServiceDeployment || FeeServiceDeployment)
const SignupService: boolean = env2("REACT_APP_SIGNUPSERVICE", "false") === "true"

const portalTheme: PortalTheme = BinServiceDeployment ? PortalTheme.BINSERV
  : FeeServiceDeployment ? PortalTheme.FEESERV
  : SignupService ? PortalTheme.SIGNUP
  : AuditPortalDeployment ? PortalTheme.AUDIT_PORTAL
  : AdminPortalDeployment ? PortalTheme.ADMIN_PORTAL
  : PortalTheme.DEFAULT

const App: React.FC<React.PropsWithChildren<unknown>> = () => {

  const { isLoading, error } = useAuth0()

  if (error) {
    return <AuthError error={error} />
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      <BrowserRouter>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ServiceProvider>
              <SseProvider>
                <OpenServiceProvider>
                  <ResourceGroupProvider>
                    <Switch>
                      {!SignupService && <Route path="/auth" component={Auth} exact />}
                      {!SignupService && <Route path="/invitation/collective/:cid/accept/:token/:email/u" component={InvitationAccept} exact />}
                      {(!SignupService || SignupService) && "both for now" && <Route path="/invitation/collective/:cid/accept/:token/:email/u/:returnTo/s" component={InvitationSignup} exact />}
                      {!SignupService && <Route path="/invitation/collective/:cid/accept/:token/:email/a" component={InvitationRsvp} exact />}
                      {!SignupService && <Route path="/invitation/collective/:cid/merchant/:mid/accept/:token/:email/u" component={InvitationAccept} exact />}
                      {!SignupService && <Route path="/invitation/collective/:cid/merchant/:mid/accept/:token/:email/a" component={InvitationRsvp} exact />}
                      {!SignupService && <Route path="/invitation/merchant/:mid/accept/:token/:email/u" component={InvitationAccept} exact />}
                      {(!SignupService || SignupService) && "both for now" && <Route path="/invitation/merchant/:mid/accept/:token/:email/u/:returnTo/s" component={InvitationSignup} exact />}
                      {!SignupService && <Route path="/invitation/merchant/:mid/accept/:token/:email/a" component={InvitationRsvp} exact />}
                      {!SignupService && <Route path="/docs" component={DocsLandingPage} exact />}
                      {!SignupService && <Route path="/docs/help" component={PortalHelpPage} exact />}
                      {!SignupService && <Route path="/docs/help/exportTransactionsHelp" component={ExportTransactionsHelpPage} exact />}
                      {!SignupService && <Route path="/p" component={Main} />}
                      {!SignupService && <Redirect from="/" to="/p" exact />}
                      {SignupService && <Redirect from="/" to="/invitation/merchant/_/accept/_/_/u/s" exact />}
                    </Switch>
                  </ResourceGroupProvider>
                </OpenServiceProvider>
              </SseProvider>
            </ServiceProvider>
          </PersistGate>
        </Provider>
      </BrowserRouter>
    </>
  )
}

export const preferDarkMode = createGlobalState<boolean>(window.matchMedia('(prefers-color-scheme: dark)').matches)
export const usePortalTheme = createGlobalState<PortalTheme>(portalTheme)

export default App
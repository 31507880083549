import { useAuth0 } from "@auth0/auth0-react"
import { useEffect, useState } from "react"
import { useMerchantAPI } from './useMerchantAPI'

export const useInviteAcceptor = () => {
  const { isAuthenticated, isLoading } = useAuth0() // ✅ Include isLoading
  const { acceptAllInvites } = useMerchantAPI()
  
  const [isInvitesAccepted, setIsInvitesAccepted] = useState(false) // ✅ Track completion

  useEffect(() => {
    if (isLoading) return //Do nothing while authentication is still loading

    if (!isAuthenticated) {
      console.log("❌ Not authenticated - skipping invite acceptance")
      setIsInvitesAccepted(true)  //Mark as complete to prevent app from waiting
      return;
    }

    if (isAuthenticated && !isInvitesAccepted) {
      console.log("🔄 Accepting invites...")

      acceptAllInvites()
        .then(() => {
          console.log("✅ Invites accepted!")
          setIsInvitesAccepted(true)
        })
        .catch((error) => {
          console.error("❌ Failed to accept invites", error)
          setIsInvitesAccepted(true) // Prevent infinite loop even on failure
        });
    }
  }, [isAuthenticated, isInvitesAccepted, acceptAllInvites, isLoading])

  return isAuthenticated ? isInvitesAccepted : true // Prevents waiting if not authenticated
}

/*
 * InterPayments Inc. ("COMPANY") CONFIDENTIAL
 * Unpublished Copyright © 2023 InterPayments Inc., All Rights Reserved.
 *
 * https://interpayments.com/copyright-policy/
 *
 * NOTICE: All information contained herein is, and remains the property of
 * COMPANY. The intellectual and technical concepts contained herein are
 * proprietary to COMPANY and may be covered by U.S. and Foreign Patents, patents
 * in process, and are protected by trade secret or copyright law. Dissemination
 * of this information or reproduction of this material is strictly forbidden
 * unless prior written permission is obtained from COMPANY. Access to the source
 * code contained herein is hereby forbidden to anyone except current COMPANY
 * employees, managers or contractors who have executed Confidentiality and
 * Non-disclosure agreements explicitly covering such access.
 *
 * The copyright notice above does not evidence any actual or intended publication
 * or disclosure of this source code, which includes information that is
 * confidential and/or proprietary, and is a trade secret, of COMPANY. ANY
 * REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY
 * OF OR THROUGH USE OF THIS SOURCE CODE WITHOUT THE EXPRESS WRITTEN CONSENT
 * OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 * INTERNATIONAL TREATIES. THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR
 * RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE
 * OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 * MAY DESCRIBE, IN WHOLE OR IN PART.
 *
 */

import { createRoot } from 'react-dom/client';
import App from './App';
import { Auth0Provider } from "@auth0/auth0-react";
import { ErrorBoundary } from 'components/ErrorBoundary'

import { createBrowserHistory } from "history";
import { env2 } from "./utils/env2"

const history = createBrowserHistory()

const SignupService: boolean = env2("REACT_APP_SIGNUPSERVICE", "false") === "true"

const onRedirectCallback = (appState:any) => {
  if (!!appState && appState.returnTo) {
    let redir = appState?.returnTo || window.location.pathname
    console.log("onRedirectCallback == ", JSON.stringify(appState || { "none": true }), redir)
    history.replace(appState?.returnTo || window.location.pathname)
  }
  if (!!appState && appState.targetUrl) {
    const absolute = appState.targetUrl.indexOf("http://") || appState.targetUrl.indexOf("https://")
    if (SignupService || absolute) {
      //
      // full redirect back to where we came
      //
      let redir = appState?.targetUrl || window.location.href
      console.log("onRedirectCallback via targetUrl == ", JSON.stringify(appState || { "none": true }), redir)
      window.location.href = redir
    } else {
      //
      // path route to original path
      //
      let redir = appState?.targetUrl || window.location.pathname
      console.log("onRedirectCallback via targetUrl == ", JSON.stringify(appState || { "none": true }), redir)
      history.replace(appState?.targetUrl || window.location.pathname)
    }
  }
};

const root = createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Auth0Provider
    domain={env2("REACT_APP_AUTH0_DOMAIN")!}
    clientId={env2("REACT_APP_AUTH0_CLIENT_ID")!}
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: env2("REACT_APP_AUTH0_AUDIENCE")!
    }}
    onRedirectCallback={onRedirectCallback}
  >
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </Auth0Provider>)
import { Button, Form, Input, Space } from "antd"
import { useForm } from "antd/es/form/Form"
import { Merchant, formLayout, isRequiredRule, tailLayout } from "models/models"
import { Location, LocationRequest } from "models/paylink"
import { useCallback, useEffect } from "react"

type LocationForm_Shape = {
  name: string,
  email: string,
}

type LocationForm_Props = {
  merchant: Merchant,
  initialValues?: Location,
  onSubmit: (request: LocationRequest) => void,
  onCancel: () => void
}

export const LocationForm = ({ merchant, initialValues, onSubmit, onCancel }: LocationForm_Props) => {
  const [ form ] = useForm<LocationForm_Shape>()

  useEffect(() => {
    if (!initialValues) return
    const fields: LocationForm_Shape = {
      name: initialValues.contact.name,
      email: initialValues.contact.email ?? initialValues.receiptEmail ?? ''
    }
    form.setFieldsValue(fields)
  }, [initialValues])

  const handleSubmit = useCallback((values: LocationForm_Shape) => {
    const { name, email } = values
    const request: LocationRequest = {
      contact: {
        name,
        email,
      },
      receiptEmail: email
    }
    return onSubmit(request)
  }, [onSubmit])

  return <Form form={form} {...formLayout} onFinish={handleSubmit}>
    <Form.Item label='Name' name={'name'} rules={[isRequiredRule('Please enter a location name')]}>
      <Input placeholder="Main Street" />
    </Form.Item>

    <Form.Item label='Email' name={'email'} rules={[isRequiredRule('Please enter an email')]}>
      <Input placeholder="mainstreet@mybusiness.com" />
    </Form.Item>

    <Form.Item {...tailLayout}>
      <Space size='small'>
        <Button htmlType='submit' type='primary'>Submit</Button>
        <Button onClick={() => onCancel()}>Cancel</Button>
      </Space>
    </Form.Item>
  </Form>
}
import { ReactNode, useCallback, useEffect } from "react"
import { useParams } from "react-router-dom"
import { atom, createStore, Provider, useAtom } from 'jotai'
import { Merchant } from "models/models"
import { useMerchantAPI } from "hooks/useMerchantAPI"
import { Customer, Gateway, GatewayConfig, Location, Payment } from "models/paylink"
import { usePaylinkAPI } from "hooks/paylink/usePaylinkAPI"

const merchantAtom = atom<Merchant | undefined>(undefined)
const paymentsAtom = atom<Payment[] | undefined>(undefined)
const gatewaysAtom = atom<Gateway[] | undefined>(undefined)
const locationsAtom = atom<Location[] | undefined>(undefined)
const gatewayConfigsAtom = atom<GatewayConfig[] | undefined>(undefined)
const customersAtom = atom<Customer[] | undefined>(undefined)

export const PaylinkMerchantContextProvider = ({ children = <></> }: {children?: ReactNode}) => {
  return <>
    {/* <Provider */}
    {children}
    {/* </Provider /> */}
  </>
}

export const usePaylinkMerchantContext = () => {
  const [ merchant, setMerchant ] = useAtom(merchantAtom)
  const [ payments, setPayments ] = useAtom(paymentsAtom)
  const [ gateways, setGateways ] = useAtom(gatewaysAtom)
  const [ locations, setLocations ] = useAtom(locationsAtom)
  const [ gatewayConfigs, setGatewayConfigs ] = useAtom(gatewayConfigsAtom)
  const [ customers, setCustomers ] = useAtom(customersAtom)

  const { getMerchant } = useMerchantAPI()
  const { getPayments, getGateways, getCustomers, getLocations, getGatewayConfigs } = usePaylinkAPI()

  const reloadMerchant = useCallback((mid: string) => {
    setMerchant(undefined)
    return getMerchant(mid)
      .then(m => setMerchant(m.merchant))
  }, [])

  const reloadPayments = useCallback((mid: string) => {
    setPayments(undefined)
    return getPayments(mid)
      .then(p => setPayments(p.payments))
  }, [])

  const reloadGateways = useCallback((mid: string) => {
    setGateways(undefined)
    return getGateways(mid)
      .then(g => setGateways(g.gateways))
  }, [])

  const reloadLocations = useCallback((mid: string) => {
    setLocations(undefined)
    setGatewayConfigs(undefined)
    return Promise.all([
      getLocations(mid),
      getGatewayConfigs(mid)
    ])
      .then(([l, gc]) => {
        setLocations(l)
        setGatewayConfigs(gc)
      })
  }, [])

  const reloadCustomers = useCallback((mid: string) => {
    setCustomers(undefined)
    return getCustomers(mid)
      .then(c => setCustomers(c.customers))
  }, [])

  const reloadAll = useCallback((mid: string) => {
    return Promise.all([
      reloadMerchant(mid),
      reloadPayments(mid),
      reloadGateways(mid),
      reloadLocations(mid),
      reloadCustomers(mid)
    ])
      .then(_ => {})
  }, [])

  return {
    merchant, reloadMerchant,
    payments, reloadPayments,
    gateways, reloadGateways,
    locations, gatewayConfigs, reloadLocations,
    customers, reloadCustomers,
    reloadAll,
  }
}
/*
 * InterPayments Inc. ("COMPANY") CONFIDENTIAL
 * Unpublished Copyright © 2023 InterPayments Inc., All Rights Reserved.
 *
 * https://interpayments.com/copyright-policy/
 *
 * NOTICE: All information contained herein is, and remains the property of
 * COMPANY. The intellectual and technical concepts contained herein are
 * proprietary to COMPANY and may be covered by U.S. and Foreign Patents, patents
 * in process, and are protected by trade secret or copyright law. Dissemination
 * of this information or reproduction of this material is strictly forbidden
 * unless prior written permission is obtained from COMPANY. Access to the source
 * code contained herein is hereby forbidden to anyone except current COMPANY
 * employees, managers or contractors who have executed Confidentiality and
 * Non-disclosure agreements explicitly covering such access.
 *
 * The copyright notice above does not evidence any actual or intended publication
 * or disclosure of this source code, which includes information that is
 * confidential and/or proprietary, and is a trade secret, of COMPANY. ANY
 * REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY
 * OF OR THROUGH USE OF THIS SOURCE CODE WITHOUT THE EXPRESS WRITTEN CONSENT
 * OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 * INTERNATIONAL TREATIES. THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR
 * RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE
 * OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 * MAY DESCRIBE, IN WHOLE OR IN PART.
 *
 */

import {CartAction, CartState} from "./types"
import { createReducer } from 'typesafe-actions'
import {ResetCart, AddItem, CreateTransaction, UpdateTransaction} from "./actions"
import {Item} from "../../models/models"
import update from "immutability-helper"

const initialState: CartState = {
  // tx: undefined
  sTxId: undefined,
  mTxId: undefined,
  items: Array<Item>(),
  transactionFee: 0
}

const s4 = () => {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1)
}

const guid = () => {
  return s4() + s4()
}

const reducer = createReducer<CartState, CartAction>(initialState)
  .handleAction(ResetCart, (state:CartState, action) => {
    return initialState
  })
  .handleAction(AddItem, (state:CartState, action) => {
    return update(state, {
      items: { $push: [action.payload] }
    })
  })
  .handleAction(CreateTransaction, (state:CartState, action) => {
    return update(state, {
      $set: { ...state, mTxId: action.mTxId }
    })
  })
  .handleAction(UpdateTransaction, (state:CartState, action) => {
    return update(state, {
      $set: {
        ...state,
        mTxId: action.payload.mTxId || guid(),
        sTxId: action.payload.sTxId,
        transactionFee: action.payload.transactionFee
      }
    })
  })

export { reducer as cartReducer }

import { useCallback } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { env2 } from '../utils/env2';

export const BaseUrl = env2("REACT_APP_BASE_URL")
// const apiUrl = (path) => `${BaseUrl}${path}`

export type useRequest_Shape = (url?: string) => {
  makeRequest: (requestObject: MakeRequestObject, requestConfig?: MakeRequestConfig) => Promise<any>
}

export enum ContentType {
  NONE,
  TEXT,
  JSON,
  CSV,
}

type UseRequestConfig = {
  url?: string,
}

type StatusChecker = (s: number) => boolean
type ContentTypeChecker = (ct: string | null) => boolean
export type MakeRequestObject = [string] | [string, object]
export type MakeRequestConfig = {
  injectAuthHeaders?: boolean,
  statusChecker?: StatusChecker,
  responseType?: ContentType,
  mockValue?: () => any,
}

export const useRequest: useRequest_Shape = (url: string = BaseUrl) => {
  const { isAuthenticated, getIdTokenClaims } = useAuth0();

  const apiUrl = (path) => `${url}${path}`

  const ok: StatusChecker = status => status >= 200 && status < 300

  const isContentType = (ct: ContentType) => type => {
    if (ct === ContentType.NONE) return true
    else if (ct === ContentType.TEXT) return type.startsWith('text/plain')
    else if (ct === ContentType.JSON) return type.startsWith('application/json')
    else if (ct === ContentType.CSV) return type.startsWith('text/csv')
    return false
  }

  const assertStatus = (chk: StatusChecker = ok) => async (rsp: Response) => chk(rsp.status) ? Promise.resolve() : Promise.reject({error: true, message: `Returned unexpected status code: ${rsp.status}`, response: rsp})
  const assertContentType = (chk: ContentTypeChecker = isContentType(ContentType.NONE)) => async (rsp: Response) => {
    const { headers } = rsp
    const contentType = headers.get('content-type')
    if (chk(contentType)) return Promise.resolve()
    return Promise.reject({error: true, message: `Returned unexpected content type: ${contentType}`, response: rsp})
  }

  const parseContent = (ct: ContentType) => async (rsp: Response) => {
    if (ct === ContentType.NONE) return undefined
    else if (ct === ContentType.JSON) return rsp.json()
    else if (ct === ContentType.TEXT) return rsp.text()
    else return rsp.blob()
  }

  const injectDefaultsIntoRequest = (requestParams): any => {
    return {
      ...requestParams,
      cache: "default",
      credentials: 'include'
    };
  };

  const injectAuthIntoRequest = async (requestParams): Promise<any> => {
    const requestHeaders = !!requestParams.headers ? requestParams.headers : {};
    const idTokenClaims = await getIdTokenClaims();
    const idTokenInjectedHeader = idTokenClaims?.__raw !== undefined ? { Authorization: `Bearer ${idTokenClaims.__raw}` } : {};
    const authenticatedParams = {
      ...requestParams,
      headers: {
        ...requestHeaders,
        ...(idTokenInjectedHeader),
      }
    };
    return authenticatedParams;
  };

  // takes a tuple [url, params], where url is a string and params is an object
  const makeRequest = useCallback(
    async (requestObject: MakeRequestObject, requestConfig: MakeRequestConfig = {}) => {
      const [requestUrl, requestParams] = requestObject;

      const defaultRequestConfig: Required<MakeRequestConfig> = {
        injectAuthHeaders: true,
        statusChecker: ok,
        responseType: ContentType.JSON,
        mockValue: () => undefined,
      }
      const filledRequestConfig = {
        ...defaultRequestConfig,
        ...requestConfig
      } as MakeRequestConfig;

      const paramsWithDefault = injectDefaultsIntoRequest(requestParams);
      const builtParams = !!filledRequestConfig.injectAuthHeaders ? await injectAuthIntoRequest(paramsWithDefault) : paramsWithDefault;
      const fetchResult = await fetch(apiUrl(requestUrl), builtParams);

      return assertStatus(filledRequestConfig.statusChecker)(fetchResult)
        .then(_ => assertContentType(isContentType(filledRequestConfig.responseType!))(fetchResult))
        .then(_ => parseContent(filledRequestConfig.responseType!)(fetchResult))
        .catch(e => {
          return e.response.json()
            .then(j => Promise.reject({...e, body: j}))
        })
    }, [isAuthenticated, getIdTokenClaims]
  );

  return { makeRequest };
};
import { CardProps, Card } from "antd"
import { useMemo } from "react"

export const ExpandableCard = (props: CardProps & { enabled: boolean }) => {
  const { enabled, children, styles, ...otherProps } = props

  const styleProps = useMemo(() => enabled ? {styles} : ({
    styles: {
      ...(!!styles ? styles : {}),
      body: {
        padding: 0
      }
    }
  }), [enabled, styles])

  return <Card
    {...otherProps}
    {...styleProps}
  >
    {enabled ? children : <></>}
  </Card>
}
import { DollarOutlined, IdcardOutlined } from "@ant-design/icons"
import { Button, Col, Form, message, Result, Row, Typography } from "antd"
import { useForm } from "antd/es/form/Form"
import { useCallback } from "react"
import { PaymentCard } from "./PaylinkVirtualTerminalView"
import { PaymentType } from "models/paylink"
import { usePaylinkMerchantContext } from "../PaylinkMerchantView/PaylinkMerchantContext"

type PaylinkCreateHostedPaymentViewForm_Shape = {
  locationId: string,
  gatewayId: string,
  amount: number,
  paymentType: PaymentType,
  surcharge: boolean,
}

export const PaylinkCreateHostedPaymentView = () => {
  const { merchant } = usePaylinkMerchantContext()
  const [ form ] = useForm()

  const handleSubmit = useCallback((values: PaylinkCreateHostedPaymentViewForm_Shape) => {
    const adjustedValues = {
      merchantId: merchant!.id!,
      ...values,
      amount: values.amount * 100
    }
    const stringified = JSON.stringify(adjustedValues)
    const base64 = btoa(stringified)
    const sessionLink = `${window.location.origin}/hpp?session=${base64}`
    navigator.clipboard.writeText(sessionLink)
      .then(_ => message.success(`Copied hosted payment link to the clipboard`))
  }, [])
  
  return <>
    <Form form={form} component={false} onFinish={handleSubmit}>
      <Row gutter={[16, 16]}>
        {/* Main page */}
        <Col xs={24}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Result
                icon={<IdcardOutlined />}
                extra={<>
                  <Typography.Title level={2}>New Hosted Payment</Typography.Title>
                </>}
              />
            </Col>

            <Col span={24}>
              <PaymentCard authorization={new Set()} />
            </Col>

            <Col span={24}>
              <Button type='primary' block icon={<IdcardOutlined />} onClick={_ => form.submit()}>Share</Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  </>
}
import { AddressRequest } from "components/paylink/AddressForm";
import { useRequest } from "hooks/useRequest";
import { Address, CardMethod, CardRequest, Customer, CustomerRequest, Gateway, GatewayConfig, GatewayRequest, InviteRequest, Location, LocationRequest, Payment, PaymentResponse, SessionRequest, TransactionFeeRequest, TransactionFeeResponse } from "models/paylink";
import { useCallback, useMemo } from "react";
import { env2 } from "utils/env2";
import { PaymentRequest } from 'models/paylink/PaymentRequest'
import { v4 } from "uuid";


const PaylinkBaseUrl = env2("REACT_APP_PAYLINK_URL")
//const PaylinkAccessToken = env2("REACT_APP_PAYLINK_ACCESS_TOKEN")

//const getAccessToken = () => PaylinkAccessToken

//console.log(getAccessToken())

export const usePaylinkAPI = () => {
  const iFrameStyleSrc = useMemo(() => `${PaylinkBaseUrl}/v2/css/client.drop-in.min.css`, [PaylinkBaseUrl])
  const iFrameLibrarySrc = useMemo(() => `${PaylinkBaseUrl}/v2/js/client.drop-in.min.js`, [PaylinkBaseUrl])

  const { makeRequest } = useRequest()

  const createSession = useCallback(
    (mid: string, request: SessionRequest) => {
      return makeRequest([`/v2/paylink/sessions/${mid}`, {
        method: 'POST',
        body: JSON.stringify(request),
        headers: {
          'content-type': 'application/json'
        }
      }])
    }, [makeRequest])

  const getSessionUrl = useCallback((sessionId: string) => `${PaylinkBaseUrl}/v2/controls/${sessionId}`, [])

  const createCustomer = useCallback(
    (mid: string, request: {customer: CustomerRequest}) => {
      return makeRequest([`/v2/paylink/customers/${mid}`, {
        method: 'POST',
        body: JSON.stringify(request),
        headers: {
          'content-type': 'application/json'
        }
      }])
    }, [makeRequest])

  const createGateway = useCallback(
    (mid: string, request: GatewayRequest) => {
      return makeRequest([`/v2/paylink/gateways/${mid}`, {
        method: 'POST',
        body: JSON.stringify(request),
        headers: {
          'content-type': 'application/json'
        }
      }])
    }, [makeRequest])

  const updateGateway = useCallback(
    (mid: string, gid: string, request: GatewayRequest) => {
      return makeRequest([`/v2/paylink/gateways/${mid}/${gid}`, {
        method: 'PUT',
        body: JSON.stringify(request),
        headers: {
          'content-type': 'application/json'
        }
      }])
    }, [makeRequest])

  const getGateway = useCallback(
    (mid: string, gid: string) => {
      return makeRequest([`/v2/paylink/gateways/${mid}/${gid}`])
    }, [makeRequest])

  const createCard = useCallback(
    (mid: string, request: CardRequest) => {
      return makeRequest([`/v2/paylink/cards/${mid}`, {
        method: 'POST',
        body: JSON.stringify(request),
        headers: {
          'content-type': 'application/json'
        }
      }])
    }, [makeRequest])

  const createAddress = useCallback(
    (mid: string, cid: string, request: AddressRequest) => {
      return makeRequest([`/v2/paylink/customers/${mid}/${cid}/addresses`, {
        method: 'POST',
        body: JSON.stringify(request),
        headers: {
          'content-type': 'application/json'
        }
      }])
    }, [makeRequest])

  const updateAddress: (mid: string, cid: string, aid: string, request: any) => Promise<any> = useCallback(
    (mid: string, cid: string, aid: string, request: any) => {
      return makeRequest([`/v2/paylink/customers/${mid}/${cid}/addresses/${aid}`, {
        method: 'PUT',
        body: JSON.stringify(request),
        headers: {
          'content-type': 'application/json'
        }
      }])
    }, [makeRequest])
  
  const createPayment = useCallback(
    (mid: string, request: PaymentRequest): Promise<PaymentResponse> => {
      return makeRequest([`/v2/paylink/payments/location/${mid}`, {
        method: 'POST',
        body: JSON.stringify(request),
        headers: {
          'content-type': 'application/json'
        }
      }])
    }, [makeRequest])

  const capturePayment = useCallback(
    (mid: string, pid: string) => {
      return makeRequest([`/v2/paylink/payments/location/${mid}/${pid}/capture`, {
        method: 'POST',
        body: JSON.stringify({idempotencyKey: v4()}),
        headers: {
          'content-type': 'application/json'
        }
      }])
    }, [makeRequest])

  const voidPayment = useCallback(
    (mid: string, pid: string) => {
      return makeRequest([`/v2/paylink/payments/location/${mid}/${pid}/void`, {
        method: 'POST',
        body: JSON.stringify({idempotencyKey: v4()}),
        headers: {
          'content-type': 'application/json'
        }
      }])
    }, [makeRequest])

  const refundPayment = useCallback(
    (mid: string, pid: string) => {
      return makeRequest([`/v2/paylink/refunds/location/${mid}`, {
        method: 'POST',
        body: JSON.stringify({idempotencyKey: v4(), refund: { paymentId: pid }}),
        headers: {
          'content-type': 'application/json'
        }
      }])
    }, [makeRequest])

  const getGateways: (mid: string) => Promise<{gateways: Gateway[]}> = useCallback(
    (mid: string) => {
      return makeRequest([`/v2/paylink/gateways/${mid}`])
    }, [makeRequest])

  const getCustomers: (mid: string) => Promise<{customers: Customer[]}> = useCallback(
    (mid: string) => {
      return makeRequest([`/v2/paylink/customers/${mid}`])
    }, [makeRequest])

  const getCustomer: (mid: string, cid: string) => Promise<{customer: Customer}> = useCallback(
    (mid: string, cid: string) => {
      return makeRequest([`/v2/paylink/customers/${mid}/${cid}`])
    }, [makeRequest])

  const updateCustomer: (mid: string, cid: string, request: any) => Promise<any> = useCallback(
    (mid: string, cid: string, request: any) => {
      return makeRequest([`/v2/paylink/customers/${mid}/${cid}`, {
        method: 'PUT',
        body: JSON.stringify(request),
        headers: {
          'content-type': 'application/json'
        }
      }])
    }, [makeRequest])

  const getCards: (mid: string, cid: string) => Promise<any> = useCallback(
    (mid: string, cid: string) => {
      return makeRequest([`/v2/paylink/customers/${mid}/${cid}/cards`])
    }, [makeRequest])

  const getCard: (mid: string, cardId: string) => Promise<{card: CardMethod}> = useCallback(
    (mid: string, cardId: string) => {
      return makeRequest([`/v2/paylink/cards/${mid}/${cardId}`])
    }, [makeRequest])

  const getBankAccounts: (mid: string, cid: string) => Promise<any> = useCallback(
    (mid: string, cid: string) => {
      return makeRequest([`/v2/paylink/customers/${mid}/${cid}/bank-accounts`])
    }, [makeRequest])

  const getAddresses: (mid: string, cid: string) => Promise<{addresses: Address[]}> = useCallback(
    (mid: string, cid: string) => {
      return makeRequest([`/v2/paylink/customers/${mid}/${cid}/addresses`])
    }, [makeRequest])

  const getAddress: (mid: string, aid: string) => Promise<{address: Address}> = useCallback(
    (mid: string, aid: string) => {
      return makeRequest([`/v2/paylink/addresses/${mid}/${aid}`])
    }, [makeRequest])

  const getPayment: (mid: string, pid: string) => Promise<{payment: Payment}> = useCallback(
    (mid: string, pid: string) => {
      return makeRequest([`/v2/paylink/payments/location/${mid}/${pid}`])
    }, [makeRequest])

  const getPayments: (mid: string) => Promise<{payments: Payment[]}> = useCallback(
    (mid: string) => {
      return makeRequest([`/v2/paylink/payments/location/${mid}`])
    }, [makeRequest])

  const getInviteMetadata: (mid: string) => Promise<any> = useCallback(
    (mid: string) => {
      return makeRequest([`/v2/paylink/${mid}/metadata`])
    }, [makeRequest])

  const sendInvite: (mid: string, invite: InviteRequest) => Promise<any> = useCallback(
    (mid: string, request: InviteRequest) => {
      return makeRequest([`/v2/paylink/${mid}/user`, {
        method: 'POST',
        body: JSON.stringify(request),
        headers: {
          'content-type': 'application/json'
        }
      }])
    }, [makeRequest])

  const deleteUser: (mid: string, uid: string, role: string) => Promise<any> = useCallback(
    (mid: string, uid: string, role: string) => {
      return makeRequest([`/v2/paylink/${mid}/user/${uid}/${role}`, {
        method: 'DELETE',
        headers: {
          'content-type': 'application/json'
        }
      }])
    }, [makeRequest])

  const deleteInvite: (mid: string, token: string) => Promise<any> = useCallback(
    (mid: string, token: string) => {
      return makeRequest([`/v2/paylink/${mid}/user/invite/${token}`, {
        method: 'DELETE',
        body: '{}',
        headers: {
          'content-type': 'application/json'
        }
      }])
    }, [makeRequest])

  const createTransactionFee: (mid: string, request: TransactionFeeRequest) => Promise<TransactionFeeResponse> = useCallback(
    (mid: string, request: TransactionFeeRequest) => {
      return makeRequest([`/v2/paylink/transaction-fees/${mid}`, {
        method: 'POST',
        body: JSON.stringify({...request}),
        headers: {
          'content-type': 'application/json'
        }
      }])
  }, [makeRequest])

  const getLocations: (mid: string) => Promise<Location[]> = useCallback(
    (mid: string) => {
      return makeRequest([`/v2/paylink/locations/${mid}`])
    }, [makeRequest])

  const getLocation: (mid: string, lid: string) => Promise<Location> = useCallback(
    (mid: string, lid: string) => {
      return makeRequest([`/v2/paylink/locations/${mid}/${lid}`])
    }, [makeRequest])

  const createLocation: (mid: string, location: LocationRequest) => Promise<void> = useCallback(
    (mid: string, location: LocationRequest) => {
      return makeRequest([`/v2/paylink/locations/${mid}`, {
        method: 'POST',
        body: JSON.stringify(location),
        headers: {
          'content-type': 'application/json'
        }
      }])
  }, [makeRequest])

  const updateLocation: (mid: string, lid: string, location: Location) => Promise<void> = useCallback(
    (mid: string, lid: string, location: Location) => {
      return makeRequest([`/v2/paylink/locations/${mid}/${lid}`, {
        method: 'PUT',
        body: JSON.stringify(location),
        headers: {
          'content-type': 'application/json'
        }
      }])
  }, [makeRequest])

  const deleteLocation: (mid: string, lid: string) => Promise<void> = useCallback(
    (mid: string, lid: string) => {
      return makeRequest([`/v2/paylink/locations/${mid}/${lid}`, {
        method: 'DELETE',
        body: JSON.stringify({}),
        headers: {
          'content-type': 'application/json'
        }
      }])
  }, [makeRequest])

  const getGatewayConfigs: (mid: string) => Promise<GatewayConfig[]> = useCallback(
    (mid: string) => {
      return makeRequest([`/v2/paylink/gatewayconfigs/${mid}`])
    }, [makeRequest])

  const getGatewayConfig: (mid: string, gcid: string) => Promise<GatewayConfig> = useCallback(
    (mid: string, gcid: string) => {
      return makeRequest([`/v2/paylink/gatewayconfigs/${mid}/${gcid}`])
    }, [makeRequest])

  const createGatewayConfig: (mid: string, gatewayConfig: GatewayConfig) => Promise<void> = useCallback(
    (mid: string, gatewayConfig: GatewayConfig) => {
      return makeRequest([`/v2/paylink/gatewayconfigs/${mid}`, {
        method: 'POST',
        body: JSON.stringify(gatewayConfig),
        headers: {
          'content-type': 'application/json'
        }
      }])
  }, [makeRequest])

  const updateGatewayConfig: (mid: string, gcid: string, gatewayConfig: GatewayConfig) => Promise<void> = useCallback(
    (mid: string, gcid: string, gatewayConfig: GatewayConfig) => {
      return makeRequest([`/v2/paylink/gatewayconfigs/${mid}/${gcid}`, {
        method: 'PUT',
        body: JSON.stringify(gatewayConfig),
        headers: {
          'content-type': 'application/json'
        }
      }])
  }, [makeRequest])

  const deleteGatewayConfig: (mid: string, gcid: string) => Promise<void> = useCallback(
    (mid: string, gcid: string) => {
      return makeRequest([`/v2/paylink/locations/${mid}/${gcid}`, {
        method: 'DELETE',
        headers: {
          'content-type': 'application/json'
        }
      }])
  }, [makeRequest])

  return {
    iFrameStyleSrc, iFrameLibrarySrc,
    createSession, getSessionUrl,
    createCustomer, updateCustomer,
    createGateway, updateGateway, getGateway,
    createCard,
    createAddress, updateAddress,
    createPayment, capturePayment, voidPayment, refundPayment,
    getGateways,
    getCustomers,
    getCustomer,
    getCards, getCard,
    getBankAccounts,
    getAddresses, getAddress,
    getPayment,
    getPayments,
    getInviteMetadata, sendInvite, deleteUser, deleteInvite,
    createTransactionFee,
    getLocations, getLocation, createLocation, updateLocation, deleteLocation,
    getGatewayConfigs, getGatewayConfig, createGatewayConfig, updateGatewayConfig, deleteGatewayConfig,
  }
}
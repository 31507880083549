import { Button, Cascader, Col, Form, Input, Row } from "antd"
import { useForm } from "antd/es/form/Form"
import { regionCascaderOptions } from "pages/paylink/PaylinkVirtualTerminalView/RegionSelector"
import { useEffect } from "react"

const isRequired = {required: true}

export type AddressRequest = any

export const AddressForm = ({ onSubmit, onCancel, initialValues }: {onSubmit: (values: AddressRequest) => void, onCancel: () => void, initialValues?: Partial<AddressRequest>}) => {
  const [ form ] = useForm()

  useEffect(() => {
    if (!initialValues) return
    const { region, country, ...otherValues } = initialValues
    const modifiedValues = {...otherValues, region: [country, region]}
    form.setFieldsValue(modifiedValues)
  }, [initialValues])

  const handleSubmit = (values: any) => {
    const { region, ...otherValues } = values
    onSubmit({...otherValues, region: region[1], country: region[0]})
  }

  return <Form form={form} component={false} onFinish={handleSubmit}>
    <Row gutter={[16, 16]}>
      <Col span={12}>
        <Form.Item layout='vertical' label='First Name' name={['firstName']} rules={[isRequired]}>
          <Input placeholder='John' />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item layout='vertical' label='Last Name' name={['lastName']} rules={[isRequired]}>
          <Input placeholder='Doe' />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item label='Phone Number' name={['phoneNumber']} layout='vertical' rules={[isRequired]}>
          <Input />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item label='Email' name={['email']} layout='vertical' rules={[isRequired]}>
          <Input />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item layout='vertical' label='Address' name={['line1']} rules={[isRequired]}>
          <Input placeholder='1 Sample Drive' />
          {/* <Input placeholder='Unit 1' style={{marginBlockStart: '0.25em'}} /> */}
        </Form.Item>
      </Col>

      <Col span={8}>
        <Form.Item layout='vertical' label='City' name={['locality']} rules={[isRequired]}>
          <Input placeholder='Sample City' />
        </Form.Item>
      </Col>

      <Col span={8}>
        <Form.Item layout='vertical' label='Region' name={['region']} rules={[{ type: 'array', required: true }]}>
          <Cascader placeholder='Sample Country / Sample Region' options={regionCascaderOptions} />
        </Form.Item>
      </Col>

      <Col span={8}>
        <Form.Item layout='vertical' label='Postal Code' name={['postalCode']} rules={[isRequired]}>
          <Input placeholder='11111' />
        </Form.Item>
      </Col>

      <Col span={8}>
        <Button block onClick={_ => onCancel()}>Cancel</Button>
      </Col>

      <Col span={16}>
        <Button type='primary' block onClick={_ => form.submit()}>Submit</Button>
      </Col>
    </Row>
  </Form>
}

/*
 * InterPayments Inc. ("COMPANY") CONFIDENTIAL
 * Unpublished Copyright © 2023 InterPayments Inc., All Rights Reserved.
 *
 * https://interpayments.com/copyright-policy/
 *
 * NOTICE: All information contained herein is, and remains the property of
 * COMPANY. The intellectual and technical concepts contained herein are
 * proprietary to COMPANY and may be covered by U.S. and Foreign Patents, patents
 * in process, and are protected by trade secret or copyright law. Dissemination
 * of this information or reproduction of this material is strictly forbidden
 * unless prior written permission is obtained from COMPANY. Access to the source
 * code contained herein is hereby forbidden to anyone except current COMPANY
 * employees, managers or contractors who have executed Confidentiality and
 * Non-disclosure agreements explicitly covering such access.
 *
 * The copyright notice above does not evidence any actual or intended publication
 * or disclosure of this source code, which includes information that is
 * confidential and/or proprietary, and is a trade secret, of COMPANY. ANY
 * REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY
 * OF OR THROUGH USE OF THIS SOURCE CODE WITHOUT THE EXPRESS WRITTEN CONSENT
 * OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 * INTERNATIONAL TREATIES. THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR
 * RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE
 * OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 * MAY DESCRIBE, IN WHOLE OR IN PART.
 *
 */

import {combineReducers, Reducer} from "redux"
import { TypedUseSelectorHook, useSelector } from "react-redux"
import {createSelector} from "reselect"

import {Collective, Merchant} from "../models/models"

import {MerchantState, MerchantAction} from "./merchant/types"
import {merchantReducer} from "./merchant/merchant"

import {CollectiveAction, CollectiveState} from "./collective/types"
import {collectiveReducer} from "./collective/collective"

import {ContextAction, ContextState, TransientTokenMap} from "./context/types"
import {contextReducer} from "./context/context"

import {StatusStateAction, StatusState} from "./status-state/types"
import {statusStateReducer} from "./status-state/reducer"

import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {PersistState} from "redux-persist/es/types";

import {TxLogState, TxLogAction} from "./txlog/types"
import {txLogReducer} from "./txlog/txlog"

import {WhoAmIState, WhoAmIAction} from "./whoami/types"
import {whoAmIReducer} from "./whoami/whoami"

import {CartAction, CartState} from "./cart/types";
import {cartReducer} from "./cart/cart";

import {InterchangeAction, InterchangeState} from './interchange/types'
import {interchangeReducer} from './interchange/interchange'

import {ExternalAuthAction, ExternalAuthState} from './external-auth/types'
import {externalAuthReducer} from './external-auth/external-auth'

import { createTransform } from 'redux-persist'

//import immutableTransform from 'redux-persist-transform-immutable'
interface PersistPartial {
  _persist: PersistState
}

const persistContextConfig = {
  //transforms: [immutableTransform()],
  key: 'context',
  storage,
}

/* why is everything so hard to do???
const mapTransformer = config =>
  createTransform(
    (inboundState) => {
      console.log("serialize map", inboundState)
      return Array.from(map)
    },
    array => {
      console.log("deserialize map", array)
      return new Map(array)
    },
    config
);
 */

const persistInterchange = {
  key: 'interchange',
  storage,
  // debug: true,
  //transforms: [mapTransformer({ whitelist: ['programs', 'interchange'] })],
  //whitelist: ['programs', 'interchange']
}

const persistExternalConfig = {
  key: 'external',
  storage,
}

export type ApplicationState = {
  merchant: MerchantState,
  collective: CollectiveState,
  context: ContextState & PersistPartial,
  sse: StatusState,
  txlog: TxLogState,
  whoami: WhoAmIState,
  cart: CartState,
  interchange: InterchangeState & PersistPartial,
  external: ExternalAuthState & PersistPartial,
}

const appReducer = combineReducers({
  merchant: merchantReducer,
  collective: collectiveReducer,
  context: persistReducer(persistContextConfig, contextReducer),
  sse: statusStateReducer,
  txlog: txLogReducer,
  whoami: whoAmIReducer,
  cart: cartReducer,
  interchange: persistReducer(persistInterchange, interchangeReducer),
  external: persistReducer(persistExternalConfig, externalAuthReducer)
})

export const useTypedSelector: TypedUseSelectorHook<ApplicationState> = useSelector

export const rootReducer: Reducer<ApplicationState,
  MerchantAction |
  CollectiveAction |
  ContextAction |
  StatusStateAction |
  TxLogAction |
  WhoAmIAction |
  CartAction |
  InterchangeAction |
  ExternalAuthAction> = (state, action) => {
  return appReducer(state, action)
}

export const createGetTransientTokenSelector = () => createSelector(
  (s: ApplicationState, o: {mid: string}) => s.context.transientTokens,
  (_, { mid }) => mid,
  (tokens:TransientTokenMap, mid: string) => {
    return { token: tokens[mid] }
  }
)

export const createGetMerchants = () => createSelector(
  (s: ApplicationState) => {
    let rv: Merchant[] = []
    Object.entries(s.merchant.merchants)
      .sort(([k1, p1], [k2, p2]) => {
        // console.log("comparing", p1, p2)
        return ((p1 && p1.name) || '').localeCompare(((p2 && p2.name) || ''))
      })
      .forEach(([k, p]) => rv.push(p))
    return rv
  },ps => ps
)

export const createGetInvoicableMerchants = () => createSelector(
  (s: ApplicationState) => {
    let rv: Merchant[] = []
    Object.entries(s.merchant.merchants)
      .sort(([k1, p1], [k2, p2]) => {
        return ((p1 && p1.name) || '').localeCompare(((p2 && p2.name) || ''))
      })
      .filter(([k, p]) => {
        return p.terms?.invoiceType !== "NONE" && p.status === "ACTIVE"
      })
      .forEach(([k, p]) => rv.push(p))
    return rv
  },ps => ps
)

export const createGetCollectives = () => createSelector(
  (s: ApplicationState) => {
    let rv: Collective[] = []
    Object.entries(s.collective.collectives)
      .sort(([k1, p1], [k2, p2]) => ((p1 && p1.name) || '').localeCompare(((p2 && p2.name) || '')))
      .forEach(([k, p]) => {
        rv.push(p)
      })
    return rv
  },ps => ps
)

export const createGetPrograms = (jurisdiction: string) => createSelector(
  (s: ApplicationState) => {
    if (s.interchange.jurisdictionToPrograms.find(x => x.jurisdiction === jurisdiction)?.programWrapper.lastFetch === undefined) return []
    else return s.interchange.jurisdictionToPrograms
  }, p => p
)

export const createTxLogSelector = () => createSelector(
  (state: ApplicationState) => state.txlog,
  x => x
)

type AuthBreadcrumbCount = "none" | "one" | "some"

type AuthBreadcrumbHints = {
  isAdmin: boolean,
  hasCollective: AuthBreadcrumbCount,
  hasMerchant: AuthBreadcrumbCount,
}

const defaultBreadcrumbHints:AuthBreadcrumbHints = {
  isAdmin: false,
  hasCollective: "none" as AuthBreadcrumbCount,
  hasMerchant: "none" as AuthBreadcrumbCount,
}

const hasAnyPermissions = (object:any) => Object.keys(object).reduce((hasPermission: boolean, key: string) => hasPermission ? true : object[key], false)

import { Contact, UserRole } from "./models"

export enum IFrameLayout {
  Tabs = 'Tabs',
  Accordion = 'Accordion'
}

type SessionAppearanceRequest = {
  styles: {[key: string]: string}
}

type SessionCardRequest = Record<string, never>
type SessionContainerRequest = string
type SessionBankAccountRequest = Record<string, never>

type SessionButtonRequest = {
  text: string,
}

type SessionLayoutRequest = {
  collapsed: boolean,
  layoutType: IFrameLayout,
  radios: boolean,
  spaced: boolean,
}

type DropInSessionRequest = {
  gatewayId: string,
  dropIn: {
    appearance: SessionAppearanceRequest,
    card?: SessionCardRequest,
    container?: SessionContainerRequest,
    bankAccount?: SessionBankAccountRequest,
    button?: SessionButtonRequest,
    layout: SessionLayoutRequest
  }
} 

type SurchargeSessionRequest = {
  surcharge: {
    amount: {
      value: string
    },
    processor: string,  
  }
}

export type SessionRequest = {
  session: DropInSessionRequest
}

export type CustomerRequest = {
  firstName: string,
  lastName: string,
  companyName: string,
  email: string,
  phoneNumber: string,
  merchantAccountNumber: string,
}

export enum GatewayType {
  AuthorizeNet = 'AuthorizeNet',
  Cybersource = 'Cybersource',
  Stripe = 'Stripe'
}

export type Gateway = {
  name: string,
  id: string,
  sandbox: boolean,
  currency: string,
  disabled: boolean,
  gatewayType: GatewayType,

  level3QualificationRequestEnabled: boolean,
  processor: string,
}

type CommonGatewayFacets = {
  name: string,
  processor: string,
  currency: string,
  sandbox: boolean,
}

export type AuthorizeNetGatewayRequest = {
  gateway: CommonGatewayFacets & {
    credentials: {
      name: string,
      transactionKey: string,
    },
    gatewayType: GatewayType.AuthorizeNet,
  }
}

export type CybersourceGatewayRequest = {
  gateway: CommonGatewayFacets & {
    credentials: {
      keyId: string,
      merchantId: string,
      sharedSecret: string,
    },
    gatewayType: GatewayType.Cybersource,
  }
}

export type StripeGatewayRequest = {
  gateway: CommonGatewayFacets & {
    credentials: {
      login: string
    }
    gatewayType: GatewayType.Stripe,
  }
}

export type GatewayRequest = AuthorizeNetGatewayRequest | CybersourceGatewayRequest | StripeGatewayRequest

export type CardRequest = {
  card: {
    address: AddressDetails,
    customerId: string,
    nameOnCard: string,
    cardNumber: string,
    expirationMonth: string,
    expirationYear: string,
    cardCode: string,
    default: boolean
  }
  gatewayId: string,
  idempotencyKey: string,
}

export type Customer = {
  id: string,
  firstName: string,
  lastName: string,
  email: string,
  companyName?: string,
  phoneNumber: string,
  merchantAccountNumber: string,
  disabled: boolean,
}

export const getCustomerName = (customer: Customer) => {
  if (customer.firstName && customer.lastName) return `${customer.firstName} ${customer.lastName}`
  return customer.companyName
}

export enum PaymentStatus {
  AUTHORIZED = 'Authorized',
  CAPTURED = 'Captured',
  VOIDED = 'Voided',
}

export enum PaymentType {
  AUTHORIZATION = 'Authorization',
  SALE = 'Sale',
}

export type Payment = {
  id: string,
  address: AddressDetails,
  authorizationAmount: number,
  captureAmount: number,
  card: any,
  creationMoment: string,
  currency: string,
  customerId: string,
  gatewayId: string,
  invoiceNumbers: string[],
  merchantReferenceCode: string,
  message: string,
  modificationMoment: string,
  paymentStatus: PaymentStatus,
  paymentType: PaymentType,
  purchaseOrderNumbers: string[],
  shippingAmount: number,
  surchargeAmount: number,
  taxAmount: number,
}

export type AddressDetails = {
  firstName: string,
  lastName: string,
  line1: string,
  locality: string,
  region: string,
  postalCode: string,
  country: string,    
  phoneNumber: string,
  email: string,
}

export type Address = AddressDetails & {
  default: boolean,
  disabled: boolean,
  id: string,
}

export type CardMethod = {
  address: AddressDetails,
  cardNumber: string,
  expirationMonth: string,
  expirationYear: string,
  nameOnCard: string,
  gatewayId: string,
  token: string,
  customerId: string,
  default: boolean,
  disabled: boolean,
  id: string,
}

export type InviteRequest = {
  merchantId: string,
  email: string,
  roles: UserRole[],
  origin?: string
}

type TransactionFeeRequest_Nonce = {
  paymentMethodNonce: string
}

type TransactionFeeRequest_Token = {
  paymentMethodToken: string
}

type TransactionFeeRequest_Unscoped = {
  bin: string,
  region: string,
}

export type TransactionFeeRequest = {
  idempotencyKey: string,
  transactionFee: {
    gatewayId: string,
    region: string,
    transaction: {
      amount: number,
    },
    waive?: boolean,
  } & (TransactionFeeRequest_Nonce | TransactionFeeRequest_Token | TransactionFeeRequest_Unscoped)
}

export type TransactionFeeResponse = {
  transactionFee: {
    amount: number,
    country: string,
    data: string[],
    discountPercentage: number,
    id: string,
    maxRatePercentage: number,
    merchantReferenceCode: string,
    message: string,
    percentage: number,
    processor: string,
    reasonCodes: string[],
    region: string,
    transaction: {
      amount: number,
      nonSurchargableAmount: number,
      totalAmount: number,
    }
    transactionFeeStatus: string,
    waive: boolean,
  }
}

export type PaymentResponse = {
  payment: {
    addressVerificationServiceCode: string,
    authorizationAmount: number,
    authorizationCode: string,
    captureAmount: number,
    card: CardMethod,
    cardAuthenticationVerificationCode: string,
    cardCodeVerificationCode: string,
    creationMoment: string,
    currency: string,
    customerId: string,
    gatewayId: string,
    id: string,
    message: string,
    modificationMoment: string,
    paymentStatus: string,
    paymentType: string,
    surchargeAmount: number,
    surchargePercentage: number,
    transactionFeeId: string,
  }
}

export type Location = {
  id: string,
  contact: Contact,
  receiptEmail?: string,
  /*
  timeZoneOffset: string,
  dayStartTime?: string,
  dayEndTime?: string,
  */
}

export type LocationRequest = Pick<Location, 'contact' | 'receiptEmail'>

export type GatewayConfig = {
  gatewayId: string,
  locationIds: string[],
}

export enum CustomerType {
  INDIVIDUAL = 'Individual',
  COMPANY = 'Company'
}
/*
 * InterPayments Inc. ("COMPANY") CONFIDENTIAL
 * Unpublished Copyright © 2023 InterPayments Inc., All Rights Reserved.
 *
 * https://interpayments.com/copyright-policy/
 *
 * NOTICE: All information contained herein is, and remains the property of
 * COMPANY. The intellectual and technical concepts contained herein are
 * proprietary to COMPANY and may be covered by U.S. and Foreign Patents, patents
 * in process, and are protected by trade secret or copyright law. Dissemination
 * of this information or reproduction of this material is strictly forbidden
 * unless prior written permission is obtained from COMPANY. Access to the source
 * code contained herein is hereby forbidden to anyone except current COMPANY
 * employees, managers or contractors who have executed Confidentiality and
 * Non-disclosure agreements explicitly covering such access.
 *
 * The copyright notice above does not evidence any actual or intended publication
 * or disclosure of this source code, which includes information that is
 * confidential and/or proprietary, and is a trade secret, of COMPANY. ANY
 * REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY
 * OF OR THROUGH USE OF THIS SOURCE CODE WITHOUT THE EXPRESS WRITTEN CONSENT
 * OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 * INTERNATIONAL TREATIES. THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR
 * RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE
 * OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 * MAY DESCRIBE, IN WHOLE OR IN PART.
 *
 */

import React from 'react'
import { Col, Typography } from 'antd'
import { useHistory } from 'react-router-dom'

export const MultilineParagraph: React.FC<React.PropsWithChildren<any>> = ({content}) => {
  const data = (Array.isArray(content) ? content : [content]).map((d, i) => <Typography.Paragraph key={i}>{d}</Typography.Paragraph>)
  return (<>{data}</>)
}

type BulletedList_Props = {
  content: any[],
}

export const BulletedList: React.FC<React.PropsWithChildren<BulletedList_Props>> = ({ content }) => <ul>
  {content.map((d: any, i: number) => <li key={i}>{d}</li>)}
</ul>

type LinkComponent_Props = {
  to: string,
}

type Link_Props = LinkComponent_Props & {
  isAnchor?: boolean,
}

const AnchorLink: React.FC<React.PropsWithChildren<LinkComponent_Props>> = ({ to, children }) => {
  return <a href={to} target='_blank'>
    {children}
  </a>
}

const HistoryLink: React.FC<React.PropsWithChildren<LinkComponent_Props>> = ({ to, children }) => {
  const history = useHistory()
  return <div onClick={() => history.push(to)}>
    {children}
  </div>
}

export const Link: React.FC<React.PropsWithChildren<Link_Props>> = ({ to, isAnchor = false, children }) => {
  const LinkComponent = isAnchor ? AnchorLink : HistoryLink
  return <LinkComponent to={to}>{children}</LinkComponent>
}

type ResponsiveColumn_Props = {
  columns: number,
  width: number,
}

export const ResponsiveColumn: React.FC<React.PropsWithChildren<ResponsiveColumn_Props>> = ({ children, columns, width }) => {
  const columnProps = {
    xs: 24,
    lg: Math.floor(24 * width / columns),
  }
  return <Col {...columnProps}>
    {children}
  </Col>
}
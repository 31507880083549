import { BankOutlined, ContactsOutlined, DollarCircleOutlined, ShopOutlined, TeamOutlined, UserOutlined } from "@ant-design/icons"
import { Avatar, Divider, Layout, Menu, Typography } from "antd"
import { Content } from "antd/es/layout/layout"
import Sider from "antd/es/layout/Sider"
import { ItemType } from "antd/es/menu/interface"
import { getMerchantDisplayName, Merchant } from "models/models"
import { useCallback, useEffect, useMemo } from "react"
import { Redirect, Route, Switch, useHistory, useParams, useRouteMatch } from "react-router-dom"
import { PaylinkVirtualTerminalView } from "../PaylinkVirtualTerminalView/PaylinkVirtualTerminalView"
import Loading from "components/Loading/Loading"
import { PaylinkMerchantContextProvider, usePaylinkMerchantContext } from "./PaylinkMerchantContext"
import { PaymentsInner } from "./inner/Payments"
import { PaymentView } from "./inner/Payment"
import { GatewaysInner } from "./inner/Gateways"
import { CustomersInner } from "./inner/Customers"
import { CustomerView } from "./inner/Customer"
import { UsersInner } from "./inner/Users"
import { LocationsInner } from "./inner/Locations"
import { PaylinkCreateHostedPaymentView } from "../PaylinkVirtualTerminalView/PaylinkCreateHostedPaymentView"
import { UseHostedPaymentPage } from "App"

const { Text } = Typography


const PaylinkMerchantSidebar = ({ merchant }: {merchant: Merchant}) => {
  const history = useHistory()
  const match = useRouteMatch()

  useEffect(() => {
    console.log(window.location.pathname)
  }, [window.location.pathname])

  const selectedMenuItems = useMemo<MenuKey[]>(() => {
    return [MenuKey.PAYMENTS, MenuKey.GATEWAYS, MenuKey.LOCATIONS, MenuKey.CUSTOMERS, MenuKey.USERS].filter(k => window.location.pathname.includes(k))
  }, [window.location.pathname])

  const menuItems: (ItemType)[] = [
    {key: MenuKey.PAYMENTS, label: <Text strong>Payments</Text>, icon: <DollarCircleOutlined />},
    {key: MenuKey.GATEWAYS, label: <Text strong>Gateways</Text>, icon: <BankOutlined />},
    {key: MenuKey.LOCATIONS, label: <Text strong>Locations</Text>, icon: <ShopOutlined />},
    {key: MenuKey.CUSTOMERS, label: <Text strong>Customers</Text>, icon: <ContactsOutlined />},
    {key: MenuKey.USERS, label: <Text strong>Users</Text>, icon: <UserOutlined />}
  ]

  if (!merchant) return <></>
  return <>
    <div style={{padding: '0.5em', display: 'flex', flexFlow: 'row', alignItems: 'center'}}>
      <Avatar size={48} shape='square' icon={<TeamOutlined />} />
      <div style={{marginInlineStart: '0.5em'}}>
        <Text strong style={{fontSize: '1em'}}>{getMerchantDisplayName(merchant)}</Text>
        <br />
        <Text type='secondary' style={{fontSize: '0.75em'}}>{merchant.id}</Text>
      </div>
    </div>
    <Divider style={{marginBlock: '0.5em'}} />
    <Menu
      mode='inline'
      items={menuItems}
      onClick={info => history.push(`/pl/${merchant.id!}/${info.key}`)}
      selectedKeys={selectedMenuItems}
    />

  </>
}

export enum MenuKey {
  PAYMENTS = 'payments',
  GATEWAYS = 'gateways',
  LOCATIONS = 'locations',
  CUSTOMERS = 'customers',
  USERS = 'users'
}

const PaylinkMerchantViewRoot = () => {
  const { mid } = useParams<{mid: string}>()
  return <Redirect to={`/pl/${mid}/payments`} />
}

export const PaylinkMerchantView = () => {
  const { mid } = useParams<{mid: string}>()

  const { merchant, reloadAll } = usePaylinkMerchantContext()

  const refresh = useCallback(() => {
    return reloadAll(mid)
  }, [reloadAll])

  useEffect(() => {
    refresh()
  }, [mid])

  if (!merchant) return <Loading />
  return <>
    <PaylinkMerchantContextProvider>
      <Layout style={{minHeight: '100%'}}>
        <Sider theme="light" width={360} style={{minHeight: '100%'}}>
          <PaylinkMerchantSidebar merchant={merchant} />
        </Sider>
        <Content className='main-content'>
          <Switch>
            <Route path='/pl/:mid' component={PaylinkMerchantViewRoot} exact />
            <Route path={`/pl/:mid/${MenuKey.PAYMENTS}`} component={() => <PaymentsInner />} exact />
            <Route path={`/pl/:mid/${MenuKey.PAYMENTS}/new`} component={() => <PaylinkVirtualTerminalView merchant={merchant!} />} exact />
            {...(UseHostedPaymentPage ? [<Route path={`/pl/:mid/${MenuKey.PAYMENTS}/host`} component={() => <PaylinkCreateHostedPaymentView />} exact />] : [])}
            <Route path={`/pl/:mid/${MenuKey.PAYMENTS}/:pid`} component={() => <PaymentView />} />
            <Route path={`/pl/:mid/${MenuKey.GATEWAYS}`} component={() => <GatewaysInner />} exact />
            <Route path={`/pl/:mid/${MenuKey.LOCATIONS}`} component={() => <LocationsInner />} exact />
            <Route path={`/pl/:mid/${MenuKey.CUSTOMERS}`} component={() => <CustomersInner />} exact />
            <Route path={`/pl/:mid/${MenuKey.CUSTOMERS}/:cid`} component={() => <CustomerView />} />
            <Route path={`/pl/:mid/${MenuKey.USERS}`} component={() => <UsersInner onRefresh={refresh} />} exact />
          </Switch>
        </Content>
      </Layout>
    </PaylinkMerchantContextProvider>
  </>
}
import { gold, green, red } from "@ant-design/colors"
import { Typography } from "antd"
import dayjs, { Dayjs } from "dayjs"
import { Contact } from "./models"

const { Text } = Typography

// Models for Audit API read path

export enum Scope {
  MERCHANT = "merchant",
  COLLECTIVE = "collective",
  DEFAULT = "default"
}

export enum ComplianceStatus {
  COMPLIANT = "COMPLIANT",
  //UNDER_REVIEW = "UNDER_REVIEW",
  NONCOMPLIANT = "NON_COMPLIANT",
}

export type AnnotatedComplianceStatus = {
  status: ComplianceStatus | null,
  isOverride: boolean,
  overrideReason?: string;  // Optional property to hold the reason for the override
  complianceInfo: AuditComplianceInfoResponse,
}

export const makeAnnotatedComplianceStatus = (merchant: AuditMerchantResponse): AnnotatedComplianceStatus => 
  ({status: merchant.complianceStatus,
    isOverride: merchant.complianceStatusOverride.value !== null,
    overrideReason: merchant.complianceStatusOverrideNote.value || undefined,
    complianceInfo: merchant.complianceInfo
  })

export type AuditInfo = {
  user: string,
  date: string,
}

export type Nullable<T> = T | null

export type AuditedValue<T> = {
  value: T | null,
  auditInfo: AuditInfo,
}

type AuditAddressResponse = {
  street: AuditedValue<string>,
  city: AuditedValue<string>,
  region: AuditedValue<string>,
  postalCode: AuditedValue<string>,
  country: AuditedValue<string>,
}

type AuditProcessorContactResponse = {
  name: AuditedValue<string>,
  email: AuditedValue<string>
}

export type AuditMerchantInfoResponse = {
  merchantName: AuditedValue<string>,
  merchantPrimaryContact: AuditedValue<string>,
  merchantPrimaryContactEmail: AuditedValue<string>,
  merchantPrimaryContactPhone: AuditedValue<string>,
  merchantPrimaryContactPhysicalAddress: AuditAddressResponse,
  merchantPrimaryContactTitle: AuditedValue<string>,
  merchantJurisdiction: AuditedValue<string>,
  surchargeType: AuditedValue<string>,
  rules: AuditedValue<string>,
  collectiveRules?: AuditedValue<string>
}

type AuditPaymentServiceProviderInfoResponse = {
  acquirer: AuditedValue<string>,
  primaryAcquirerContact: AuditProcessorContactResponse,
}

type AuditCardBrandNoticeResponse = {
  masterCardNotifiedDate: AuditedValue<string>,
  acquirerNotifiedDate: AuditedValue<string>,
  eligibleSurchargeDate: AuditedValue<string>,
  surchargeLaunchDate: AuditedValue<string>,
  confirmedCompliantSurchargeLaunchDate: AuditedValue<string>,
  limitedAcceptanceNotificationDate: AuditedValue<string>,
  limitedAcceptanceNotificationRequired: boolean
}

type AuditSurchargeDisclosureResponse = {
  merchantLiabilityWaiverDate: AuditedValue<string>,
  eCommerceCertificationDate: AuditedValue<string>,
  trainingProvidedDate: AuditedValue<string>,
  retailPlacardsSubmittedDate: AuditedValue<string>,
  surchargeLineItemCertificationDate: AuditedValue<string>,
}

type AuditRegionalComplianceResponse = {
  surchargeRateType: AuditedValue<string>,
  coloradoSurchargingType: AuditedValue<string>,
  newYorkSurchargingType: AuditedValue<string>,
  collectiveColoradoSurchargingType?: AuditedValue<string>,
  collectiveNewYorkSurchargingType?: AuditedValue<string>,
  crossBorderAllowed: AuditedValue<boolean>,
  collectiveCrossBorderAllowed?: AuditedValue<boolean>,
  prohibitedStates: AuditedValue<string[]>,
  collectiveProhibitedStates?: AuditedValue<string[]>,
}

type AuditComplianceInfoResponse = {
  cardBrandNotices: AuditCardBrandNoticeResponse,
  surchargeDisclosures: AuditSurchargeDisclosureResponse,
  regionalCompliance: AuditRegionalComplianceResponse
}

export type AuditProcessorResponse = {
  name: AuditedValue<string>,
  mid: AuditedValue<string>,
  americanExpressProgram: AuditedValue<string>,
  paymentGateway: AuditedValue<string>,
  processingScheme: AuditedValue<string>,
  limitedAcceptance: AuditedValue<boolean>
  }

export type AuditMerchantResponse = {
  ts: string,
  mid: string,
  complianceStatus: ComplianceStatus,
  complianceStatusOverride: AuditedValue<ComplianceStatus>,
  complianceStatusOverrideNote: AuditedValue<string>,
  surchargeStatus: AuditedValue<string>,
  merchantInfo: AuditMerchantInfoResponse,
  paymentServiceProviderInfo: AuditPaymentServiceProviderInfoResponse,
  complianceInfo: AuditComplianceInfoResponse,
  processors: AuditProcessorResponse[],
}

export type AuditTransactionResponse = {
  ts: string,
  transactionId: string,
  merchantId: string,
  complianceStatus: AuditedValue<ComplianceStatus>,
  nonCompliantFields: AuditedValue<AuditTransactionComplianceFacet[]>
}

export enum AuditTransactionComplianceFacet {
  SURCHARGE = 'surcharge',
  KIND = 'kind',
  REGION = 'region',
}

export type AuditTransactionWriteRequest = {
  transactionId: string,
  merchantId: string,
  complianceStatus: ComplianceStatus | null,
  nonCompliantFields: AuditTransactionComplianceFacet[]
}

// Models for writing to Merchant API

export enum SurchargeDisclosureType {
  MerchantLiabilityWaiverDate = 'merchantLiabilityWaiverDate',
  ECommerceCertificationDate = 'eCommerceCertificationDate',
  TrainingProvidedDate = 'trainingProvidedDate',
  RetailPlacardsSubmittedDate = 'retailPlacardsSubmittedDate',
  SurchargeLineItemCertificationDate = 'surchargeLineItemCertificationDate',
}

export enum CardBrandNoticeType {
  MasterCardNotifiedDate = 'masterCardNotifiedDate',
  AcquirerNotifiedDate = 'acquirerNotifiedDate',
  SurchargeLaunchDate = 'surchargeLaunchDate',
  ConfirmedCompliantSurchargeLaunchDate = 'confirmedCompliantSurchargeLaunchDate',
  LimitedAcceptanceNotificationDate = 'limitedAcceptanceNotificationDate',
}

export const getPrimaryContact = (audit: AuditMerchantResponse): Contact => {
  const { merchantInfo: info } = audit
  const name = info.merchantPrimaryContact.value
  const email = info.merchantPrimaryContactEmail.value
  const phone = info.merchantPrimaryContactPhone.value
  const title = info.merchantPrimaryContactTitle.value
  const { merchantPrimaryContactPhysicalAddress: address } = info
  const street = address.street.value
  const city = address.city.value
  const region = address.region.value
  const postalCode = address.postalCode.value
  const country = address.country.value
  return { name, address: { street, city, state: region, zip: postalCode, country }, email, phone, title } as Contact
}

export const getAcquirerInformation = (audit: AuditMerchantResponse): {name: string, contactName: string, contactEmail: string} => {
  const { paymentServiceProviderInfo: info } = audit
  const name = info.acquirer.value || ''
  const contactName = info.primaryAcquirerContact.name.value || ''
  const contactEmail = info.primaryAcquirerContact.email.value || ''
  return { name, contactName, contactEmail }
}

// Other models

export type OpaqueConfig<T extends unknown> = {
  collective?: T,
  merchant?: T,
  orElse: T
}

export const OpaqueConfig = <T extends unknown>({ collective, merchant, orElse }: { collective?: AuditedValue<T>, merchant?: AuditedValue<T>, orElse: T }): OpaqueConfig<T> => ({ collective: collective?.value ?? undefined, merchant: merchant?.value ?? undefined, orElse })

export type AuditTransaction = {
  id: string,
  amount: number,
  surcharge: number,
  completedAt: Dayjs,
  cardType: string,
  region: string,

  surchargeStatus: ComplianceStatus,
  cardTypeStatus: ComplianceStatus,
  regionStatus: ComplianceStatus,
}

type HumanizeOptions = {
  showDay?: boolean,
  showDate?: boolean,
  showTime?: boolean
}

export const humanize = (c:string | Dayjs, options: HumanizeOptions = {}) => {
  const showDay = Object.hasOwn(options, 'showDay') ? options.showDay : true
  const showDate = Object.hasOwn(options, 'showDate') ? options.showDate : true
  const showTime = Object.hasOwn(options, 'showTime') ? options.showTime : true
  let then = dayjs(c)
  return then.format(`${showDay ? 'ddd' : ''}${showDay && showDate ? ', ' : ''}${showDate ? 'MMM Do, YYYY' : ''}${showDate && showTime ? ', ' : ''}${showTime ? 'h:mm:ss a' : ''}`)
}

export const getOrElse = <T extends unknown>(orElse: T) => (value: T | null | undefined) => !!value ? value : orElse
import { blue } from "@ant-design/colors"
import { ApiOutlined, CreditCardOutlined, DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons"
import { Button, Card, Col, message, Popconfirm, Result, Row, Skeleton, Space, Spin, Tooltip, Typography } from "antd"
import { FancyCard } from "components/Card"
import { GatewayForm } from "components/paylink/GatewayForm"
import { usePaylinkAPI } from "hooks/paylink/usePaylinkAPI"
import { Merchant } from "models/models"
import { Gateway, GatewayRequest } from "models/paylink"
import { useCallback, useEffect, useMemo, useState } from "react"
import { usePaylinkMerchantContext } from "../PaylinkMerchantContext"

const { Text, Title } = Typography

const NewGatewayCard = ({merchant, onRefresh}: {merchant: Merchant, onRefresh: () => Promise<void>}) => {
  const [ loading, setLoading ] = useState<boolean>(false)
  const [ showForm, setShowForm ] = useState<boolean>(false)

  const { createGateway } = usePaylinkAPI()

  const handleSubmit = useCallback((request: GatewayRequest) => {
    setLoading(true)
    message.info({content: <Text>Creating a new <Text strong>{request.gateway.gatewayType}</Text> gateway <Text type='secondary'>({request.gateway.name})</Text></Text>, key: 'createGateway'})
    createGateway(merchant.id!, request)
      .then(onRefresh)
      .then(_ => {
        message.success({content: <Text>Gateway <Text strong>{request.gateway.name}</Text> was created successfully!</Text>, key: 'createGateway'})
        setLoading(false)
        setShowForm(false)
      })
      .catch(e => {
        message.error({content: <Text>Gateway <Text strong>{request.gateway.name}</Text> could not be created</Text>, key: 'createGateway'})
        setLoading(false)
      })
  }, [merchant])

  if (showForm) return <Spin spinning={loading}>
    <Card>
      <GatewayForm merchant={merchant} onSubmit={handleSubmit} onCancel={() => setShowForm(false)} requireCredentials={true} />
    </Card>
  </Spin>

  return <FancyCard style={{height: '100%', position: 'relative', overflow: 'hidden'}} icon={<PlusOutlined style={{color: blue[0]}} />} hoverable onClick={() => setShowForm(true)}>
    <Title level={4} style={{color: blue.primary, position: 'absolute', top: '50%', transform: 'translateY(-50%)'}}>New Gateway</Title>
  </FancyCard>
}

const GatewayLoadingCard = () => {
  return <Card loading={true}>
    <div style={{marginBlockEnd: '1em'}}>
      <Skeleton><Title level={4} style={{marginBlockEnd: '-0.25em'}}>Test Gateway</Title></Skeleton>
      <Skeleton><Text type='secondary' style={{fontSize: '0.75em'}}>abcdefgh-abcd-abcd-abcd-abcdefghijkl</Text></Skeleton>
    </div>
  </Card>
}

const GatewayCard = ({gateway, merchant, onRefresh}: {gateway: Gateway, merchant: Merchant, onRefresh: () => Promise<void>}) => {
  const [ showForm, setShowForm ] = useState<boolean>(false)
  const [ loading, setLoading ] = useState<boolean>(false)

  const { updateGateway } = usePaylinkAPI()

  const handleUpdate = useCallback((request: GatewayRequest) => {
    if (!merchant || !gateway) return
    setLoading(true)
    message.info({content: <Text>Updating gateway <Text strong>{gateway.name}</Text></Text>, key: 'updateGateway'})
    updateGateway(merchant.id!, gateway.id, {...request, gateway: {...request.gateway, disabled: false}} as any)
      .then(onRefresh)
      .then(_ => {
        message.success({content: <Text>Gateway <Text strong>{gateway.name}</Text> updated successfully!</Text>, key: 'updateGateway'})
        setLoading(false)
        setShowForm(false)
      })
      .catch(e => {
        message.error({content: <Text>Gateway <Text strong>{gateway.name}</Text> could not be updated</Text>, key: 'updateGateway'})
        console.error(`[GatewayCard] handleUpdate was unsuccessful`)
        setLoading(false)
      })
  }, [merchant, gateway])

  const handleDelete = useCallback(() => {
    if (!merchant || !gateway) return
    setLoading(true)
    message.info({content: <Text>Deleting gateway <Text strong>{gateway.name}</Text></Text>, key: 'deleteGateway'})
    updateGateway(merchant.id!, gateway.id!, {gateway: {...gateway, disabled: true}} as any)
      .then(onRefresh)
      .then(_ => {
        message.success({content: <Text>Gateway <Text strong>{gateway.name}</Text> deleted successfully!</Text>, key: 'deleteGateway'})
        setLoading(false)
      })
      .catch(e => {
        message.error({content: <Text>Gateway <Text strong>{gateway.name}</Text> could not be deleted</Text>, key: 'deleteGateway'})
        console.error(`[GatewayCard] handleDelete was unsuccessful`)
        setLoading(false)
      })
  }, [merchant, gateway])

  if (showForm) return <Spin spinning={loading}>
    <Card>
      <GatewayForm merchant={merchant} initialValues={gateway} onSubmit={handleUpdate} onCancel={() => setShowForm(false)} requireCredentials={false} />
    </Card>
  </Spin>
  return <Card
    title={<div style={{marginBlock: '1em'}}>
      <Title level={4} style={{marginBlockEnd: '-0.25em'}}>{gateway.name}</Title>
      <Text type='secondary' style={{fontSize: '0.75em'}}>{gateway.id}</Text>
    </div>}
    extra={<Space size='middle'>
      <Button type='primary' icon={<EditOutlined />} onClick={() => setShowForm(true)}>Edit</Button>
      <Popconfirm
        title={<Text>Are you sure you want to delete gateway <Text strong>{gateway.name}</Text>?</Text>}
        onConfirm={() => handleDelete()}
      >
        <Button danger icon={<DeleteOutlined />}>Delete</Button>
      </Popconfirm>
    </Space>}
  >
    <div style={{display: 'grid', gridTemplateColumns: 'auto 1fr', gap: '1.5em'}}>
      <Text type='secondary'><ApiOutlined style={{marginInlineEnd: '0.5em'}} />Gateway</Text>
      <div>{gateway.gatewayType}</div>

      <Tooltip title='The surcharging processor associated with this gateway'><Text type='secondary'><CreditCardOutlined style={{marginInlineEnd: '0.5em'}} />Processor</Text></Tooltip>
      <div>{gateway.processor}</div>
    </div>
  </Card>
}

export const GatewaysInner = () => {
  const { merchant, gateways, reloadGateways } = usePaylinkMerchantContext()
  const mid = merchant!.id!

  const loading = useMemo(() => gateways === undefined, [gateways])

  const refresh = useCallback(() => {
    return reloadGateways(mid)
  }, [mid])

  if (gateways === undefined) return <></>
  return <>
    <Row gutter={[32, 32]}>
      <Col span={24}>
        <Result
          title={<Title level={2}>Gateways</Title>}
          icon={<ApiOutlined />}
        />
      </Col>

      <Col span={24}>
        <NewGatewayCard merchant={merchant!} onRefresh={refresh} />
      </Col>

      {loading && <>
        <Col span={24}>
          <GatewayLoadingCard />
        </Col>
      </>}

      {!loading && gateways.map(g => <Col span={24} key={g.id}>
        <GatewayCard key={g.id} merchant={merchant!} gateway={g} onRefresh={refresh} />
      </Col>)}
    </Row>
  </>
}

import { useEffect } from "react"
import { usePaylinkAPI } from "./usePaylinkAPI"

export const usePaylinkExternalDependencies = () => {
  const { iFrameLibrarySrc, iFrameStyleSrc } = usePaylinkAPI()

  useEffect(() => {
    if (!iFrameStyleSrc || !iFrameLibrarySrc) return

    const cssElement = document.createElement('link')
    cssElement.setAttribute('rel', 'stylesheet')
    cssElement.setAttribute('type', 'text/css')
    cssElement.setAttribute('href', iFrameStyleSrc)
    document.head.appendChild(cssElement)

    const scriptElement = document.createElement('script')
    scriptElement.setAttribute('src', iFrameLibrarySrc)
    document.body.appendChild(scriptElement)

    return () => {
      cssElement.remove()
      scriptElement.remove()
    }
  }, [iFrameLibrarySrc, iFrameStyleSrc])
}
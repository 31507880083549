import { useState, useCallback, useEffect, useMemo } from "react"
import { useHistory } from "react-router-dom"
import { Row, Col, Result, Typography, Button, Space, Spin, Table, Tag } from "antd"
import { blue } from "@ant-design/colors"
import { BookOutlined, DollarCircleOutlined, ExportOutlined, IdcardOutlined, PlusOutlined } from "@ant-design/icons"

import { usePaylinkAPI } from "hooks/paylink/usePaylinkAPI"
import { Payment, PaymentStatus, PaymentType } from "models/paylink"
import { usePaylinkMerchantContext } from "../PaylinkMerchantContext"
import { FancyCard } from "components/Card"
import { ColumnsType } from "antd/es/table"
import dayjs from "dayjs"
import FileSaver from "file-saver"
import { useAuthorization } from "hooks/useAuthorization"
import { useTableSearch } from "hooks/useTableSearch"
import { humanize } from "models/audit"
import { MenuKey } from "../PaylinkMerchantView"
import { UserRole } from "models/models"
import { UseHostedPaymentPage } from "App"

const { Text, Title } = Typography

const NewPaymentCard = ({mid}: {mid: string}) => {
  const history = useHistory()

  return <FancyCard style={{height: '100%', position: 'relative', overflow: 'hidden'}} icon={<PlusOutlined style={{color: blue[0]}} />} hoverable onClick={() => history.push(`/pl/${mid}/${MenuKey.PAYMENTS}/new`)}>
    <Title level={4} style={{color: blue.primary, position: 'absolute', top: '50%', transform: 'translateY(-50%)'}}>New Payment</Title>
  </FancyCard>
}

const NewHostedPaymentCard = ({mid}: {mid: string}) => {
  const history = useHistory()

  return <FancyCard style={{height: '100%', position: 'relative', overflow: 'hidden'}} icon={<IdcardOutlined style={{color: blue[0]}} />} hoverable onClick={() => history.push(`/pl/${mid}/${MenuKey.PAYMENTS}/host`)}>
    <Title level={4} style={{color: blue.primary, position: 'absolute', top: '50%', transform: 'translateY(-50%)'}}>New Hosted Payment</Title>
  </FancyCard>
}

const paymentCsvColumns: {title: string, getValue: (record: Payment) => string}[] = [
  {title: 'ID', getValue: record => record.id},
  {title: 'Invoice Numbers', getValue: record => (record.invoiceNumbers || []).join(';')},
  {title: 'Purchase Order Numbers', getValue: record => (record.purchaseOrderNumbers || []).join(';')}
]

const PaymentTable = ({mid, payments, loading}: {mid: string, payments: Payment[], loading: boolean}) => {
  const history = useHistory()
  const { isAuthorized } = useAuthorization('*', ['admin'])

  const { getSearchProps } = useTableSearch<Payment>()

  const [ filteredData, setFilteredData ] = useState<Payment[]>(payments)

  const columns: ColumnsType<Payment> = useMemo(() => [
    {
      title: 'ID',
      key: 'id',
      render: (_, payment) => {
        return <>
          {(!!payment.invoiceNumbers && !!payment.invoiceNumbers.length) ? <>
            <Text strong>Invoice: </Text><Text>{(payment.invoiceNumbers || []).map((n, i) => <Tag key={i}>{n}</Tag>)}</Text>
            <br />
          </> : <></>}
          {(!!payment.purchaseOrderNumbers && !!payment.purchaseOrderNumbers.length) ? <>
            <Text strong>PO: </Text><Text>{(payment.purchaseOrderNumbers || []).map((n, i) => <Tag key={i}>{n}</Tag>)}</Text>
            <br />
          </> : <></>}
          {isAuthorized('admin') ? <><Text strong>ID: </Text><Tag>{payment.id}</Tag></> : <></>}
        </>
      },
      ...getSearchProps((value, record) => {
        const id = record.id.toLowerCase()
        const invoiceNumbers = (record.invoiceNumbers || []).map(n => n.toLowerCase())
        const purchaseOrderNumbers = (record.purchaseOrderNumbers || []).map(n => n.toLowerCase())
        const searchValue = value.toString().toLowerCase()
        return !![id, ...invoiceNumbers, ...purchaseOrderNumbers].find(v => v.includes(searchValue))
      })
    },
    {
      title: 'Created At',
      key: 'createdAt', 
      render: (_, payment) => <Text>{humanize(payment.creationMoment)}</Text>,
      sorter: (a, b) => dayjs(a.creationMoment).diff(dayjs(b.creationMoment)),
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'Amount',
      key: 'amount',
      render: (_, payment) => <>
        <Text strong>Subtotal: </Text><Text>{(payment.captureAmount / 100).toLocaleString('en-US', {style: 'currency', currency: payment.currency})}</Text>
        <br />
        {!!payment.surchargeAmount ? <>
          <Text strong>Surcharge: </Text><Text>{(payment.surchargeAmount / 100).toLocaleString('en-US', {style: 'currency', currency: payment.currency})}</Text>
          <br />
        </> : <></>}
        <Text strong>Total: </Text><Text>{((payment.captureAmount + (payment.surchargeAmount || 0)) / 100).toLocaleString('en-US', {style: 'currency', currency: payment.currency})}</Text>
      </>
    },
    {
      title: 'Status',
      key: 'status',
      render: (_, payment) => <Text>{payment.paymentStatus}</Text>,
      filters: [
        {
          value: PaymentStatus.CAPTURED,
          text: 'Captured'
        },
        {
          value: PaymentStatus.AUTHORIZED,
          text: 'Authorized'
        },
        {
          value: PaymentStatus.VOIDED,
          text: 'Voided'
        }
      ],
      onFilter: (value, record) => record.paymentStatus === value
    },
    {
      title: 'Type',
      key: 'type',
      render: (_, payment) => <Text>{payment.paymentType}</Text>,
      filters: [
        {
          value: PaymentType.SALE,
          text: 'Sale'
        },
        {
          value: PaymentType.AUTHORIZATION,
          text: 'Authorization'
        }
      ],
      onFilter: (value, record) => record.paymentType === value
    },
    {
      key: 'actions',
      render: (_, payment) => <div style={{textAlign: 'right'}}>
        <Button type='primary' ghost icon={<BookOutlined />} onClick={_ => history.push(`/pl/${mid}/payments/${payment.id}`)} />
      </div>
    }
  ], [isAuthorized])

  const exportToCSV = useCallback(() => {
    console.log(filteredData)
    const titleColumn = paymentCsvColumns.map(c => c.title).join(',')
    const dataColumns = filteredData.map(d => paymentCsvColumns.map(c => c.getValue(d)).join(','))
    const output = [titleColumn, ...dataColumns].join('\r\n')
    const timestamp = dayjs().format('YYMMDDHHmmss')
    FileSaver.saveAs(new Blob([output], {type: "text/plain;charset=utf-8"}), `payments_${timestamp}.csv`)
  }, [filteredData])

  useEffect(() => {
    setFilteredData(payments)
  }, [payments])

  return <Spin spinning={loading}>
    <Table
      onChange={(p, f, s, extra) => setFilteredData(extra.currentDataSource)}
      title={(data) => <div style={{display: 'grid', gridTemplateRows: 'auto', gridTemplateColumns: '1fr auto 1fr', alignItems: 'center'}}>
        <div style={{gridColumn: 2, gridRow: 1, textAlign: 'center'}}>
          <Text strong style={{fontSize: '1rem'}}>Payments</Text>
          <br />
          <Text type='secondary'>Showing {data.length} of {filteredData.length.toString()} {filteredData.length === 1 ? 'payment' : 'payments'}</Text>
        </div>
        <div style={{gridColumn: 3, gridRow: 1, textAlign: 'right'}}>
          <Space size='middle'>
            <Button type={'primary'} icon={<ExportOutlined />} onClick={() => exportToCSV()}>Export</Button>
          </Space>
        </div>
      </div>}
      dataSource={payments}
      columns={columns}
    />
  </Spin>
}

export const PaymentsInner = () => {
  const { isAuthorized } = useAuthorization('*', [UserRole.ADMIN])

  const { merchant, payments, reloadPayments } = usePaylinkMerchantContext()
  const mid = merchant!.id!
  const loading = useMemo(() => payments === undefined, [payments])
  const keyedPayments = (payments || []).map(p => ({...p, key: p.id}))

  const refresh = useCallback(() => {
    return reloadPayments(mid)
  }, [mid])

  return <>
    <Row gutter={[32, 32]}>
      <Col span={24}>
        <Result
          title={<Title level={2}>Payments</Title>}
          icon={<DollarCircleOutlined />}
        />
      </Col>

      <Col span={24}>
        <NewPaymentCard mid={mid} />
      </Col>

      {UseHostedPaymentPage && isAuthorized(UserRole.ADMIN) && <Col span={24}>
        <NewHostedPaymentCard mid={mid} />
      </Col>}

      <Col span={24}>
        <PaymentTable payments={keyedPayments} loading={loading} mid={mid} />
      </Col>
    </Row>
  </>
}

import qs from "qs"
import { useCallback } from "react"
import { useHistory } from "react-router-dom"

type QueryParamMap = {
  [key: string]: string
}

export const useQueryParams = () => {
  const history = useHistory()

  const getAllParams = useCallback(() => {
    if (!history) return {}
    const { search } = window.location
    const hasQueryParams = search.length > 0
    const queryParamString = hasQueryParams ? search.slice(1) : ''
    return qs.parse(queryParamString)
  }, [history])

  const getParamByKey = (key: string) => {
    const allParams = getAllParams()
    return allParams[key]
  }

  const changeParams = (newParams: QueryParamMap) => {
    const updatedParams = {
      ...getAllParams(),
      ...newParams,
    }
    history.push(`${history.location.pathname}?${qs.stringify(updatedParams)}`)
  }

  const setParams = (newParams: QueryParamMap) => {
    history.push(`${history.location.pathname}?${qs.stringify(newParams)}`)
  }

  const clearParams = () => {
    history.push(`${history.location.pathname}`)
  }

  return { getAllParams, getParamByKey, changeParams, setParams, clearParams }
}
/*
 * InterPayments Inc. ("COMPANY") CONFIDENTIAL
 * Unpublished Copyright © 2023 InterPayments Inc., All Rights Reserved.
 *
 * https://interpayments.com/copyright-policy/
 *
 * NOTICE: All information contained herein is, and remains the property of
 * COMPANY. The intellectual and technical concepts contained herein are
 * proprietary to COMPANY and may be covered by U.S. and Foreign Patents, patents
 * in process, and are protected by trade secret or copyright law. Dissemination
 * of this information or reproduction of this material is strictly forbidden
 * unless prior written permission is obtained from COMPANY. Access to the source
 * code contained herein is hereby forbidden to anyone except current COMPANY
 * employees, managers or contractors who have executed Confidentiality and
 * Non-disclosure agreements explicitly covering such access.
 *
 * The copyright notice above does not evidence any actual or intended publication
 * or disclosure of this source code, which includes information that is
 * confidential and/or proprietary, and is a trade secret, of COMPANY. ANY
 * REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY
 * OF OR THROUGH USE OF THIS SOURCE CODE WITHOUT THE EXPRESS WRITTEN CONSENT
 * OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 * INTERNATIONAL TREATIES. THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR
 * RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE
 * OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 * MAY DESCRIBE, IN WHOLE OR IN PART.
 *
 */

import React, {createContext, FunctionComponent, useContext, useEffect, useState} from "react"
import {useDispatch} from "react-redux"
import {Dispatch} from "redux"

import {useAuth0} from "@auth0/auth0-react"
import {SseAddMessage, SseSetStatus} from "../../reducers/status-state/actions"

import { env2 } from "../../utils/env2"
import {Button, notification} from 'antd'
import {useSupportAPI} from "../../hooks/useSupportAPI";
import {guid} from "../../models/models";
import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

const EnableSSE = env2("REACT_APP_DISABLE_SSE") !== "true"
const DebugSSE = env2("REACT_APP_DEBUG_SSE") === "true"
const BaseUrl = env2("REACT_APP_BASE_URL")
const timeoutAfter = env2("REACT_APP_EVENTSOURCE_TIMEOUT_S")

type SseContext_Shape = {
  stop: () => void,
}

const initialSseContext: SseContext_Shape = {
  stop: () => undefined,
}

const SseContext = createContext<SseContext_Shape>(initialSseContext)

const debugLog = (message?: any, ...optionalParams: any[]) => {
  if (DebugSSE) {
    console.log(`[sse-provider] ${message}`, ...optionalParams)
  }
}

type OwnProps = object

type Props = OwnProps

const SseProviderFC: FunctionComponent<React.PropsWithChildren<Props>> = ({ children }) => {

  const { getIdTokenClaims, logout } = useAuth0()

  const [idToken, setIdToken] = useState<string | null>(null)
  const [clientTicket, setClientTicket] = useState<string>(guid())
  const [ticket, setTicket] = useState<string | undefined>()
  const [lastTicketStartTime, setLastTicketStartTime] = useState<Dayjs>(dayjs())

  const { initiateSse, validateSse } = useSupportAPI()

  const dispatch = useDispatch()

  const report = (m: string) => (e: any) => {
    debugLog(`EVENT ${m}`, e)
  }

  const [ singletonEventSource, setSingletonEventSource ] = useState<EventSource | null>(null)

  const fireUpSse = (idToken: string,
                     dispatch: Dispatch<any>) => {
    // const expiredToken = "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6InNtMDRaT09pRjU1YU00YXpnQndTMyJ9.eyJodHRwczovL3BvcnRhbC5pbnRlcnBheW1lbnRzLmNvbS9hZG1pbiI6ZmFsc2UsImh0dHBzOi8vaW50ZXJwYXltZW50cy5jb20vYXBwX21ldGFkYXRhIjp7InJvbGVzIjpbImFkbWluIiwiaW50ZXJwYXltZW50cy1hZG1pbiJdfSwiaHR0cHM6Ly9pbnRlcnBheW1lbnRzLmNvbS91c2VyX21ldGFkYXRhIjp7fSwiaHR0cHM6Ly9pbnRlcnBheW1lbnRzLmNvbS9jcmVhdGVkX2F0IjoiMjAyMC0xMS0wNlQwNDoxMDo0Mi40MzRaIiwiZ2l2ZW5fbmFtZSI6IkJyYWQiLCJmYW1pbHlfbmFtZSI6IlJ1c3QiLCJuaWNrbmFtZSI6ImJyYWQiLCJuYW1lIjoiQnJhZCBSdXN0IiwicGljdHVyZSI6Imh0dHBzOi8vbGgzLmdvb2dsZXVzZXJjb250ZW50LmNvbS9hLS9BT2gxNEdqNVpGMUlBREY0amplUFBZYVFhcktlVU1aYl83LUpaOExqckRvSz1zOTYtYyIsImxvY2FsZSI6ImVuIiwidXBkYXRlZF9hdCI6IjIwMjAtMTItMTRUMTY6NTM6MTIuMDg5WiIsImVtYWlsIjoiYnJhZEBpbnRlcnBheW1lbnRzLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJpc3MiOiJodHRwczovL2ludGVycGF5bWVudHMtc2FuZGJveC51cy5hdXRoMC5jb20vIiwic3ViIjoiZ29vZ2xlLW9hdXRoMnwxMDUyNjYxNjk0NDk1MjkzNzQ4NjEiLCJhdWQiOiJLdDJBTU9KMzV4S3ZDN2VOV0RRZ3RFemFNcTFFRUZSYyIsImlhdCI6MTYwNzk4OTUzMiwiZXhwIjoxNjA4MDI1NTMyLCJub25jZSI6IldGTjRZa2h3WTFWV2JHWnRlRUpGTVRaaFJscGFXR1pYYWt4TUxXcHVOVVpaY2xwa09FVk1XWGxPUVE9PSJ9.nXX-HogEmrX-E8Fvf0ROV9_tOLOXAhxvDvuWjAJiXinW7QPhfe5oRZS6YZWmdlF8M5cq66EbH-LZM5t9NQYuu9OpIwNdt0MlfOP_tmh4e4ZzPG_dQzjDapio9thj2EFAPsaqpjS76mqMSq6-CczZgAhS6aFXmpwVqrmFoCDmMAmJ4nBR7F6gaf3t9yXoXzReR9zu434L8MDJQQOY-j2VbBMvELJusnla3QJpc5JomI4gjcWSEzpdI6jqHCIKudySLcsw5sXETnWjs3qBoTxcxKDcZhmkJ2s_c4xVAfwZUbka7Q-bSBKs0BBXaDf6oME5d82s_ts2Ltwz-MeQfRmtag"
    const url = `${BaseUrl}/v1/support/user/notifications?t=${ticket}&start=${lastTicketStartTime}`
    debugLog("FIRING UP SSE", url)
    if (DebugSSE) {
      dispatch(SseSetStatus("opening"))
    }
    const eventSource = new EventSource(url)
    eventSource.addEventListener("open", (e: any) => {
      report("open")(e)
      switch (e.currentTarget.readyState) {
        case 0:
          if (DebugSSE) {
            dispatch(SseSetStatus("opening"))
          }
          break
        case 1:
          dispatch(SseSetStatus("open"))
          break
        case 2:
          dispatch(SseSetStatus("error"))
          break
      }
      setSingletonEventSource(eventSource)
    })

    eventSource.addEventListener("error", async (e: any) => {
      report("error")(e)
      try {
        const t = await getIdTokenClaims()
        // example expiration ... const t = { "exp": 1608025532 }
        if (t && t.exp) {
          const now = dayjs()
          const expirationDate = t.exp * 1000
          const tokenExpiration = dayjs.utc(expirationDate)
          if (now.isAfter(tokenExpiration)) {
            console.log("token expired,... logging out", tokenExpiration, window.location.origin)
            logout({
              logoutParams: {
                returnTo: window.location.origin,
              }
            })
          } else {
            console.log("token not expired", tokenExpiration.toDate(), now.toDate())
            let stillValid = await validateSse(ticket!)
                .then(r => {
                  console.log("validating sse", r)
                  return r && r.isValid
                })
                .catch((e: any) => {
                  console.log("sse probably invalid", e)
                  Promise.resolve(false)
                })
            if (!stillValid) {
              console.log("sse invalid", stillValid)
              await (async () => {
                // not sure I really need an async here
                setLastTicketStartTime(dayjs())
                getTicket()
              })()
            }
          }

        }
      } catch (error) {
        console.log("token expiration check error", error)
      }
      console.log("error has occurred", e)
    })

    eventSource.onerror = (e: Event) => {
      console.log("An error occurred while attempting to connect.", e)
    }

    //eventSource.addEventListener("COMMENT", (e:any) => {
    //  console.log("COMMENT/KEEP-ALIVE", e)
    //})
    eventSource.addEventListener("USER-NOTIFICATION", (e:any) => {
      dispatch(SseAddMessage({ data: e.data, type: e.type, lastEventId: e.lastEventId }))
    })
    eventSource.addEventListener("BIN-UPDATE", (e:any) => {
      dispatch(SseAddMessage({ data: e.data, type: e.type, lastEventId: e.lastEventId }))
    })
    eventSource.addEventListener("BIN-MINOR-UPDATE", (e:any) => {
      dispatch(SseAddMessage({ data: e.data, type: e.type, lastEventId: e.lastEventId }))
    })
    eventSource.addEventListener("INVOICE", (e:any) => {
      dispatch(SseAddMessage({ data: e.data, type: e.type, lastEventId: e.lastEventId }))
    })
    eventSource.addEventListener("SYNC-ASSET", (e:any) => {
      dispatch(SseAddMessage({ data: e.data, type: e.type, lastEventId: e.lastEventId }))
    })
    eventSource.addEventListener("USER-MESSAGE", (e:any) => {
      let data = JSON.parse(e.data)
      let msg = JSON.parse(data.message)

      if (msg && msg.msg) {

        const close = () => {}
        const key = `open${Date.now()}`

        //
        // way too brittle here but just testing the waters too.   If the server send
        // a USER-MESSAGE with a url and a msg, then display it as a URL to be followed
        // by the User.
        //
        // else, just display a notification to the user
        //
        if (msg.url) {
          const BaseUrl = env2("REACT_APP_BASE_URL")
          const url = `${BaseUrl}${msg.url}`
          const btn = (
            <Button type="primary" size="small" onClick={() => {
              window.open(url, '_blank')
              notification.destroy(key)
            }}>
              Download
            </Button>
          )
          console.log("URL Notification...", url, msg.msg)
          notification.open({
            message: 'URL Notification',
            description: msg.msg,
            duration: 0,
            btn,
            key,
            onClose: close
          });
        } else {
          const btn = (
            <Button type="primary" size="small" onClick={() => {
              notification.destroy(key)
            }}>Ok</Button>
          )
          notification.open({
            message: 'Notification',
            description: msg.msg,
            duration: 0,
            btn,
            key,
            onClose: close
          });
        }
      }
    })
    eventSource.onmessage = (e: any) => debugLog(`onmessage ${JSON.stringify(e)}`)
    return eventSource
  }

  const closeSse = () => {
    debugLog("closing event source", singletonEventSource)
    if (singletonEventSource) {
      dispatch(SseSetStatus('closed'))
      singletonEventSource.close()
      setSingletonEventSource(null)
    }
  }

  useEffect(() => {
    (async () => {
      const token = await getIdTokenClaims()
      // console.log("useEffect to get claims", token)
      if (token && token.__raw !== idToken) {
        console.log("SSE: setIdToken")
        setIdToken(token.__raw)
      } else {
        console.log("SSE: setIdToken null")
        setIdToken(null)
      }
    })()
  }, [getIdTokenClaims])

  const getTicket = () => {
    initiateSse(clientTicket)
      .then(sseAuth => {
        if (sseAuth && sseAuth.ticket) {
          console.log("new ticket", sseAuth.ticket)
          setTicket(sseAuth.ticket)
        }
      })
      .catch((e: any) => Promise.reject('There was a problem fetching an sse ticket'))
  }

  useEffect(() => {
    if (EnableSSE && idToken && !ticket) {
      console.log("SSE: idToken", idToken)
      setLastTicketStartTime(dayjs())
      getTicket()
    }
  }, [dispatch, idToken, ticket])

  useEffect(() => {
    if (EnableSSE && ticket) {
      const es = idToken ? fireUpSse(idToken, dispatch) : null
      setSingletonEventSource(es)
      return () => closeSse()
    }
  }, [ticket])

  useEffect(() => {
    if (timeoutAfter && ticket && idToken && singletonEventSource) {
      const toAfter = +timeoutAfter
      const timeout = setTimeout(async () => {
        try {
          if (singletonEventSource) {
            closeSse()
            const es = fireUpSse(idToken, dispatch)
            setSingletonEventSource(es)
          }
        } catch (error) {
          console.log(error)
        }
      }, toAfter * 1000)
      return () => clearTimeout(timeout)
    } else {
      return
    }
  }, [singletonEventSource, idToken, ticket])

  return <SseContext.Provider value={{stop: closeSse}}>
    {children}
  </SseContext.Provider>
}

export const SseProvider = SseProviderFC
export const useSse = () => useContext(SseContext)
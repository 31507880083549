import dayjs from 'dayjs'
import qs from 'qs'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from "react-router-dom"
import { SetGoogleOAuthToken } from '../reducers/external-auth/actions'

const ValidateGoogleAuthFC: React.FC<React.PropsWithChildren<unknown>> = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    const { hash } = location
    if (hash.charAt(0) !== '#') return

    const { access_token, expires_in, scope, token_type } = qs.parse(hash.slice(1))
    if (token_type === 'Bearer' && !!access_token && !!expires_in) {
      const expireDuration: number = parseInt(expires_in as string)
      const expireDatetime = dayjs().add(expireDuration, 'seconds')

      console.log(expireDatetime)

      dispatch(SetGoogleOAuthToken(access_token as string))
      history.push('/')
    }
  }, [location])

  return (<>
  </>)
}

export const ValidateGoogleAuth = ValidateGoogleAuthFC
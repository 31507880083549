/*
 * InterPayments Inc. ("COMPANY") CONFIDENTIAL
 * Unpublished Copyright © 2023 InterPayments Inc., All Rights Reserved.
 *
 * https://interpayments.com/copyright-policy/
 *
 * NOTICE: All information contained herein is, and remains the property of
 * COMPANY. The intellectual and technical concepts contained herein are
 * proprietary to COMPANY and may be covered by U.S. and Foreign Patents, patents
 * in process, and are protected by trade secret or copyright law. Dissemination
 * of this information or reproduction of this material is strictly forbidden
 * unless prior written permission is obtained from COMPANY. Access to the source
 * code contained herein is hereby forbidden to anyone except current COMPANY
 * employees, managers or contractors who have executed Confidentiality and
 * Non-disclosure agreements explicitly covering such access.
 *
 * The copyright notice above does not evidence any actual or intended publication
 * or disclosure of this source code, which includes information that is
 * confidential and/or proprietary, and is a trade secret, of COMPANY. ANY
 * REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY
 * OF OR THROUGH USE OF THIS SOURCE CODE WITHOUT THE EXPRESS WRITTEN CONSENT
 * OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 * INTERNATIONAL TREATIES. THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR
 * RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE
 * OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 * MAY DESCRIBE, IN WHOLE OR IN PART.
 *
 */

import {AuthenticatedBaseService} from "./base-service"
import {Collective, Merchant, serializeObjectToURLParams, Webhook} from "../models/models"
import {CollectiveMetadata} from "../providers/collective-provider/collective-provider"
import qs from "qs"
import { FilterProps } from "utils/txlogHelper"
export class CollectiveService extends AuthenticatedBaseService {

  async isReady() {
    const h = await this.getAuthHeaders()
    // TODO... sometimes service isn't ready because idToken hasn't been resolved... why???
    // console.log("sometimes service isn't ready because idToken hasn't been resolved... why???")
    // console.log("h ", !!h.headers.authorization, h.headers.authorization)
    return (!!h.headers.authorization)
  }

  async getMyCollectives(): Promise<CollectiveMetadata> {
    console.log("getMyCollectives")
    const url = this.apiUrl(`/v1/collective`)
    const rsp = await fetch(url, {
      cache: "default",
      ...await this.getAuthHeaders()
    })
    let rv = this.checkJsonStatus<Collective[]>(rsp)
    return rv.then((value: Collective[]) => {
      const md: CollectiveMetadata = {
        c: value.pop
      }
      return md
    })
  }

  async getCollectives(): Promise<Array<Collective>> {
    console.log("getMyCollectives")
    const url = this.apiUrl(`/v1/support/collective`)
    const rsp = await fetch(url, {
      cache: "default",
      ...await this.getAuthHeaders()
    })
    let rv = this.checkJsonStatus<Collective[]>(rsp)
    return rv.then((value: Collective[]) => {
      return value
    })
  }

  // TODO Collective API Role.Viewer
  async findLogs(collectiveId: any, skip: any, limit: any, attributes: FilterProps): Promise<any> {
    const params = qs.stringify({
      ...attributes,
      limit: limit,
      skip: skip
    }, { arrayFormat: 'repeat' })
    const url = this.apiUrl(`/v1/collective/${collectiveId}/txlog?${params}`)
    const rsp = await fetch(url, {
      cache: "default",
      ...await this.getAuthHeaders()
    })
    return (attributes.format === "csv" || attributes.format === "csv-remote") ? rsp.text() : this.checkJsonStatus(rsp)
  }

  // TODO Collective API Role.Manager
  async deleteInvite(cid: string, token: string): Promise<any[]> {
    console.log("delete invite")
    const url = this.apiUrl(`/v1/collective/${cid}/user/invite/${token}`)
    const rsp = await fetch(url, {
      method: "DELETE",
      ...await this.getAuthHeaders()
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO Collective API Role.Manager
  async removeUser(cid: string, userId: string, role: string): Promise<any[]> {
    console.log("removeUser")
    const url = this.apiUrl(`/v1/collective/${cid}/user/${userId}/${role}`)
    const rsp = await fetch(url, {
      method: "DELETE",
      ...await this.getAuthHeaders()
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO Collective API Role.Manager
  async inviteUser(cid: string, name: string, email: string, role: string): Promise<any[]> {
    console.log("inviteUser")
    let notEmptyBody = JSON.stringify({
      email,
      name,
      role
    })

    const url = this.apiUrl(`/v1/collective/${cid}/user`)
    const rsp = await fetch(url, {
      method: "POST",
      body: notEmptyBody,
      cache: "no-store",
      ...await this.getAuthHeaders({
        "content-type": "application/json"
      })
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO Merchant API Role.Manager or Collective API Role.Manager
  async inviteUserToMerchant(cid: string, mid: string, name: string, email: string, role: string): Promise<any[]> {
    console.log("inviteUserToMerchant")
    let notEmptyBody = JSON.stringify({
      email,
      name,
      role
    })
    const url = this.apiUrl(`/v1/collective/${cid}/merchant/${mid}/user`)
    const rsp = await fetch(url, {
      method: "POST",
      body: notEmptyBody,
      cache: "no-store",
      ...await this.getAuthHeaders({
        "content-type": "application/json"
      })
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO Collective API Role.Viewer
  async inviteRsvp(cid: string, token: string): Promise<any[]> {
    console.log("inviteRsvp")
    let empty = JSON.stringify({})

    const url = this.apiUrl(`/v1/collective/${cid}/user/invite/${token}`)
    const rsp = await fetch(url, {
      method: "POST",
      body: empty,
      cache: "no-store",
      ...await this.getAuthHeaders({
        "content-type": "application/json"
      })
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO Collective API Role.Viewer
  async inviteMerchantRsvp(cid: string, mid: string, token: string): Promise<any[]> {
    console.log("inviteMerchantRsvp")
    let empty = JSON.stringify({})

    const url = this.apiUrl(`/v1/collective/${cid}/merchant/${mid}/user/invite/${token}`)
    const rsp = await fetch(url, {
      method: "POST",
      body: empty,
      cache: "no-store",
      ...await this.getAuthHeaders({
        "content-type": "application/json"
      })
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO Collective API Role.Manager
  async updateMerchantInCollective(cid: string, m: Merchant): Promise<any> {
    console.log("updateMerchantInCollective")
    const url = this.apiUrl(`/v1/collective/${cid}/merchant/${m.id}`)
    const rsp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(m),
      cache: "no-store",
      ...await this.getAuthHeaders({
        "content-type": "application/json"
      })
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO Collective API Role.Owner
  async deleteMerchantInCollective(cid: string, mid: string): Promise<any> {
    console.log("deleteMerchantInCollective")
    const url = this.apiUrl(`/v1/collective/${cid}/merchant/${mid}`)
    const rsp = await fetch(url, {
      method: "DELETE",
      body: JSON.stringify({}),
      cache: "no-store",
      ...await this.getAuthHeaders({
        "content-type": "application/json"
      })
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO Collective API Role.Viewer
  async getWebhooks(cid: string): Promise<Webhook[]> {
    console.log('getWebhooks')
    const url = this.apiUrl(`/v1/collective/${cid}/webhook`)
    const rsp = await fetch(url, {
      cache: "no-store",
      ...await this.getAuthHeaders()
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO Collective API Role.Manager
  async createWebhook(cid: string, eventType: string, u: string): Promise<any> {
    let notEmptyBody = JSON.stringify({
      eventType,
      url: u,
    })
    const url = this.apiUrl(`/v1/collective/${cid}/webhook`)
    const rsp = await fetch(url, {
      method: "POST",
      body: notEmptyBody,
      cache: "no-store",
      ...await this.getAuthHeaders({
        "content-type": "application/json"
      })
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO Collective API Role.Manager
  async deleteWebhook(cid: string, wid: string): Promise<any> {
    const url = this.apiUrl(`/v1/collective/${cid}/webhook/${wid}`)
    const rsp = await fetch(url, {
      method: "DELETE",
      cache: "no-store",
      ...await this.getAuthHeaders()
    })
    return this.checkStatus(rsp, 204)
  }

  // TODO Collective API Role.Manager
  async toggleWebhook(cid: string, wid: string): Promise<any> {
    const url = this.apiUrl(`/v1/collective/${cid}/webhook/${wid}`)
    const rsp = await fetch(url, {
      method: "PATCH",
      cache: "no-store",
      ...await this.getAuthHeaders()
    })
    return this.checkStatus(rsp, 204)
  }

  async apiCreateClient(cid: string): Promise<any> {
    console.log("apiCreateClient")
    const url = this.apiUrl(`/v1/collective/${cid}/api`)
    const rsp = await fetch(url, {
      method: "POST",
      body: JSON.stringify({}),
      cache: "no-store",
      ...await this.getAuthHeaders()
    })
    return this.checkJsonStatus(rsp)
  }

  async apiDeleteClient(cid: string, clientId: string | undefined): Promise<any> {
    console.log("apiDeleteClient")
    const url = clientId ? this.apiUrl(`/v1/collective/${cid}/api?clientId=${clientId}`) : this.apiUrl(`/v1/collective/${cid}/api`)
    const rsp = await fetch(url, {
      method: "DELETE",
      cache: "no-store",
      ...await this.getAuthHeaders()
    })
    return this.checkStatus(rsp, 204)
  }

  async apiGetClient(cid: string): Promise<any> {
    console.log("apiGetClient")
    const url = this.apiUrl(`/v1/collective/${cid}/api/client`)
    const rsp = await fetch(url, {
      cache: "no-store",
      ...await this.getAuthHeaders()
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO Collective API Role.Owner
  async getConfig(cid: string): Promise<{config: {[key: string]: string}}> {
    const url = this.apiUrl(`/v1/collective/${cid}/config`)
    const rsp = await fetch(url, {
      cache: "no-store",
      ...await this.getAuthHeaders()
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO Collective API Role.Owner
  async addConfig(cid:string, data:any): Promise<any> {
    const url = this.apiUrl(`/v1/collective/${cid}/config`)
    const rsp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      cache: "no-store",
      ...await this.getAuthHeaders({
        "content-type": "application/json"
      })
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO Collective API Role.Owner
  async deleteConfig(cid: string, key: string): Promise<any> {
    console.log("deleteConfig")
    const url = this.apiUrl(`/v1/collective/${cid}/config/${key}`)
    const rsp = await fetch(url, {
      method: "DELETE",
      ...await this.getAuthHeaders()
    })
    return this.checkStatus(rsp, 204)
  }


}

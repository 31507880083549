/*
 * InterPayments Inc. ("COMPANY") CONFIDENTIAL
 * Unpublished Copyright © 2023 InterPayments Inc., All Rights Reserved.
 *
 * https://interpayments.com/copyright-policy/
 *
 * NOTICE: All information contained herein is, and remains the property of
 * COMPANY. The intellectual and technical concepts contained herein are
 * proprietary to COMPANY and may be covered by U.S. and Foreign Patents, patents
 * in process, and are protected by trade secret or copyright law. Dissemination
 * of this information or reproduction of this material is strictly forbidden
 * unless prior written permission is obtained from COMPANY. Access to the source
 * code contained herein is hereby forbidden to anyone except current COMPANY
 * employees, managers or contractors who have executed Confidentiality and
 * Non-disclosure agreements explicitly covering such access.
 *
 * The copyright notice above does not evidence any actual or intended publication
 * or disclosure of this source code, which includes information that is
 * confidential and/or proprietary, and is a trade secret, of COMPANY. ANY
 * REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY
 * OF OR THROUGH USE OF THIS SOURCE CODE WITHOUT THE EXPRESS WRITTEN CONSENT
 * OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 * INTERNATIONAL TREATIES. THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR
 * RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE
 * OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 * MAY DESCRIBE, IN WHOLE OR IN PART.
 *
 */

import React, {FunctionComponent, useContext, useEffect, useMemo, useState} from "react"
import {
  AuthenticatedBaseService,
  AuthenticatedBaseServiceParams,
  BaseService,
  BaseServiceParams
} from "../../services/base-service";
import {useAuth0} from "@auth0/auth0-react";
import {useDispatch} from "react-redux";

import { env2 } from "../../utils/env2"

const BaseUrl = env2("REACT_APP_BASE_URL")

type Context = {
  getService: <T extends AuthenticatedBaseService>(
    type: new (p: AuthenticatedBaseServiceParams) => T,
    getIdToken: () => Promise<string>,
    idToken: string|undefined
  ) => T
}

export const ServiceContext = React.createContext<Context | null>(null)

type OpenContext = {
  getService: <T extends BaseService>(
    type: new (p: BaseServiceParams) => T
  ) => T
}

export const OpenServiceContext = React.createContext<OpenContext | null>(null)

type OwnProps = object

type Props = React.PropsWithChildren<OwnProps>

const ServiceProviderFC: FunctionComponent<Props> = ({ children }) => {

  const { getIdTokenClaims } = useAuth0()

  const [ service, setService ] = useState(undefined)
  const [ idToken, setIdToken ] = useState<string|undefined>(undefined)
  useEffect(() => {
    (async () => {
      const tc = await getIdTokenClaims()
      if (!!tc && !!tc.__raw) setIdToken(tc.__raw)
      else console.log(`Could not set ID token!  Claims attached.`, tc)
    })()
  })

  const getService = <T extends AuthenticatedBaseService>(
    type: new (p: AuthenticatedBaseServiceParams) => T,
    getIdToken: () => Promise<string>,
    idToken: string | undefined
  ) => {
    return new type({ baseUrl: BaseUrl, getIdToken: getIdToken, idToken: idToken })
  }

  return (
    <ServiceContext.Provider value={{ getService }}>
      {children}
    </ServiceContext.Provider>
  )
}

export const ServiceProvider = ServiceProviderFC

const OpenServiceProviderFC: FunctionComponent<React.PropsWithChildren<Props>> = ({ children }) => {
  const getService = <T extends BaseService>(
    type: new (p: BaseServiceParams) => T
  ) => {
    return new type({ baseUrl: BaseUrl })
  }

  return (
    <OpenServiceContext.Provider value={{ getService }}>
      {children}
    </OpenServiceContext.Provider>
  )
}

export const OpenServiceProvider = OpenServiceProviderFC

export const useService = <T extends AuthenticatedBaseService>(
  type: new (p: AuthenticatedBaseServiceParams) => T
) => {

  const {
    getIdTokenClaims
  } = useAuth0()

  const [idToken, setIdToken] = useState<string | null>()

  const dispatch = useDispatch()

  useEffect(() => {
    (async () => {
      const token = await getIdTokenClaims()
      // console.log("useEffect to get claims", token)
      if (token && token.__raw !== idToken) {
        setIdToken(token.__raw)
      } else {
        setIdToken(null)
      }
    })()
  }, [getIdTokenClaims])

  const svcCtx = useContext(ServiceContext)!

  return useMemo(() => {
      // console.log("creating service!", type, getIdTokenClaims)
      return svcCtx.getService(type, () => Promise.resolve(idToken!), idToken!)
    }, [idToken])
}

export const useOpenService = <T extends BaseService>(
  type: new (p: BaseServiceParams) => T
) => {

  const svcCtx = useContext(OpenServiceContext)!
  return useMemo(() => {
    // console.log("creating service!", type, getIdTokenClaims)
    return svcCtx.getService(type)
  }, [svcCtx, type])
}

/*
 * InterPayments Inc. ("COMPANY") CONFIDENTIAL
 * Unpublished Copyright © 2023 InterPayments Inc., All Rights Reserved.
 *
 * https://interpayments.com/copyright-policy/
 *
 * NOTICE: All information contained herein is, and remains the property of
 * COMPANY. The intellectual and technical concepts contained herein are
 * proprietary to COMPANY and may be covered by U.S. and Foreign Patents, patents
 * in process, and are protected by trade secret or copyright law. Dissemination
 * of this information or reproduction of this material is strictly forbidden
 * unless prior written permission is obtained from COMPANY. Access to the source
 * code contained herein is hereby forbidden to anyone except current COMPANY
 * employees, managers or contractors who have executed Confidentiality and
 * Non-disclosure agreements explicitly covering such access.
 *
 * The copyright notice above does not evidence any actual or intended publication
 * or disclosure of this source code, which includes information that is
 * confidential and/or proprietary, and is a trade secret, of COMPANY. ANY
 * REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY
 * OF OR THROUGH USE OF THIS SOURCE CODE WITHOUT THE EXPRESS WRITTEN CONSENT
 * OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 * INTERNATIONAL TREATIES. THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR
 * RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE
 * OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 * MAY DESCRIBE, IN WHOLE OR IN PART.
 *
 */

import React, {useEffect, useState} from "react"

export const branch = <P1 extends object, P2 extends object>(test: boolean, ComponentOnPass: React.ComponentType<React.PropsWithChildren<P1>>, ComponentOnFail: React.ComponentType<React.PropsWithChildren<P2>>) =>
  (props1: P1, props2: P2) => test ? <ComponentOnPass {...props1} /> : <ComponentOnFail {...props2} />

export type HasDataProps<T> = {
  data: {
    result: T | null
    error: any
  }
}

export const hasData = <T extends any, L extends object>(getData: () => Promise<T>, LoadingComponent: React.ComponentType<React.PropsWithChildren<L>>) => <P extends HasDataProps<T>, R = Omit<P, "data">>(WrappedComponent: React.ComponentType<React.PropsWithChildren<P>>) => (props1: R, props2: L) => {
  const [result, setResult] = useState<T | null | undefined>()
  const [error, setError] = useState<any>()
  useEffect(() => {
    getData()
      .then(setResult)
      .catch(e => {
        setResult(null)
        setError(e)
      })
  }, [])

  return branch(result === undefined, LoadingComponent, WrappedComponent)(props2, {...({...props1, data: {result, error}} as unknown as P)})
}

export const hasProps = <IP extends object>(injectedProps: IP) => <P extends object>(WrappedComponent: React.ComponentType<React.PropsWithChildren<P>>) => {
  const HasProps = (props: P) => <WrappedComponent {...injectedProps} {...props} />
  return HasProps
}

const s4 = () => {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1)
}

export const guid = () => {
  return s4() + s4()
}

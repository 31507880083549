/*
 * InterPayments Inc. ("COMPANY") CONFIDENTIAL
 * Unpublished Copyright © 2024 InterPayments Inc., All Rights Reserved.
 *
 * https://interpayments.com/copyright-policy/
 *
 * NOTICE: All information contained herein is, and remains the property of
 * COMPANY. The intellectual and technical concepts contained herein are
 * proprietary to COMPANY and may be covered by U.S. and Foreign Patents, patents
 * in process, and are protected by trade secret or copyright law. Dissemination
 * of this information or reproduction of this material is strictly forbidden
 * unless prior written permission is obtained from COMPANY. Access to the source
 * code contained herein is hereby forbidden to anyone except current COMPANY
 * employees, managers or contractors who have executed Confidentiality and
 * Non-disclosure agreements explicitly covering such access.
 *
 * The copyright notice above does not evidence any actual or intended publication
 * or disclosure of this source code, which includes information that is
 * confidential and/or proprietary, and is a trade secret, of COMPANY. ANY
 * REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY
 * OF OR THROUGH USE OF THIS SOURCE CODE WITHOUT THE EXPRESS WRITTEN CONSENT
 * OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 * INTERNATIONAL TREATIES. THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR
 * RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE
 * OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 * MAY DESCRIBE, IN WHOLE OR IN PART.
 *
 */

import { createContext, Dispatch, ReactNode, useCallback, useContext, useEffect, useMemo, useReducer } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { hardFetchTimeout } from 'hooks/usePortalResources'
import { useResourceGroupAPI } from 'hooks/useResourceGroupAPI'

export type ResourceGroup = {
  id: string,
  name: string,
  status: string,
}

type ResourceGroupState = {
  loading: Promise<ResourceGroup[]> | undefined,
  loadedAt: Dayjs | undefined,
  resourceGroups: ResourceGroup[]
}

enum ResourceGroupActionType {
  SET_LOADING = 'setLoading',
  INVALIDATE_CACHE = 'invalidateCache',
  RESOURCE_GROUPS_LOADED = 'setResourceGroups'
}

type SetLoadingAction = {
  type: ResourceGroupActionType.SET_LOADING,
  loading: Promise<ResourceGroup[]>,
}

export const SetLoadingAction = (loading: Promise<ResourceGroup[]>): SetLoadingAction => ({type: ResourceGroupActionType.SET_LOADING, loading})

type InvalidateCacheAction = {
  type: ResourceGroupActionType.INVALIDATE_CACHE
}

export const InvalidateCacheAction = (): InvalidateCacheAction => ({type: ResourceGroupActionType.INVALIDATE_CACHE})

type SetResourceGroupsAction = {
  type: ResourceGroupActionType.RESOURCE_GROUPS_LOADED,
  value: ResourceGroup[]
}

export const SetResourceGroupsAction = (value: ResourceGroup[]): SetResourceGroupsAction => ({type: ResourceGroupActionType.RESOURCE_GROUPS_LOADED, value})

type ResourceGroupAction = SetLoadingAction | InvalidateCacheAction | SetResourceGroupsAction

type ResourceGroupContext_Shape = {
  state: ResourceGroupState,
  dispatch: Dispatch<ResourceGroupAction>
}

const initialResourceGroupState: ResourceGroupState = {
  loading: undefined,
  loadedAt: undefined,
  resourceGroups: []
}

const initialDispatch: Dispatch<ResourceGroupAction> = (value) => undefined

const initialValue: ResourceGroupContext_Shape = {
  state: initialResourceGroupState,
  dispatch: initialDispatch
}

const reducerFunction = (state: ResourceGroupState, action: ResourceGroupAction) => {
  if (action.type === ResourceGroupActionType.INVALIDATE_CACHE) {
    return {...state, loadedAt: undefined}
  }
  else if (action.type === ResourceGroupActionType.RESOURCE_GROUPS_LOADED) {
    return {...state, resourceGroups: action.value, loadedAt: dayjs(), loading: undefined}
  }
  else if (action.type === ResourceGroupActionType.SET_LOADING) {
    const { loading } = action
    return {...state, loading}
  }
  return state
}

const ResourceGroupContext = createContext<ResourceGroupContext_Shape>(initialValue)

export const ResourceGroupProvider = ({ children }: {children: ReactNode}) => {
  const [state, dispatch] = useReducer(reducerFunction, initialResourceGroupState)

  return <ResourceGroupContext.Provider value={{state, dispatch}}>
    {children}
  </ResourceGroupContext.Provider>
}

export const useResourceGroupContext = () => {
  const {state, dispatch} = useContext(ResourceGroupContext)
  const api = useResourceGroupAPI()

  useEffect(() => {
    if (state.loadedAt === undefined && !state.loading) getResourceGroups()
  }, [state])

  const loadResourceGroups = useCallback(async () => {
    const existingLoad = state.loading
    if (existingLoad) {
      return await existingLoad
    }
    const loading = api.getResourceGroups()
    dispatch(SetLoadingAction(loading))
    return loading.then(v => {
      dispatch(SetResourceGroupsAction(v))
      return v
    })
      .catch(e => dispatch(SetResourceGroupsAction([])))
  }, [state.loading, api.getResourceGroups])

  const invalidateCache = useCallback(() => {
    dispatch(InvalidateCacheAction())
  }, [])

  const loading = useMemo(() => state.loading !== undefined, [state.loading])
  const resourceGroups = useMemo(() => state.resourceGroups, [state.resourceGroups])

  const getResourceGroups = useCallback(async () => {
    const cacheExpired = state.loadedAt === undefined || dayjs().diff(state.loadedAt) >= hardFetchTimeout
    if (cacheExpired) return loadResourceGroups()
    else return state.resourceGroups
  }, [state.loadedAt, state.resourceGroups])

  const getResourceGroup = useCallback(async (rgid: string) => {
    const groups = await getResourceGroups()
    return groups.find(g => g.id === rgid)
  }, [getResourceGroups])

  return { loading, invalidateCache, resourceGroups, getResourceGroup }
}
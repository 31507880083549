import { Cascader } from "antd"
import { Jurisdiction, regionsByJurisdiction } from "models/models"
import { ReactNode } from "react"

type RegionCascaderOptions = RegionCascaderJurisdiction[]

type RegionCascaderJurisdiction = {
  value: Jurisdiction,
  label: ReactNode,
  children: RegionCascaderRegion[]
}

type RegionCascaderRegion = {
  value: string,
  label: ReactNode
}

export const regionCascaderOptions: RegionCascaderOptions = Object.entries(regionsByJurisdiction).map(([jurisdiction, regions]) => {
  const children = regions.map(r => ({value: r.key, label: r.name}))
  return {value: jurisdiction as Jurisdiction, label: jurisdiction, children}
})

export const RegionSelector = () => {
  return <Cascader onChange={v => console.log(v)} placeholder='Sample Country / Sample Region' options={regionCascaderOptions} />
}
/*
 * InterPayments Inc. ("COMPANY") CONFIDENTIAL
 * Unpublished Copyright © 2023 InterPayments Inc., All Rights Reserved.
 *
 * https://interpayments.com/copyright-policy/
 *
 * NOTICE: All information contained herein is, and remains the property of
 * COMPANY. The intellectual and technical concepts contained herein are
 * proprietary to COMPANY and may be covered by U.S. and Foreign Patents, patents
 * in process, and are protected by trade secret or copyright law. Dissemination
 * of this information or reproduction of this material is strictly forbidden
 * unless prior written permission is obtained from COMPANY. Access to the source
 * code contained herein is hereby forbidden to anyone except current COMPANY
 * employees, managers or contractors who have executed Confidentiality and
 * Non-disclosure agreements explicitly covering such access.
 *
 * The copyright notice above does not evidence any actual or intended publication
 * or disclosure of this source code, which includes information that is
 * confidential and/or proprietary, and is a trade secret, of COMPANY. ANY
 * REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY
 * OF OR THROUGH USE OF THIS SOURCE CODE WITHOUT THE EXPRESS WRITTEN CONSENT
 * OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 * INTERNATIONAL TREATIES. THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR
 * RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE
 * OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 * MAY DESCRIBE, IN WHOLE OR IN PART.
 *
 */

import { useCallback } from "react"
import { Contact, FeeConfig, formatURIDate, formatURIDatetime, InterchangeType, Merchant, MerchantResource, RollupSummaryRecord, RuleType, serializeObjectToURLParams, TxLog, Webhook } from "../models/models"
import { MerchantRegionConfig_Response } from "pages/MerchantView/MerchantRegionView"
import { ContentType, useRequest } from "./useRequest"
import { FilterProps } from "utils/txlogHelper"
import qs from "qs"
import { CardBrandNoticeType, ComplianceStatus, SurchargeDisclosureType } from "models/audit"
import { FeeDataRecord } from "models/feedata"
import { Dayjs } from 'dayjs'

// Merchant API

export const useMerchantAPI = () => {
  const { makeRequest } = useRequest()

  const getMerchant = useCallback(
    (mid: string) => {
      return makeRequest([`/v1/merchant/${mid}`, {}])
    }, [])

  const getAllMerchants = useCallback(
    () => {
      return makeRequest([`/v1/merchant`, {}]) as Promise<MerchantResource[]>
    }, [])

  const getMerchantInviteMetadata = useCallback(
    (mid: string) => {
      return makeRequest([`/v1/merchant/${mid}/metadata`, {}])
    }, [])

  const createMerchant = useCallback(
    (m: Merchant) => {
      return makeRequest([`/v1/merchant`, {
        method: 'POST',
        body: JSON.stringify(m),
        headers: {
          'content-type': 'application/json'
        }
      }])
    }, [])

  const updateMerchantLimited = useCallback(
    (m: Merchant) => {
      return makeRequest([`/v1/merchant/${m.id}`, {
        method: "POST",
        body: JSON.stringify(m),
        headers: {
          "content-type": "application/json"
        }
      }])
    }, [])

  const updateMerchantAdmin = useCallback(
    (m: Merchant) => {
      return makeRequest([`/v1/support/merchant/${m.id}`, {
        method: "POST",
        body: JSON.stringify(m),
        headers: {
          "content-type": "application/json"
        }
      }])
    }, [])

  const updateMerchantTermsAdmin = useCallback(
    (mid: string, d: any) => {
      return makeRequest([`/v1/support/merchant/${mid}/terms`, {
        method: "POST",
        body: JSON.stringify(d),
        headers: {
          "content-type": "application/json"
        }
      }])
    }, [])

  const getMerchantTermsAdmin = useCallback(
    (mid: string) => {
      return makeRequest([`/v1/support/merchant/${mid}/terms`, {}])
    }, [])

  const updateOrganizationName = useCallback(
    (mid: string, name: string) => {
      return makeRequest([`/v1/merchant/${mid}/organizationname`, {
        method: "POST",
        body: JSON.stringify({name}),
        headers: {
          "content-type": "application/json"
        }
      }], { responseType: ContentType.NONE })
    }, [])

  const updateContact = useCallback(
    (mid: string, c: Contact) => {
      return makeRequest([`/v1/merchant/${mid}/contact`, {
        method: "POST",
        body: JSON.stringify(c),
        headers: {
          "content-type": "application/json"
        }
      }])
    }, [])

  const updateAcquirerInformation = useCallback(
      (mid: string, acquirerInformation: {name: string, contactName: string, contactEmail: string}) => {
        return makeRequest([`/v1/merchant/${mid}/acquirerinformation`, {
          method: "POST",
          body: JSON.stringify(acquirerInformation),
          headers: {
            "content-type": "application/json"
          }
        }], { responseType: ContentType.NONE })
      }, [])
  

  const updateDisclosures = useCallback(
    (mid: string, type: SurchargeDisclosureType, date: Dayjs | null) => {
      return makeRequest([`/v1/merchant/${mid}/surchargedisclosures`, {
        method: "POST",
        body: JSON.stringify({
          surchargeDisclosureType: type,
          surchargeDisclosureDate: !!date ? date.format('YYYY-MM-DDTHH:mm:ssZ') : null,
        }),
        headers: {
          "content-type": "application/json"
        }
      }], { responseType: ContentType.NONE })
    }, [])

  const updateCardBrandNotices = useCallback(
    (mid: string, type: CardBrandNoticeType, date: Dayjs | null) => {
      return makeRequest([`/v1/merchant/${mid}/cardbrandnotices`, {
        method: "POST",
        body: JSON.stringify({
          cardBrandNoticeType: type,
          cardBrandNoticeDate: !!date ? date.format('YYYY-MM-DDTHH:mm:ssZ') : null,
        }),
        headers: {
          "content-type": "application/json"
        }
      }], { responseType: ContentType.NONE })
    }, [])

  const updateComplianceStatusOverride = useCallback(
    (mid: string, complianceStatusOverride: ComplianceStatus | null,  overrideReason?: string) => {
      const requestBody: Record<string, any> = {}

      // Populate the request body based on provided parameters
      if (complianceStatusOverride !== null && complianceStatusOverride !== undefined) {
        requestBody.complianceStatusOverride = complianceStatusOverride
      }
      
      if (overrideReason) {
        requestBody.complianceStatusOverrideNote = overrideReason;
      }
      
      return makeRequest([`/v1/merchant/${mid}/compliancestatusoverride`, {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "content-type": "application/json"
        },
      }], { responseType: ContentType.NONE })
    }, [])

  const deleteMerchantAdmin = useCallback(
    (mid: string) => {
      return makeRequest([`/v1/merchant/${mid}`, {
        method: "DELETE",
      }])
    }, [])

  const createProcessor = useCallback(
    (mid: string, data: any) => {
      return makeRequest([`/v1/merchant/${mid}/processor/sf`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "content-type": "application/json"
        }
      }])
    }, [])

  const deleteProcessor = useCallback(
    (mid: string, pid: string) => {
      return makeRequest([`/v1/merchant/${mid}/processor/${pid}`, {
        method: "DELETE",
      }])
    }, [])

  const updateMerchantInterchangeType = useCallback(
    (mid: string, value: InterchangeType) => {
      return makeRequest([`/v1/merchant/${mid}/processor/update/interchange?interchangeType=${value}`, {
        method: "POST",
        body: JSON.stringify({}),
        headers: {
          "content-type": "application/json"
        }}])
      }, [])

  const generateClientToken = useCallback(
    (mid: string, body: any) => {
      return makeRequest([`/v1/merchant/${mid}/token`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "content-type": "application/json"
        }
      }])
    }, [])

  const blacklistToken = useCallback(
    (mid: string, token: string) => {
      return makeRequest([`/v1/merchant/${mid}/token/blacklist`, {
        method: "POST",
        body: JSON.stringify({
          token
        }),
        headers: {
          "content-type": "application/json"
        }
      }], { responseType: ContentType.NONE })
    }, [])

  const getTxLogs = useCallback(
    (mid: string, attributes: FilterProps & {skip?: any, limit?: any}): Promise<{totalRecords: number, data: any[]}> => {
      const params = qs.stringify(attributes, {arrayFormat: 'repeat'})
      const responseType = attributes.format === 'csv' || attributes.format === 'csv-remote' ? ContentType.TEXT : ContentType.JSON
      return makeRequest([`/v1/merchant/${mid}/txlog?${params}`, {}], { responseType })
    }, [])

  const getTxLogsById = useCallback(
    (mid: string, txids: string[]): Promise<TxLog[]> => {
      const params = qs.stringify({txids: txids.join(',')})
      return makeRequest([`/v1/merchant/${mid}/txlog/search?${params}`, {}])
    }, [])

  const getTxLogDetails = useCallback(
    (mid: string, txid: string): Promise<TxLog> => {
      return makeRequest([`/v1/merchant/${mid}/txlog/${txid}`, {}])
    }, [])

  const supportMerchantInvoiceRegister = useCallback(
    (mid: string, data: any) => {
      return makeRequest([`/v1/merchant/${mid}/invoice/register`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "content-type": "application/json"
        }
      }])
    }, [])

  const supportInvoiceRegisterCustomerId = useCallback(
    (cid: string, customerId: any) => {
      return makeRequest([`/v1/merchant/${cid}/invoice/register/customer`, {
        method: "PATCH",
        body: JSON.stringify({ customerId }),
        headers: {
          "content-type": "application/json"
        }
      }], { responseType: ContentType.NONE })
    }, [])

  const checkInvitation = useCallback(
    (mid: string, token: string): Promise<boolean> => {
      return makeRequest([`/v1/merchant/${mid}/user/invite/${token}`, {}]).then(_ => true)
    }, [])

  const getConfig = useCallback(
    (mid: string): Promise<{config: {[key: string]: string}}> => {
      return makeRequest([`/v1/merchant/${mid}/config`, {}])
    }, [])

  const addConfig = useCallback(
    (mid: string, data: any) => {
      return makeRequest([`/v1/merchant/${mid}/config`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "content-type": "application/json"
        }
      }])
    }, [])

  const deleteConfig = useCallback(
    (mid: string, key: string) => {
      return makeRequest([`/v1/merchant/${mid}/config/${key}`, {
        method: "DELETE"
      }])
    }, [])

  const getRegionConfig = useCallback(
    (mid: string): Promise<MerchantRegionConfig_Response> => {
      return makeRequest([`/v1/merchant/${mid}/region`, {}])
    }, [])

  const merchantHasSyncId = useCallback(
    (mid: string): Promise<boolean> => {
      return makeRequest([`/v1/merchant/${mid}/config`, {}])
        .then(c => {
          if (c && c.config) {
            const map = c.config
            return !!Object.keys(map).find(x => x === "sync-id")
          } else {
            return false
          }
        })
    }, [])

  const getFeedataSummary = useCallback(
    (mid: string, start: Dayjs, end: Dayjs, pid?: string): Promise<FeeDataRecord[]> => {
      const processorParams = !!pid ? {pid} : {}
      const params = serializeObjectToURLParams({
        start: formatURIDate(start),
        end: formatURIDate(end),
        ...processorParams
      })
      return makeRequest([`/v1/merchant/${mid}/feedata/summary?${params}`])
    }, [])

  const updateRateExpiration = useCallback(
    (mid: string, data: any) => {
      return makeRequest([`/v1/merchant/${mid}/processor/snooze`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'content-type': 'application/json',
        }
      }], {responseType: ContentType.NONE})
    }, [])

  const apiGetClient = useCallback(
    (mid: string) => {
      return makeRequest([`/v1/merchant/${mid}/api/client`])
    }, [])

  const apiCreateClient = useCallback(
    (mid: string) => {
      return makeRequest([`/v1/merchant/${mid}/api`, {
        method: 'POST',
        body: JSON.stringify({}),
        headers: {
          "content-type": "application/json"
        }
      }])
    }, [])

  const apiDeleteClient = useCallback(
    (mid: string) => {
      return makeRequest([`/v1/merchant/${mid}/api`, {
        method: 'DELETE',
        body: JSON.stringify({}),
        headers: {
          "content-type": "application/json"
        }
      }], {responseType: ContentType.NONE})
    }, [])

  const apiToggleGrantType = useCallback(
    (mid: string, grantType: string, toggle: boolean) => {
      return makeRequest([`/v1/merchant/${mid}/api/${grantType}/${toggle}`, {
        method: "POST",
        body: JSON.stringify({}),
        headers: {
          "content-type": "application/json"
        }
      }], {responseType: ContentType.NONE})
    }, [])

  const removeUser = useCallback(
    (mid: string, userId: string, role: string) => {
      return makeRequest([`/v1/merchant/${mid}/user/${userId}/${role}`, {
        method: 'DELETE'
      }])
    }, [])

  const getRecentAverages = useCallback(
    (mid: string, pid: string): Promise<any> => {
      return makeRequest([`/v1/merchant/${mid}/processor/${pid}/avg`, {}])
    }, [])

  const updateAverages = useCallback(
    (mid: string, pid: string) => {
      return makeRequest([`/v1/merchant/${mid}/processor/${pid}/avg`, {
        method: "POST",
        body: JSON.stringify({}),
        headers: {
          "content-type": "application/json"
        }
      }], {})
    }, [])

  const generateTestData = useCallback(
    (mid: string, data: any) => {
      return makeRequest([`/v1/merchant/${mid}/testdata`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'content-type': 'application/json'
        }
      }], {responseType: ContentType.TEXT})
    }, [])

  const deleteTestData = useCallback(
    (mid: string, key: string) => {
      return makeRequest([`/v1/merchant/${mid}/testdata/${key}`, {
        method: 'DELETE'
      }], {responseType: ContentType.NONE})
    }, [])

  const enableRule = useCallback(
    (mid: string, data: any) => {
      return makeRequest([`/v1/merchant/${mid}/rule`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'content-type': 'application/json'
        }
      }], {responseType: ContentType.NONE})
    }, [])

  const disableRule = useCallback(
    (mid: string, ruleType: RuleType) => enableRule(mid, {ruleType, enable: false, data: {}}), [])

  const setActive = useCallback(
    (mid: string) => {
      return makeRequest([`/v1/merchant/${mid}/status/active`, {
        method: 'POST',
        body: JSON.stringify({}),
        headers: {
          'content-type': 'application/json'
        }
      }], {responseType: ContentType.NONE})
    }, [])

  const setInactive = useCallback(
    (mid: string) => {
      return makeRequest([`/v1/merchant/${mid}/status/inactive`, {
        method: 'POST',
        body: JSON.stringify({}),
        headers: {
          'content-type': 'application/json'
        }
      }], {responseType: ContentType.NONE})
    }, [])

  const getProcessorDimension = useCallback(
    (mid: string, pdid: string) => {
      return makeRequest([`/v1/merchant/${mid}/processor/dimension/${pdid}`])
    }, [])

  const getProcessorHistory = useCallback(
    (mid: string, pid: string, start: Dayjs, end: Dayjs) => {
      const startParam = formatURIDatetime(start)
      const endParam = formatURIDatetime(end)
      return makeRequest([`/v1/merchant/${mid}/processor/${pid}/dimension?start=${startParam}&end=${endParam}`])
    }, [])

  const listBucket = useCallback(
    (mid: string) => {
      return makeRequest([`/v1/merchant/${mid}/ch/list`], {})
    }, [])

  const getBulkTxArtifact = useCallback(
    (mid: string, filename: string, format: string = 'csv') => {
      return makeRequest([`/v1/merchant/${mid}/ch/bulk?fileName=${filename}`, {
        method: 'GET',
        cache: 'no-store'
      }], {responseType: ContentType.CSV})
    }, [])

  const sendNotification = useCallback(
    (mid: string) => {
      return makeRequest([`/v1/merchant/${mid}/notification`, {
        method: 'POST'
      }], { responseType: ContentType.NONE })
    }, [])

    const getAccountInfo = useCallback(
      (mid: string) => {
        return makeRequest([`/v1/merchant/${mid}/invoice/account/info`, {
          method: 'GET'
        }])
      }, []
    )
    /**
     * Very limited use-cases.  Only applicable when we don't have access to HttpHeaders to set an Bearer Token.
     * Try really hard to avoid using this
     */
    const initiateSse = useCallback(
        (uuid: string, mid: string, pq: string = "n") => {
            return makeRequest([
                `/v1/merchant/${mid}/ticket?ct=${uuid}&i=jwt&&pq=${pq || "n"}`,
                {
                    method: "POST",
                    body: JSON.stringify({}),
                    headers: {"content-type": "application/json"}
                }
            ])
        }, []
    )

    const getWebhooks = useCallback(
      (mid: string): Promise<Webhook[]> => {
        return makeRequest([`/v1/merchant/${mid}/webhook`, {
          method: 'GET'
        }], { responseType: ContentType.JSON })
      }, []
    )

    const createWebhook = useCallback(
      (mid: string, eventType: string, u: string) => {
        return makeRequest([`/v1/merchant/${mid}/webhook`, {
          method: 'POST',
          body: JSON.stringify({eventType, url: u}),
          headers: {"content-type": "application/json"}
        }])
      }, []
    )

    const toggleWebhook = useCallback(
      (mid: string, wid: string) => {
        return makeRequest([`/v1/merchant/${mid}/webhook/${wid}`, {
          method: 'PATCH'
        }], { responseType: ContentType.NONE })
      }, []
    )

    const deleteWebhook = useCallback(
      (mid: string, wid: string) => {
        return makeRequest([`/v1/merchant/${mid}/webhook/${wid}`, {
          method: 'DELETE'
        }], { responseType: ContentType.NONE })
      }, []
    )

    const updateConvenienceFeeConfig = useCallback(
      (mid: string, pid: string, config: FeeConfig) => {
        return makeRequest([`/v1/merchant/${mid}/processor/${pid}/update/cf`, {
          method: "POST",
          body: JSON.stringify(config),
          headers: {"content-type": "application/json"}
        }], { responseType: ContentType.NONE })
      }, [])

    const updateServiceFeeConfig = useCallback(
      (mid: string, pid: string, config: FeeConfig) => {
        return makeRequest([`/v1/merchant/${mid}/processor/${pid}/update/sf`, {
          method: "POST",
          body: JSON.stringify(config),
          headers: {"content-type": "application/json"}
        }], { responseType: ContentType.NONE })
      }, [])

    const updateRentProgramFeeConfigs = useCallback(
        (mid: string, pid: string, config: { credit: FeeConfig, debit: FeeConfig }) => {
            return makeRequest([`/v1/merchant/${mid}/processor/${pid}/update/rpp`, {
                method: "POST",
                body: JSON.stringify(config),
                headers: {"content-type": "application/json"}
            }], { responseType: ContentType.NONE })
        }, [])

    const generateTransientToken = useCallback(
      (merchantId: string) => {
        return makeRequest([`/v1/merchant/token/${merchantId}`, {
          method: 'POST',
        }], { responseType: ContentType.JSON})
      }, []
    )

    return {
    getMerchant,
    getAllMerchants,
    getMerchantInviteMetadata,
    createMerchant,
    updateMerchantLimited,
    updateMerchantAdmin,
    updateMerchantTermsAdmin,
    getMerchantTermsAdmin,
    updateOrganizationName,
    updateContact,
    updateAcquirerInformation,
    updateDisclosures,
    updateCardBrandNotices,
    updateComplianceStatusOverride,
    deleteMerchantAdmin,
    generateClientToken,
    createProcessor,
    deleteProcessor,
    updateMerchantInterchangeType,
    blacklistToken,
    getTxLogs,
    getTxLogsById,
    getTxLogDetails,
    supportMerchantInvoiceRegister,
    supportInvoiceRegisterCustomerId,
    checkInvitation,
    addConfig,
    getConfig,
    deleteConfig,
    getRegionConfig,
    merchantHasSyncId,
    getFeedataSummary,
    updateRateExpiration,
    apiGetClient,
    apiCreateClient,
    apiDeleteClient,
    apiToggleGrantType,
    removeUser,
    getRecentAverages,
    updateAverages,
    generateTestData,
    deleteTestData,
    enableRule,
    disableRule,
    setActive,
    setInactive,
    getProcessorDimension,
    getProcessorHistory,
    listBucket,
    getBulkTxArtifact,
    sendNotification,
    initiateSse,
    getWebhooks,
    createWebhook,
    toggleWebhook,
    deleteWebhook,
    getAccountInfo,
    updateConvenienceFeeConfig,
    generateTransientToken,
    updateServiceFeeConfig,
    updateRentProgramFeeConfigs,
  }
}
